import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { Autocomplete } from "@mui/material";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import Logo from "../Invoice/Logo1.jpg";

const OutStandingUnPaidHistory = () => {
  const [formData, setFormData] = useState({
    totalOutStandingAmount: "",
    invoiceRefNo: "",
    financialYear: "",
    clientNameAndCode: "",
    outStandingAmount: "",
    paidAmount: "",
    unPaidAmount: "",
    tdsAmount: "",
    payMode: "",
    payDate: "",
    collectedBy: "",
    collectedName: "",
    chqNumber: "",
    chqDate: "",
    bankBranch: "",
    deductionAmount: "",
    deductionRemark: "",
  });

  const [financialYears, setFinancialYears] = useState([]);
  const [clientNames, setClientNames] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [otherChargesDetails, setOtherChargesDetails] = useState([]);
  const [invoiceRefNos, setInvoiceRefNos] = useState([]);
  const [collectionHistory, setCollectionHistory] = useState([]);

  useEffect(() => {
    // Fetch financial years
    myAxiosCommonHost
      .get("gst/invoice/getFiscalYear")
      .then((response) => setFinancialYears(response.data))
      .catch((error) =>
        console.error("Error fetching financial years:", error)
      );

    // Fetch client names
    myAxiosCommonHost
      .get("gst/outstanding/getOutstandingClientList")
      .then((response) => setClientNames(response.data))
      .catch((error) => console.error("Error fetching client names:", error));
  }, []);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      totalOutStandingAmount: "",
      outStandingAmount: "",
      paidAmount: "",
      unPaidAmount: "",
      tdsAmount: "",
      payMode: "",
      payDate: "",
      collectedBy: "",
      collectedName: "",
      chqNumber: "",
      chqDate: "",
      bankBranch: "",
      deductionAmount: "",
      deductionRemark: "",
    }));
    if (formData.financialYear && formData.clientNameAndCode) {
      const encodedClientNameAndCode = encodeURIComponent(
        formData.clientNameAndCode
      );
      const encodedFinancialYear = encodeURIComponent(formData.financialYear);
      myAxiosCommonHost
        .get(
          `gst/outstanding/getOutstandingClientWiseAmountAndInvoiceRef?clientNameAndCode=${encodedClientNameAndCode}&finacialYear=${encodedFinancialYear}`
        )
        .then((response) => {
          const { clientOutStandingAmount, clientInvoiceRefList } =
            response.data;

          setInvoiceRefNos(
            clientInvoiceRefList.map((ref) => ({ label: ref, value: ref }))
          );
          setFormData((prevData) => ({
            ...prevData,
            totalOutStandingAmount: clientOutStandingAmount || "",
          }));
        })
        .catch((error) =>
          console.error("Error fetching clientOutStandingAmount:", error)
        );
    }
  }, [formData.financialYear, formData.clientNameAndCode]);

  useEffect(() => {
    if (formData.invoiceRefNo) {
      const encodedinvoiceRefNo = encodeURIComponent(formData.invoiceRefNo);
      myAxiosCommonHost
        .get(
          `gst/outstanding/getOutstandingInvoiceRefDetailsUI?invoiceRefNo=${encodedinvoiceRefNo}`
        )
        .then((response) => {
          const {
            grandTotalAmount,
            outstandingEmployeeWiseDetailsUIList,
            outstandingProjectWiseDetailsUIList,
            outstandingCollectionPaidHistoryList,
            outstandingOtherChargesPerDetailsUIList,
          } = response.data;

          setFormData((prevData) => ({
            ...prevData,
            grandTotalAmount: grandTotalAmount || 0.0,
            outStandingAmount: grandTotalAmount || 0.0,
          }));

          if (
            outstandingEmployeeWiseDetailsUIList &&
            outstandingEmployeeWiseDetailsUIList.length > 0
          ) {
            setEmployeeDetails(outstandingEmployeeWiseDetailsUIList);
            setProjectDetails([]); // Clear projectDetails if any
            setOtherChargesDetails([]); // Clear employeeDetails if any
          } else if (
            outstandingProjectWiseDetailsUIList &&
            outstandingProjectWiseDetailsUIList.length > 0
          ) {
            setProjectDetails(outstandingProjectWiseDetailsUIList);
            setEmployeeDetails([]); // Clear employeeDetails if any
            setOtherChargesDetails([]); // Clear employeeDetails if any
          } else if (
            outstandingOtherChargesPerDetailsUIList &&
            outstandingOtherChargesPerDetailsUIList.length > 0
          ) {
            setProjectDetails([]); // Clear employeeDetails if any
            setEmployeeDetails([]); // Clear employeeDetails if any
            setOtherChargesDetails(outstandingOtherChargesPerDetailsUIList);
          }

          setCollectionHistory(outstandingCollectionPaidHistoryList || []);
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [formData.invoiceRefNo]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      let updatedData = {
        ...prevData,
        [name]: value,
      };
      if (
        name === "outStandingAmount" ||
        name === "paidAmount" ||
        name === "tdsAmount" ||
        name === "deductionAmount"
      ) {
        const outStandingAmount =
          parseFloat(updatedData.outStandingAmount) || 0.0;
        const paidAmount = parseFloat(updatedData.paidAmount) || 0.0;
        const tdsAmount = parseFloat(updatedData.tdsAmount) || 0.0;
        const deductionAmount = parseFloat(updatedData.deductionAmount) || 0.0;

        updatedData.unPaidAmount = (
          outStandingAmount -
          (paidAmount + tdsAmount + deductionAmount)
        ).toFixed(2);
      }

      return updatedData;
    });
  };

  const handleSubmit = () => {
    myAxiosCommonHost
      .post("gst/outstanding/createOutStandingCollection", formData)
      .then((response) => {
        console.log("POST request successful:", response.data);
        // Update outstandingCollectionPaidHistoryList immediately after submission
        setCollectionHistory((prevCollection) => [...prevCollection, formData]);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });

    // Reset form fields
    setFormData({
      totalOutStandingAmount: "",
      //invoiceRefNo: "",
      //financialYear: "",
      //clientNameAndCode: "",
      outStandingAmount: "",
      paidAmount: "",
      unPaidAmount: "",
      deductionAmount: "",
      deductionRemark: "",
      tdsAmount: "",
      payMode: "",
      payDate: "",
      collectedBy: "",
      collectedName: "",
      chqNumber: "",
      chqDate: "",
      bankBranch: "",
    });
  };

  const handleExportToExcel = () => {
    if (collectionHistory.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(collectionHistory);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Outstanding Collection "
      );
      XLSX.writeFile(workbook, "outstandingCollection.xlsx");
    }
  };

  const handlegeneratePDF = async (invoiceRefNo, filterType) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/invoice/viewInvoice?invoiceRefNo=${invoiceRefNo}`
      );
      const invoiceData = response.data; // Assuming myAxiosCommonHost behaves similarly to axios
  
      // Determine empRateUnit from invoiceData
      const empRateUnit = invoiceData[0]?.empRateUnit;
      const filterType = invoiceData[0]?.filterType;
     
  
      if (filterType === "EMPLOYEEPOINVOICE") {
        if (empRateUnit === "Per Day") {
          generatePDFPerDay(invoiceData);
        } else if (empRateUnit === "Per Month") {
          generatePDFPerMonth(invoiceData);
        } else if (empRateUnit === "Per Hour") {
          generatePDFPerHour(invoiceData);
        } else {
          console.log("Invalid empRateUnit value. Unable to generate PDF");
        }
      } else if (filterType === "PROJECTPOINVOICE") {
        // Additional check for Project PO Invoice
        if (empRateUnit === "Per Day") {
          generateProjectPDFPerDay(invoiceData);
        } else if (empRateUnit === "Per Hour") {
          generateProjectPDFPerHour(invoiceData);
        } else {
          console.log("Invalid empRateUnit value for Project PO Invoice. Unable to generate PDF");
        }
      } else if (filterType === "OTHERCHARGESINVOICE") {
        // Directly download Other Charges Invoice
        generateOtherChargesPDF(invoiceData);
      } else {
        console.log("Invalid filterType. Unable to determine PDF type.");
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };
  
   const generatePDFPerDay = async (invoiceData) => {
      const doc = new jsPDF();
  
      const includePO = await Swal.fire({
        title: "Do you want to include PO number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });
  
      // Define includeEmployeeCategoryWithPO variable
      let includeEmployeeCategoryWithPO;
  
      if (includePO) {
        includeEmployeeCategoryWithPO = await Swal.fire({
          title:
            "Do you want to include Employee Category with PO Number in the PDF?",
          icon: "question",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          } else {
            return false;
          }
        });
  
        await generatePDF(includePO, includeEmployeeCategoryWithPO);
      } else {
        await generatePDF(includePO, false);
      }
      // Call generatePDF function with correct parameters
      async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
        const encompassingRectX = 3;
        const encompassingRectY = 4;
        const encompassingRectWidth = doc.internal.pageSize.width - 6;
        const encompassingRectHeight = doc.internal.pageSize.height - 8;
  
        doc.setDrawColor(0); // Set border color to black
        doc.setLineWidth(0.4); // Set border width
        doc.rect(
          encompassingRectX,
          encompassingRectY,
          encompassingRectWidth,
          encompassingRectHeight
        );
  
        const {
          companyProfileUI,
          invoiceDetails,
          clientUI,
          invoiceEmployeePoDetailsUIList,
          invoiceGstDetailsUI,
          invoicePaymentModeUI,
          bankUI,
          gstdetailsUI,
          invoiceDate,
          invoiceRefNo,
          poNumber,
          workingDays,
          empRateUnit,
          clientName,
        } = invoiceData[0];
  
        let companyInfoText =
          `${companyProfileUI.companyName}\n` +
          `${companyProfileUI.companyAddress}\n` +
          `${companyProfileUI.website}\n` +
          `${companyProfileUI.eMail}`;
  
        // Add the company information to the PDF for the current invoice
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Black color
        doc.setFont("helvetica", "bold"); // Set font to bold
        doc.text(companyInfoText, 15, 30);
        doc.setFont("helvetica", "normal"); // Reset font to normal
  
        // Continue with the rest of your PDF generation logic...
  
        const companyInfoContainerX = 14;
        const companyInfoContainerY = 10;
        const companyInfoContainerWidth =
          doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
        const companyInfoContainerHeight = 40;
  
        // Draw the container first
        doc.setDrawColor(0);
        doc.setLineWidth(0.4);
        doc.setFillColor(255, 255, 255);
        doc.rect(
          companyInfoContainerX,
          companyInfoContainerY,
          companyInfoContainerWidth,
          companyInfoContainerHeight,
          "FD"
        );
  
        // Add the logo with additional margin from the left border
        doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
  
        // Position the company information text with reduced margin after the logo
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after logo width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
  
        // Use the fetched invoice data in your PDF
  
        //const { companyData, clientData } = await fetchData();
  
        const CompInvo = [
          ["Invoice No: " + invoiceRefNo],
          ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
          ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
          ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
          ["Invoice Date: " + invoiceDate],
        ];
  
        const tableWidth = doc.internal.pageSize.width * 0.45;
        const leftMargin = doc.internal.pageSize.width - tableWidth - 10;
  
        // Calculate the height of the CompInvo table
        const compInvoTableHeight = doc.autoTable({
          body: CompInvo,
          theme: "grid",
          margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
          styles: {
            fontSize: 10,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            cellPadding: 2,
            marginBottom: 0,
            marginTop: 0,
            padding: 0,
          },
        }).lastAutoTable.finalY;
  
        const ClientInvo = [
          ["Service ACC Code: " + gstdetailsUI.hsnsac],
          ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
          ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
          ["Billing Period: " + clientUI.billingPeriod],
          ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
        ];
  
        const clientInvoTableHeight = 40;
  
        // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
        doc.autoTable({
          body: ClientInvo,
          theme: "grid",
          columnStyles: { 0: { cellWidth: "auto" } },
          startY: compInvoTableHeight, // Set startY explicitly
          margin: {
            top: compInvoTableHeight,
            left: leftMargin,
            right: 14,
            bottom: 0,
          },
          styles: {
            fontSize: 10,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
            tableHeight: 40,
          },
        });
  
        const firstTableHeight1 = doc.previousAutoTable.finalY;
        const billedToContainerHeight = 38.85;
  
        // Billed To container immediately after companyInfo container
  
        const billedToText = `Billed To: ${clientName}
      ${clientUI.clientAddress}
      
      
      Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
      Kind Attn: ${invoicePaymentModeUI.kindAttention}`;
  
        const billedToContainerX = companyInfoContainerX;
        const topMargin = 0;
        const containerHeight = 40; // Set the common height for both the billedToContainer and the table
        const billedToContainerY =
          companyInfoContainerY + companyInfoContainerHeight + topMargin;
        const billedToContainerWidth = companyInfoContainerWidth;
  
        doc.setDrawColor(0);
        doc.setLineWidth(0.4);
        doc.setFillColor(255, 255, 255);
        doc.rect(
          billedToContainerX,
          billedToContainerY,
          billedToContainerWidth,
          containerHeight,
          "FD"
        );
  
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
          maxWidth: billedToContainerWidth - 10,
        });
        doc.setFillColor(255, 255, 255);
  
        let tableData;
        let amountColumnIndex;
        if (includePO) {
          // Include PO number column in the PDF
          if (includeEmployeeCategoryWithPO) {
            tableData = invoiceEmployeePoDetailsUIList.map((row) => [
              row.emplyoyeeName,
              poNumber,
              row.employeeCategory,
              row.fromDate,
              row.toDate,
  
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]);
            amountColumnIndex = 7;
          } else {
            tableData = invoiceEmployeePoDetailsUIList.map((row) => [
              row.emplyoyeeName,
  
              row.fromDate,
              row.toDate,
  
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]);
            amountColumnIndex = 6;
          }
        } else {
          // Exclude PO number column in the PDF
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
  
            row.fromDate,
            row.toDate,
  
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 5;
        }
  
        const headerLabels =
          includePO && includeEmployeeCategoryWithPO
            ? [
                "Particulars",
                "PO NO.",
                "Employee Category",
                "From Date",
                "To date",
                "Billable Days",
                "Current PO Rate",
                "Amount",
              ]
            : includePO && !includeEmployeeCategoryWithPO
            ? [
                "Particulars",
                "From Date",
                "To date",
                "Billable Days",
                "Current PO Rate",
                "Amount",
              ]
            : [
                "Particulars",
                "From Date",
                "To date",
                "Billable Days",
                "Current PO Rate",
                "Amount",
              ];
  
        // Generate the PDF table
        doc.autoTable({
          head: [headerLabels],
  
          body: tableData,
          startY: billedToContainerY + billedToContainerHeight + 0,
          theme: "grid",
          styles: {
            fontSize: 9,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: {
            [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
          },
        });
  
        // Calculate the height of the first table (invoice data table)
        const firstTableHeight = doc.previousAutoTable
          ? doc.previousAutoTable.finalY
          : 0;
  
        const {
          totalPresentyAmount,
          grandTotalAmount,
          totalTaxAmt,
          totalInWord,
        } = invoiceGstDetailsUI;
        const { cgstAmount, sgstAmount, igstAmount } = invoiceGstDetailsUI;
  
        const InvoiceData = [
          ["A] Total", totalPresentyAmount],
          ["B] CGST (1)", ` ${cgstAmount}`],
          ["C] SGST (2)", ` ${sgstAmount}`],
          ["D] IGST (3)", ` ${igstAmount}`],
          ["E] Total (A+B+C+D)", grandTotalAmount],
          ["F] Deduction ", 0],
          ["G] Grand Total (F-G)", grandTotalAmount],
          [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
        ];
  
        // Set startY for the second table immediately after the first table
        doc.autoTable({
          body: InvoiceData,
          startY: firstTableHeight + 0,
          theme: "grid",
          styles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: {
            0: { columnWidth: 157 },
            1: { columnWidth: 25, halign: "right" },
          },
        });
  
        // Calculate the height of the second table (invoice data table)
        const secondTableHeight = doc.previousAutoTable
          ? doc.previousAutoTable.finalY
          : 0;
  
        const remainingSpace =
          doc.internal.pageSize.getHeight() - secondTableHeight - 20;
  
        // Check if there is enough space for the bank table
        if (remainingSpace < 0) {
          doc.addPage();
        }
  
        const bankDataTable = [
          [`Bank Name: ${bankUI.bankName}`],
          [`Bank Address: ${bankUI.bankAddress}`],
          [`A/C No.: ${bankUI.accountNumber}`],
          [`IFC Code: ${bankUI.ifsc}`],
          [`Swift Code: ${bankUI.swiftCode}`],
        ];
  
        const tableWidth2 = doc.internal.pageSize.width * 0.4;
  
        // Set startY for the bank table immediately after the first table
        doc.autoTable({
          body: bankDataTable,
          startY: secondTableHeight + 0,
          theme: "grid",
          styles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
        });
  
        // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
        const rectWidth = 97;
        const rectHeight = 45.5;
  
        // Set rectX1 to start from the right edge of the bank table
        const rectX1 = doc.internal.pageSize.width * 0.47;
  
        const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed
  
        doc.setDrawColor(0); // Set border color to black
        doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);
  
        const line1Text = "For Gsquare Techsystem PVT LTD";
        const line2Text = "Authorized Signatory & Seal";
        const textX = rectX1 + 25;
        const textY1 = rectY1 + 8;
        const textY2 = rectY1 + 30;
        doc.text(line1Text, textX - 5, textY1);
        doc.text(line2Text, textX, textY2);
  
        const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
        const pdfWindow = window.open("", "_blank");
        const pdfURL = URL.createObjectURL(pdfData);
        pdfWindow.location.href = pdfURL;
      }
    };
  
    const generatePDFPerMonth = async (invoiceData, annexureFlag) => {
      const doc = new jsPDF();
  
      const includePO = await Swal.fire({
        title: "Do you want to include PO number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });
  
      // Define includeEmployeeCategoryWithPO variable
      let includeEmployeeCategoryWithPO;
  
      if (includePO) {
        includeEmployeeCategoryWithPO = await Swal.fire({
          title:
            "Do you want to include Employee Category with PO Number in the PDF?",
          icon: "question",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          } else {
            return false;
          }
        });
  
        await generatePDF(includePO, includeEmployeeCategoryWithPO);
      } else {
        await generatePDF(includePO, false);
      }
      // Call generatePDF function with correct parameters
      async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
        const encompassingRectX = 3;
        const encompassingRectY = 4;
        const encompassingRectWidth = doc.internal.pageSize.width - 6;
        const encompassingRectHeight = doc.internal.pageSize.height - 8;
  
        doc.setDrawColor(0); // Set border color to black
        doc.setLineWidth(0.4); // Set border width
        doc.rect(
          encompassingRectX,
          encompassingRectY,
          encompassingRectWidth,
          encompassingRectHeight
        );
  
        const {
          companyProfileUI,
          invoiceDetails,
          clientUI,
          invoiceEmployeePoDetailsUIList,
          invoiceGstDetailsUI,
          invoicePaymentModeUI,
          bankUI,
          gstdetailsUI,
          invoiceDate,
          invoiceRefNo,
          poNumber,
          workingDays,
          empRateUnit,
          clientName,
        } = invoiceData[0];
  
        let companyInfoText =
          `${companyProfileUI.companyName}\n` +
          `${companyProfileUI.companyAddress}\n` +
          `${companyProfileUI.website}\n` +
          `${companyProfileUI.eMail}`;
  
        // Add the company information to the PDF for the current invoice
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Black color
        doc.setFont("helvetica", "bold"); // Set font to bold
        doc.text(companyInfoText, 15, 30);
        doc.setFont("helvetica", "normal"); // Reset font to normal
  
        // Continue with the rest of your PDF generation logic...
  
        const companyInfoContainerX = 14;
        const companyInfoContainerY = 10;
        const companyInfoContainerWidth =
          doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
        const companyInfoContainerHeight = 40;
  
        // Draw the container first
        doc.setDrawColor(0);
        doc.setLineWidth(0.4);
        doc.setFillColor(255, 255, 255);
        doc.rect(
          companyInfoContainerX,
          companyInfoContainerY,
          companyInfoContainerWidth,
          companyInfoContainerHeight,
          "FD"
        );
  
        // Add the logo with additional margin from the left border
        doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
  
        // Position the company information text with reduced margin after the logo
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after logo width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
  
        // Use the fetched invoice data in your PDF
  
        //const { companyData, clientData } = await fetchData();
  
        const CompInvo = [
          ["Invoice No: " + invoiceRefNo],
          ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
          ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
          ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
          ["Invoice Date: " + invoiceDate],
        ];
  
        const tableWidth = doc.internal.pageSize.width * 0.45;
        const leftMargin = doc.internal.pageSize.width - tableWidth - 10;
  
        // Calculate the height of the CompInvo table
        const compInvoTableHeight = doc.autoTable({
          body: CompInvo,
          theme: "grid",
          margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
          styles: {
            fontSize: 10,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            cellPadding: 2,
            marginBottom: 0,
            marginTop: 0,
            padding: 0,
          },
        }).lastAutoTable.finalY;
  
        const ClientInvo = [
          ["Service ACC Code: " + gstdetailsUI.hsnsac],
          ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
          ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
          ["Billing Period: " + clientUI.billingPeriod],
          ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
        ];
  
        const clientInvoTableHeight = 40;
  
        // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
        doc.autoTable({
          body: ClientInvo,
          theme: "grid",
          columnStyles: { 0: { cellWidth: "auto" } },
          startY: compInvoTableHeight, // Set startY explicitly
          margin: {
            top: compInvoTableHeight,
            left: leftMargin,
            right: 14,
            bottom: 0,
          },
          styles: {
            fontSize: 10,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
            tableHeight: 40,
          },
        });
  
        const firstTableHeight1 = doc.previousAutoTable.finalY;
        const billedToContainerHeight = 38.85;
  
        // Billed To container immediately after companyInfo container
  
        const billedToText = `Billed To: ${clientName}
        ${clientUI.clientAddress}
        
        
        Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
        Kind Attn: ${invoicePaymentModeUI.kindAttention}`;
  
        const billedToContainerX = companyInfoContainerX;
        const topMargin = 0;
        const containerHeight = 40; // Set the common height for both the billedToContainer and the table
        const billedToContainerY =
          companyInfoContainerY + companyInfoContainerHeight + topMargin;
        const billedToContainerWidth = companyInfoContainerWidth;
  
        doc.setDrawColor(0);
        doc.setLineWidth(0.4);
        doc.setFillColor(255, 255, 255);
        doc.rect(
          billedToContainerX,
          billedToContainerY,
          billedToContainerWidth,
          containerHeight,
          "FD"
        );
  
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
          maxWidth: billedToContainerWidth - 10,
        });
        doc.setFillColor(255, 255, 255);
  
        let tableData;
        let amountColumnIndex;
        if (includePO) {
          // Include PO number column in the PDF
          if (includeEmployeeCategoryWithPO) {
            tableData = invoiceEmployeePoDetailsUIList.map((row) => [
              row.emplyoyeeName,
              poNumber,
              row.employeeCategory,
              row.fromDate,
              row.toDate,
              workingDays,
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]);
            amountColumnIndex = 8;
          } else {
            tableData = invoiceEmployeePoDetailsUIList.map((row) => [
              row.emplyoyeeName,
              poNumber,
              row.fromDate,
              row.toDate,
              workingDays,
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]);
            amountColumnIndex = 7;
          }
        } else {
          // Exclude PO number column in the PDF
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
  
            row.fromDate,
            row.toDate,
            workingDays,
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 7;
        }
  
        const headerLabels =
          includePO && includeEmployeeCategoryWithPO
            ? [
                "Particulars",
                "PO NO.",
                "Employee Category",
                "From Date",
                "To date",
                "Working Days",
                "Billable Days",
                "Current PO Rate",
                "Amount",
              ]
            : includePO && !includeEmployeeCategoryWithPO
            ? [
                "Particulars",
                "PO NO.",
                "From Date",
                "To date",
                "Working Days",
                "Billable Days",
                "Current PO Rate",
                "Amount",
              ]
            : [
                "Particulars",
                "From Date",
                "To date",
                "Working Days",
                "Billable Days",
                "Current PO Rate",
                "Amount",
              ];
  
        // Generate the PDF table
        doc.autoTable({
          head: [headerLabels],
  
          body: tableData,
          startY: billedToContainerY + billedToContainerHeight + 0,
          theme: "grid",
          styles: {
            fontSize: 9,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: {
            [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
          },
        });
  
        // Calculate the height of the first table (invoice data table)
        const firstTableHeight = doc.previousAutoTable
          ? doc.previousAutoTable.finalY
          : 0;
  
        const {
          totalPresentyAmount,
          grandTotalAmount,
          totalTaxAmt,
          totalInWord,
        } = invoiceGstDetailsUI;
        const { cgstAmount, sgstAmount, igstAmount } = invoiceGstDetailsUI;
  
        const InvoiceData = [
          ["A] Total", totalPresentyAmount],
          ["B] CGST (1)", ` ${cgstAmount}`],
          ["C] SGST (2)", ` ${sgstAmount}`],
          ["D] IGST (3)", ` ${igstAmount}`],
          ["E] Total (A+B+C+D)", grandTotalAmount],
          ["F] Deduction ", 0],
          ["G] Grand Total (F-G)", grandTotalAmount],
          [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
        ];
  
        // Set startY for the second table immediately after the first table
        doc.autoTable({
          body: InvoiceData,
          startY: firstTableHeight + 0,
          theme: "grid",
          styles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: {
            0: { columnWidth: 157 },
            1: { columnWidth: 25, halign: "right" },
          },
        });
  
        // Calculate the height of the second table (invoice data table)
        const secondTableHeight = doc.previousAutoTable
          ? doc.previousAutoTable.finalY
          : 0;
  
        const remainingSpace =
          doc.internal.pageSize.getHeight() - secondTableHeight - 20;
  
        // Check if there is enough space for the bank table
        if (remainingSpace < 0) {
          doc.addPage();
        }
  
        const bankDataTable = [
          [`Bank Name: ${bankUI.bankName}`],
          [`Bank Address: ${bankUI.bankAddress}`],
          [`A/C No.: ${bankUI.accountNumber}`],
          [`IFC Code: ${bankUI.ifsc}`],
          [`Swift Code: ${bankUI.swiftCode}`],
        ];
  
        const tableWidth2 = doc.internal.pageSize.width * 0.4;
  
        // Set startY for the bank table immediately after the first table
        doc.autoTable({
          body: bankDataTable,
          startY: secondTableHeight + 0,
          theme: "grid",
          styles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
        });
  
        // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
        const rectWidth = 97;
        const rectHeight = 45.5;
  
        // Set rectX1 to start from the right edge of the bank table
        const rectX1 = doc.internal.pageSize.width * 0.47;
  
        const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed
  
        doc.setDrawColor(0); // Set border color to black
        doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);
  
        const line1Text = "For Gsquare Techsystem PVT LTD";
        const line2Text = "Authorized Signatory & Seal";
        const textX = rectX1 + 25;
        const textY1 = rectY1 + 8;
        const textY2 = rectY1 + 30;
        doc.text(line1Text, textX - 5, textY1);
        doc.text(line2Text, textX, textY2);
  
      //   if (annexureFlag === "true") {
      //     generateAnnexurePage = (doc, companyProfileUI, invoiceRefNo,invoiceDate,gstdetailsUI,invoiceEmployeePoDetailsUIList);
      // }
  
        const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
        const pdfWindow = window.open("", "_blank");
        const pdfURL = URL.createObjectURL(pdfData);
        pdfWindow.location.href = pdfURL;
      }
    };
  
    const generatePDFPerHour = async (invoiceData) => {
      const doc = new jsPDF();
  
      const includePO = await Swal.fire({
        title: "Do you want to include PO number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });
  
      // Define includeEmployeeCategoryWithPO variable
      let includeEmployeeCategoryWithPO;
  
      if (includePO) {
        includeEmployeeCategoryWithPO = await Swal.fire({
          title:
            "Do you want to include Employee Category with PO Number in the PDF?",
          icon: "question",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          } else {
            return false;
          }
        });
  
        await generatePDF(includePO, includeEmployeeCategoryWithPO);
      } else {
        await generatePDF(includePO, false);
      }
      // Call generatePDF function with correct parameters
      async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
        const encompassingRectX = 3;
        const encompassingRectY = 4;
        const encompassingRectWidth = doc.internal.pageSize.width - 6;
        const encompassingRectHeight = doc.internal.pageSize.height - 8;
  
        doc.setDrawColor(0); // Set border color to black
        doc.setLineWidth(0.4); // Set border width
        doc.rect(
          encompassingRectX,
          encompassingRectY,
          encompassingRectWidth,
          encompassingRectHeight
        );
  
        const {
          companyProfileUI,
          invoiceDetails,
          clientUI,
          invoiceEmployeePoDetailsUIList,
          invoiceGstDetailsUI,
          invoicePaymentModeUI,
          bankUI,
          gstdetailsUI,
          invoiceDate,
          invoiceRefNo,
          poNumber,
          workingDays,
          empRateUnit,
          clientName,
        } = invoiceData[0];
  
        let companyInfoText =
          `${companyProfileUI.companyName}\n` +
          `${companyProfileUI.companyAddress}\n` +
          `${companyProfileUI.website}\n` +
          `${companyProfileUI.eMail}`;
  
        // Add the company information to the PDF for the current invoice
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Black color
        doc.setFont("helvetica", "bold"); // Set font to bold
        doc.text(companyInfoText, 15, 30);
        doc.setFont("helvetica", "normal"); // Reset font to normal
  
        // Continue with the rest of your PDF generation logic...
  
        const companyInfoContainerX = 14;
        const companyInfoContainerY = 10;
        const companyInfoContainerWidth =
          doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
        const companyInfoContainerHeight = 40;
  
        // Draw the container first
        doc.setDrawColor(0);
        doc.setLineWidth(0.4);
        doc.setFillColor(255, 255, 255);
        doc.rect(
          companyInfoContainerX,
          companyInfoContainerY,
          companyInfoContainerWidth,
          companyInfoContainerHeight,
          "FD"
        );
  
        // Add the logo with additional margin from the left border
        doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
  
        // Position the company information text with reduced margin after the logo
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(companyInfoText, 25 + 20 + 2, 18, {
          // Change margin after logo width (e.g., 2 or 3)
          maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
        });
  
        // Use the fetched invoice data in your PDF
  
        //const { companyData, clientData } = await fetchData();
  
        const CompInvo = [
          ["Invoice No: " + invoiceRefNo],
          ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
          ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
          ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
          ["Invoice Date: " + invoiceDate],
        ];
  
        const tableWidth = doc.internal.pageSize.width * 0.45;
        const leftMargin = doc.internal.pageSize.width - tableWidth - 10;
  
        // Calculate the height of the CompInvo table
        const compInvoTableHeight = doc.autoTable({
          body: CompInvo,
          theme: "grid",
          margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
          styles: {
            fontSize: 10,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            cellPadding: 2,
            marginBottom: 0,
            marginTop: 0,
            padding: 0,
          },
        }).lastAutoTable.finalY;
  
        const ClientInvo = [
          ["Service ACC Code: " + gstdetailsUI.hsnsac],
          ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
          ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
          ["Billing Period: " + clientUI.billingPeriod],
          ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
        ];
  
        const clientInvoTableHeight = 40;
  
        // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
        doc.autoTable({
          body: ClientInvo,
          theme: "grid",
          columnStyles: { 0: { cellWidth: "auto" } },
          startY: compInvoTableHeight, // Set startY explicitly
          margin: {
            top: compInvoTableHeight,
            left: leftMargin,
            right: 14,
            bottom: 0,
          },
          styles: {
            fontSize: 10,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
            tableHeight: 40,
          },
        });
  
        const firstTableHeight1 = doc.previousAutoTable.finalY;
        const billedToContainerHeight = 38.85;
  
        // Billed To container immediately after companyInfo container
  
        const billedToText = `Billed To: ${clientName}
          ${clientUI.clientAddress}
          
          
          Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
          Kind Attn: ${invoicePaymentModeUI.kindAttention}`;
  
        const billedToContainerX = companyInfoContainerX;
        const topMargin = 0;
        const containerHeight = 40; // Set the common height for both the billedToContainer and the table
        const billedToContainerY =
          companyInfoContainerY + companyInfoContainerHeight + topMargin;
        const billedToContainerWidth = companyInfoContainerWidth;
  
        doc.setDrawColor(0);
        doc.setLineWidth(0.4);
        doc.setFillColor(255, 255, 255);
        doc.rect(
          billedToContainerX,
          billedToContainerY,
          billedToContainerWidth,
          containerHeight,
          "FD"
        );
  
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
          maxWidth: billedToContainerWidth - 10,
        });
        doc.setFillColor(255, 255, 255);
  
        let tableData;
        let amountColumnIndex;
        if (includePO) {
          // Include PO number column in the PDF
          if (includeEmployeeCategoryWithPO) {
            tableData = invoiceEmployeePoDetailsUIList.map((row) => [
              row.emplyoyeeName,
              poNumber,
              row.employeeCategory,
              row.fromDate,
              row.toDate,
  
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]);
            amountColumnIndex = 7;
          } else {
            tableData = invoiceEmployeePoDetailsUIList.map((row) => [
              row.emplyoyeeName,
  
              row.fromDate,
              row.toDate,
  
              row.presenty,
              row.currentPoRate,
              row.presentyAmt, // Calculate amount
            ]);
            amountColumnIndex = 6;
          }
        } else {
          // Exclude PO number column in the PDF
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
  
            row.fromDate,
            row.toDate,
  
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 5;
        }
  
        const headerLabels =
          includePO && includeEmployeeCategoryWithPO
            ? [
                "Particulars",
                "PO NO.",
                "Employee Category",
                "From Date",
                "To date",
                "Billable Hours",
                "Current PO Rate",
                "Amount",
              ]
            : includePO && !includeEmployeeCategoryWithPO
            ? [
                "Particulars",
                "From Date",
                "To date",
                "Billable Hours",
                "Current PO Rate",
                "Amount",
              ]
            : [
                "Particulars",
                "From Date",
                "To date",
                "Billable Hours",
                "Current PO Rate",
                "Amount",
              ];
  
        // Generate the PDF table
        doc.autoTable({
          head: [headerLabels],
  
          body: tableData,
          startY: billedToContainerY + billedToContainerHeight + 0,
          theme: "grid",
          styles: {
            fontSize: 9,
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: {
            [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
          },
        });
  
        // Calculate the height of the first table (invoice data table)
        const firstTableHeight = doc.previousAutoTable
          ? doc.previousAutoTable.finalY
          : 0;
  
        const {
          totalPresentyAmount,
          grandTotalAmount,
          totalTaxAmt,
          totalInWord,
        } = invoiceGstDetailsUI;
        const { cgstAmount, sgstAmount, igstAmount } = invoiceGstDetailsUI;
  
        const InvoiceData = [
          ["A] Total", totalPresentyAmount],
          ["B] CGST (1)", ` ${cgstAmount}`],
          ["C] SGST (2)", ` ${sgstAmount}`],
          ["D] IGST (3)", ` ${igstAmount}`],
          ["E] Total (A+B+C+D)", grandTotalAmount],
          ["F] Deduction ", 0],
          ["G] Grand Total (F-G)", grandTotalAmount],
          [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
        ];
  
        // Set startY for the second table immediately after the first table
        doc.autoTable({
          body: InvoiceData,
          startY: firstTableHeight + 0,
          theme: "grid",
          styles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: {
            0: { columnWidth: 157 },
            1: { columnWidth: 25, halign: "right" },
          },
        });
  
        // Calculate the height of the second table (invoice data table)
        const secondTableHeight = doc.previousAutoTable
          ? doc.previousAutoTable.finalY
          : 0;
  
        const remainingSpace =
          doc.internal.pageSize.getHeight() - secondTableHeight - 20;
  
        // Check if there is enough space for the bank table
        if (remainingSpace < 0) {
          doc.addPage();
        }
  
        const bankDataTable = [
          [`Bank Name: ${bankUI.bankName}`],
          [`Bank Address: ${bankUI.bankAddress}`],
          [`A/C No.: ${bankUI.accountNumber}`],
          [`IFC Code: ${bankUI.ifsc}`],
          [`Swift Code: ${bankUI.swiftCode}`],
        ];
  
        const tableWidth2 = doc.internal.pageSize.width * 0.4;
  
        // Set startY for the bank table immediately after the first table
        doc.autoTable({
          body: bankDataTable,
          startY: secondTableHeight + 0,
          theme: "grid",
          styles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
        });
  
        // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
        const rectWidth = 97;
        const rectHeight = 45.5;
  
        // Set rectX1 to start from the right edge of the bank table
        const rectX1 = doc.internal.pageSize.width * 0.47;
  
        const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed
  
        doc.setDrawColor(0); // Set border color to black
        doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);
  
        const line1Text = "For Gsquare Techsystem PVT LTD";
        const line2Text = "Authorized Signatory & Seal";
        const textX = rectX1 + 25;
        const textY1 = rectY1 + 8;
        const textY2 = rectY1 + 30;
        doc.text(line1Text, textX - 5, textY1);
        doc.text(line2Text, textX, textY2);
  
        const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
        const pdfWindow = window.open("", "_blank");
        const pdfURL = URL.createObjectURL(pdfData);
        pdfWindow.location.href = pdfURL;
      }
    };
    
    const generateProjectPDFPerHour = async (invoiceData) => {
      const doc = new jsPDF();
  
      const includePO = await Swal.fire({
        title: "Do you want to include PO number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then((result) => {
        return result.isConfirmed;
      });
  
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;
  
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );
  
      const {
        companyProfileUI,
        invoiceDetails,
        clientUI,
        invoiceProjectPoDetailsUIList,
        invoiceGstDetailsUI,
        bankUI,
        gstdetailsUI,
        invoiceDate,
        invoiceRefNo,
        poNumber,
        workingDays,
        empRateUnit,
        clientName,
        invoicePaymentModeUI,
      } = invoiceData[0];
  
      let companyInfoText =
        `${companyProfileUI.companyName}\n` +
        `${companyProfileUI.companyAddress}\n` +
        `${companyProfileUI.website}\n` +
        `${companyProfileUI.eMail}`;
  
      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal
  
      // Continue with the rest of your PDF generation logic...
  
      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;
  
      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );
  
      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
  
      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
  
      // Use the fetched invoice data in your PDF
  
      //const { companyData, clientData } = await fetchData();
  
      const CompInvo = [
        ["Invoice No: " + invoiceRefNo],
        ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
        ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
        ["Invoice Date: " + invoiceDate],
      ];
  
      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;
  
      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;
  
      const ClientInvo = [
        ["Service ACC Code: " + gstdetailsUI.hsnsac],
        ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientUI.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];
  
      const clientInvoTableHeight = 40;
  
      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });
  
      const firstTableHeight1 = doc.previousAutoTable.finalY;
      const billedToContainerHeight = 38.85;
  
      // Extract clientAddress from the fetched data
      const clientAddress = clientUI.clientAddress;
  
      // Billed To container immediately after companyInfo container
      const billedToText = `Billed To: ${clientName}
        ${clientUI.clientAddress}
        
        
        Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
        Ph. : ${clientUI.clientContactNumber}
        
        Kind Attn: ${invoicePaymentModeUI.kindAttention}`;
  
      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;
  
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );
  
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
        maxWidth: billedToContainerWidth - 10,
      });
      doc.setFillColor(255, 255, 255);
  
      let tableData;
      let amountColumnIndex;
  
      if (includePO) {
        // Include PO number column in the PDF
        tableData = invoiceProjectPoDetailsUIList.map((row) => [
          row.projectName,
          row.projectPoNumber,
          row.projectFromDate.split("-").reverse().join("-"),
          row.projectToDate.split("-").reverse().join("-"),
  
          row.ratePerDays,
          row.monthWorkDays,
          row.projectWorkAmount,
         
        ]);
        amountColumnIndex = 6;
      }
  
      const headerLabels = includePO
        ? [
            "Particulars",
            "PO NO.",
            "From Date",
            "To date",
            "Billable Hours",
            "Unit Price",
            "Amount",
          ]
        : [
            "Particulars",
            "From Date",
            "To date",
            "Billable Hours",
            "Unit Price",
            "Amount",
          ];
  
      doc.autoTable({
        head: [headerLabels],
        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
  
        columnStyles: {
          7: { columnWidth: 25, halign: "right" },
        },
      });
      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;
  
      const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
        invoiceGstDetailsUI;
        const { cgstAmount, sgstAmount, igstAmount } = invoiceGstDetailsUI;
  
        const InvoiceData = [
          ["A] Total", totalPresentyAmount],
          ["B] CGST (1)", ` ${cgstAmount}`],
          ["C] SGST (2)", ` ${sgstAmount}`],
          ["D] IGST (3)", ` ${igstAmount}`],
          ["E] Total (A+B+C+D)", grandTotalAmount],
          ["F] Deduction ", 0],
          ["G] Grand Total (F-G)", grandTotalAmount],
          [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
        ];
    
        // Set startY for the second table immediately after the first table
        doc.autoTable({
          body: InvoiceData,
          startY: firstTableHeight + 0,
          theme: "grid",
          styles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            marginBottom: 0,
          },
          columnStyles: {
            0: { columnWidth: 157 },
            1: { columnWidth: 25, halign: "right" },
          },
        });
      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;
  
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;
  
      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
  
      const bankDataTable = [
        [`Bank Name: ${bankUI.bankName}`],
        [`Bank Address: ${bankUI.bankAddress}`],
        [`A/C No.: ${bankUI.accountNumber}`],
        [`IFC Code: ${bankUI.ifsc}`],
        [`Swift Code: ${bankUI.swiftCode}`],
      ];
  
      const tableWidth2 = doc.internal.pageSize.width * 0.4;
  
      // Set startY for the bank table immediately after the first table
      doc.autoTable({
        body: bankDataTable,
        startY: secondTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
      });
  
      // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
      const rectWidth = 97;
      const rectHeight = 45.5;
  
      // Set rectX1 to start from the right edge of the bank table
      const rectX1 = doc.internal.pageSize.width * 0.47;
  
      const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed
  
      doc.setDrawColor(0); // Set border color to black
      doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);
  
      const line1Text = "For Gsquare Techsystem PVT LTD";
      const line2Text = "Authorized Signatory & Seal";
      const textX = rectX1 + 25;
      const textY1 = rectY1 + 8;
      const textY2 = rectY1 + 30;
      doc.text(line1Text, textX - 5, textY1);
      doc.text(line2Text, textX, textY2);
  
      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    };
  
    const generateProjectPDFPerDay = async (invoiceData) => {
      const doc = new jsPDF();
  
      const includePO = await Swal.fire({
        title: "Do you want to include PO number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then((result) => {
        return result.isConfirmed;
      });
  
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;
  
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );
  
      const {
        companyProfileUI,
        invoiceDetails,
        clientUI,
        invoiceProjectPoDetailsUIList,
        invoiceGstDetailsUI,
        bankUI,
        gstdetailsUI,
        invoiceDate,
        invoiceRefNo,
        poNumber,
        workingDays,
        empRateUnit,
        clientName,
        invoicePaymentModeUI,
      } = invoiceData[0];
  
      let companyInfoText =
        `${companyProfileUI.companyName}\n` +
        `${companyProfileUI.companyAddress}\n` +
        `${companyProfileUI.website}\n` +
        `${companyProfileUI.eMail}`;
  
      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal
  
      // Continue with the rest of your PDF generation logic...
  
      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;
  
      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );
  
      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
  
      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
  
      // Use the fetched invoice data in your PDF
  
      //const { companyData, clientData } = await fetchData();
  
      const CompInvo = [
        ["Invoice No: " + invoiceRefNo],
        ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
        ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
        ["Invoice Date: " + invoiceDate],
      ];
  
      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;
  
      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;
  
      const ClientInvo = [
        ["Service ACC Code: " + gstdetailsUI.hsnsac],
        ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientUI.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];
  
      const clientInvoTableHeight = 40;
  
      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });
  
      const firstTableHeight1 = doc.previousAutoTable.finalY;
      const billedToContainerHeight = 38.85;
  
      // Extract clientAddress from the fetched data
      const clientAddress = clientUI.clientAddress;
  
      // Billed To container immediately after companyInfo container
      const billedToText = `Billed To: ${clientName}
        ${clientUI.clientAddress}
        
        
        Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
        Ph. : ${clientUI.clientContactNumber}
        
        Kind Attn: ${invoicePaymentModeUI.kindAttention}`;
  
      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;
  
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );
  
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
        maxWidth: billedToContainerWidth - 10,
      });
      doc.setFillColor(255, 255, 255);
  
      let tableData;
      let amountColumnIndex;
  
      if (includePO) {
        // Include PO number column in the PDF
        tableData = invoiceProjectPoDetailsUIList.map((row) => [
          row.projectName,
          row.projectPoNumber,
          row.projectFromDate.split("-").reverse().join("-"),
          row.projectToDate.split("-").reverse().join("-"),
  
          row.ratePerDays,
          row.monthWorkDays,
          row.projectWorkAmount,
        ]);
        amountColumnIndex = 6;
      }
  
      const headerLabels = includePO
        ? [
            "Particulars",
            "PO NO.",
            "From Date",
            "To date",
            "Billable Days",
            "Unit Price",
            "Amount",
          ]
        : [
            "Particulars",
            "From Date",
            "To date",
            "Billable Days",
            "Unit Price",
            "Amount",
          ];
  
      doc.autoTable({
        head: [headerLabels],
        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
  
        columnStyles: {
          7: { columnWidth: 25, halign: "right" },
        },
      });
      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;
  
      const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
        invoiceGstDetailsUI;
        const { cgstAmount, sgstAmount, igstAmount } = invoiceGstDetailsUI;
  
      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
      ];
  
      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { columnWidth: 157 },
          1: { columnWidth: 25, halign: "right" },
        },
      });
  
      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;
  
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;
  
      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
  
      const bankDataTable = [
        [`Bank Name: ${bankUI.bankName}`],
        [`Bank Address: ${bankUI.bankAddress}`],
        [`A/C No.: ${bankUI.accountNumber}`],
        [`IFC Code: ${bankUI.ifsc}`],
        [`Swift Code: ${bankUI.swiftCode}`],
      ];
  
      const tableWidth2 = doc.internal.pageSize.width * 0.4;
  
      // Set startY for the bank table immediately after the first table
      doc.autoTable({
        body: bankDataTable,
        startY: secondTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
      });
  
      // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
      const rectWidth = 97;
      const rectHeight = 45.5;
  
      // Set rectX1 to start from the right edge of the bank table
      const rectX1 = doc.internal.pageSize.width * 0.47;
  
      const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed
  
      doc.setDrawColor(0); // Set border color to black
      doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);
  
      const line1Text = "For Gsquare Techsystem PVT LTD";
      const line2Text = "Authorized Signatory & Seal";
      const textX = rectX1 + 25;
      const textY1 = rectY1 + 8;
      const textY2 = rectY1 + 30;
      doc.text(line1Text, textX - 5, textY1);
      doc.text(line2Text, textX, textY2);
  
      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    };
    
    const generateOtherChargesPDF = async (invoiceData) => {
      const doc = new jsPDF();
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;
  
      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );
  
      const {
        companyProfileUI,
        invoiceDetails,
        clientUI,
        invoiceOtherChargesDetailsUIList,
        invoiceGstDetailsUI,
        bankUI,
        gstdetailsUI,
        invoiceDate,
        invoiceRefNo,
        poNumber,
        workingDays,
        empRateUnit,
        clientName,
        invoicePaymentModeUI,
      } = invoiceData[0];
  
      let companyInfoText =
        `${companyProfileUI.companyName}\n` +
        `${companyProfileUI.companyAddress}\n` +
        `${companyProfileUI.website}\n` +
        `${companyProfileUI.eMail}`;
  
      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal
  
      // Continue with the rest of your PDF generation logic...
  
      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;
  
      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );
  
      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)
  
      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });
  
      // Use the fetched invoice data in your PDF
  
      //const { companyData, clientData } = await fetchData();
  
      const CompInvo = [
        ["Invoice No: " + invoiceRefNo],
        ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
        ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
        ["Invoice Date: " + invoiceDate],
      ];
  
      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;
  
      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;
  
      const ClientInvo = [
        ["Service ACC Code: " + gstdetailsUI.hsnsac],
        ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientUI.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];
  
      const clientInvoTableHeight = 40;
  
      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });
  
      const firstTableHeight1 = doc.previousAutoTable.finalY;
      const billedToContainerHeight = 38.85;
  
      // Extract clientAddress from the fetched data
      const clientAddress = clientUI.clientAddress;
  
      // Billed To container immediately after companyInfo container
      const billedToText = `Billed To: ${clientName}
      ${clientUI.clientAddress}
      
      
      Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
      Ph. : ${clientUI.clientContactNumber}
      
      Kind Attn: ${invoicePaymentModeUI.kindAttention}`;
  
      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;
  
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );
  
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
        maxWidth: billedToContainerWidth - 10,
      });
      doc.setFillColor(255, 255, 255);
      const tableData = invoiceOtherChargesDetailsUIList.map((row) => [
        row.employeeNameAndCode, // replace with the actual property names from otherChargesList
        row.employeeRole,
        row.description,
        row.ctcAmount,
      ]);
  
      doc.autoTable({
        head: [["Employee Name", "Employee Role", "Description", "Amount"]],
        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          3: { columnWidth: 35, halign: "right" },
        },
      });
      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;
  
      const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
        invoiceGstDetailsUI;
      const { cgst, sgst, igst } = invoiceGstDetailsUI;
  
      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
        ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
        ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
      ];
  
      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { columnWidth: 147 },
          1: { columnWidth: 35, halign: "right" },
        },
      });
  
      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;
  
      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;
  
      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }
  
      const bankDataTable = [
        [`Bank Name: ${bankUI.bankName}`],
        [`Bank Address: ${bankUI.bankAddress}`],
        [`A/C No.: ${bankUI.accountNumber}`],
        [`IFC Code: ${bankUI.ifsc}`],
        [`Swift Code: ${bankUI.swiftCode}`],
      ];
  
      const tableWidth2 = doc.internal.pageSize.width * 0.4;
  
      // Set startY for the bank table immediately after the first table
      doc.autoTable({
        body: bankDataTable,
        startY: secondTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
      });
  
      // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
      const rectWidth = 97;
      const rectHeight = 45.5;
  
      // Set rectX1 to start from the right edge of the bank table
      const rectX1 = doc.internal.pageSize.width * 0.47;
  
      const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed
  
      doc.setDrawColor(0); // Set border color to black
      doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);
  
      const line1Text = "For Gsquare Techsystem PVT LTD";
      const line2Text = "Authorized Signatory & Seal";
      const textX = rectX1 + 25;
      const textY1 = rectY1 + 8;
      const textY2 = rectY1 + 30;
      doc.text(line1Text, textX - 5, textY1);
      doc.text(line2Text, textX, textY2);
  
      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    };
  
    
    
  

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Paper
              elevation={3}
              style={{
                padding: 20,
                marginBottom: 20,
                marginTop: 20,
                backgroundColor: "#2196f3",
                color: "white",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ marginBottom: "15px", color: "darkblue" }}
                >
                  Invoice Details
                </Typography>
              </div>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Total Out Standing Amount"
                  fullWidth
                  variant="standard"
                  name="totalOutStandingAmount"
                  onChange={handleChange}
                  disabled
                  value={formData.totalOutStandingAmount}
                  required
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={financialYears}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: { name: "financialYear", value: newValue || "" },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Financial Year"
                      variant="standard"
                      name="financialYear"
                      required
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={clientNames}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "clientNameAndCode",
                        value: newValue || "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name And Code"
                      variant="standard"
                      name="clientNameAndCode"
                      required
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={invoiceRefNos}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "invoiceRefNo",
                        value: newValue ? newValue.value : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Ref No"
                      variant="standard"
                      name="invoiceRefNo"
                      required
                      margin="normal"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Outstanding Amount"
                  fullWidth
                  variant="standard"
                  name="outStandingAmount"
                  disabled
                  onChange={handleChange}
                  value={formData.outStandingAmount}
                  required
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Pay Date"
                  type="date"
                  fullWidth
                  variant="standard"
                  name="payDate"
                  onChange={handleChange}
                  value={formData.payDate}
                  required
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Paid Amount"
                  fullWidth
                  variant="standard"
                  name="paidAmount"
                  onChange={handleChange}
                  value={formData.paidAmount}
                  required
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="TDS Amount"
                  fullWidth
                  variant="standard"
                  name="tdsAmount"
                  onChange={handleChange}
                  value={formData.tdsAmount}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Deduction Amount"
                  fullWidth
                  variant="standard"
                  name="deductionAmount"
                  onChange={handleChange}
                  value={formData.deductionAmount}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Deduction Remark"
                  fullWidth
                  variant="standard"
                  name="deductionRemark"
                  onChange={handleChange}
                  value={formData.deductionRemark}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="UnPaid Amount"
                  fullWidth
                  variant="standard"
                  name="unPaidAmount"
                  //   onChange={handleChange}
                  disabled
                  value={formData.unPaidAmount}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={["Cheque", "Bank Transfer"]}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: { name: "payMode", value: newValue || "" },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pay Mode"
                      variant="standard"
                      name="payMode"
                      required
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={[
                    "HR",
                    "Marketing Head",
                    "IT",
                    "Design",
                    "Management",
                    "Product Assurance",
                    "Training",
                    "CAE",
                    "Welding",
                  ]}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: { name: "collectedBy", value: newValue || "" },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Collected By"
                      variant="standard"
                      name="collectedBy"
                      required
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Collected Name"
                  fullWidth
                  variant="standard"
                  name="collectedName"
                  onChange={handleChange}
                  value={formData.collectedName}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  {["Cheque", "Bank Transfer"].includes(formData.payMode) && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Cheque Number/Bank UTRNo"
                          fullWidth
                          variant="standard"
                          name="chqNumber"
                          onChange={handleChange}
                          value={formData.chqNumber}
                          required
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Cheque Date"
                          fullWidth
                          type="date"
                          variant="standard"
                          name="chqDate"
                          onChange={handleChange}
                          value={formData.chqDate}
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Bank Branch"
                          fullWidth
                          variant="standard"
                          name="bankBranch"
                          margin="normal"
                          onChange={handleChange}
                          value={formData.bankBranch}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleExportToExcel}
                  >
                    Excel
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handlegeneratePDF(formData.invoiceRefNo)
                                    } // Pass a function reference here
                                    color="secondary"
                                  >
                                    VIEW INVOICE
                                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Paper
              elevation={3}
              style={{
                padding: 20,
                marginBottom: 20,
                marginTop: 20,
                backgroundColor: "#2196f3",
                color: "white",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  component="div"
                  gutterBottom
                  style={{ marginBottom: "15px", color: "darkblue" }}
                >
                  {employeeDetails.length > 0
                    ? "Outstanding Employee  Details"
                    : projectDetails.length > 0
                    ? "Outstanding Project  Details"
                    : otherChargesDetails.length > 0
                    ? "Outstanding OtherCharges Details"
                    : "Invoice Details"}
                </Typography>
              </div>
            </Paper>
            {employeeDetails.length > 0 && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Month Name</TableCell>
                      <TableCell>Month Year</TableCell>
                      <TableCell>Employee Name</TableCell>
                      <TableCell>Present Amount</TableCell>
                      <TableCell>Catneen Amount</TableCell>
                      <TableCell>Transport Amount</TableCell>
                      <TableCell>Employee Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeeDetails.map((employee, index) => (
                      <TableRow key={index}>
                        <TableCell>{employee?.monthName || "N/A"}</TableCell>
                        <TableCell>{employee?.monthYear || "N/A"}</TableCell>
                        <TableCell>{employee?.employeeName || "N/A"}</TableCell>
                        <TableCell>
                          {employee?.presentyAmount || "N/A"}
                        </TableCell>
                        <TableCell>
                          {employee?.catneenAmount || "N/A"}
                        </TableCell>
                        <TableCell>
                          {employee?.transportAmount || "N/A"}
                        </TableCell>
                        <TableCell>{employee?.employeeRate || "N/A"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {projectDetails.length > 0 && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Project Name</TableCell>
                      <TableCell>Particular</TableCell>
                      <TableCell>Project PO Number</TableCell>
                      <TableCell>Project From Date</TableCell>
                      <TableCell>Project To Date</TableCell>
                      <TableCell>Rate Per Days</TableCell>
                      <TableCell>Month Work Days</TableCell>
                      <TableCell>Project Work Amount</TableCell>
                      <TableCell>Month Name</TableCell>
                      <TableCell>Month Year</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.map((project, index) => (
                      <TableRow key={index}>
                        <TableCell>{project?.projectName || "N/A"}</TableCell>
                        <TableCell>{project?.particular || "N/A"}</TableCell>
                        <TableCell>
                          {project?.projectPoNumber || "N/A"}
                        </TableCell>
                        <TableCell>
                          {project.projectFromDate
                            ? project.projectFromDate
                                .split("-")
                                .reverse()
                                .join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {project.projectToDate
                            ? project.projectToDate
                                .split("-")
                                .reverse()
                                .join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{project?.ratePerDays || "N/A"}</TableCell>
                        <TableCell>{project?.monthWorkDays || "N/A"}</TableCell>
                        <TableCell>
                          {project?.projectWorkAmount || "N/A"}
                        </TableCell>
                        <TableCell>{project?.monthName || "N/A"}</TableCell>
                        <TableCell>{project?.monthYear || "N/A"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {otherChargesDetails.length > 0 && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Employee Name</TableCell>
                      <TableCell>Employee Role</TableCell>
                      <TableCell>Select Item</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Charge Per Amount</TableCell>
                      <TableCell>CTC Amount</TableCell>
                      <TableCell>Percentage Amount</TableCell>
                      <TableCell>Month Name</TableCell>
                      <TableCell>Month Year</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {otherChargesDetails.map((charge, index) => (
                      <TableRow key={index}>
                        <TableCell>{charge?.employeeName || "N/A"}</TableCell>
                        <TableCell>{charge?.employeeRole || "N/A"}</TableCell>
                        <TableCell>{charge?.selectItem || "N/A"}</TableCell>
                        <TableCell>{charge?.description || "N/A"}</TableCell>
                        <TableCell>
                          {charge?.chargePerAmount || "N/A"}
                        </TableCell>
                        <TableCell>{charge?.ctcAmount || "N/A"}</TableCell>
                        <TableCell>
                          {charge?.percentageAmount || "N/A"}
                        </TableCell>
                        <TableCell>{charge?.monthName || "N/A"}</TableCell>
                        <TableCell>{charge?.monthYear || "N/A"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: "20px" }}>
          <CardContent>
            <Paper
              elevation={3}
              style={{
                padding: 20,
                marginBottom: 20,
                backgroundColor: "#2196f3",
                color: "white",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ marginBottom: "15px", color: "darkblue" }}
                >
                  Outstanding Collection UnPaid History
                </Typography>
              </div>
            </Paper>
            {collectionHistory && collectionHistory.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      {/* <TableCell>ID</TableCell> */}
                      <TableCell>Invoice Ref No</TableCell>
                      {/* <TableCell>Total Standing Amount</TableCell> */}
                      {/* <TableCell>Financial Year</TableCell> */}
                      <TableCell>Client Name</TableCell>
                      <TableCell>Outstanding Amount</TableCell>
                      <TableCell>Paid Amount</TableCell>
                      <TableCell>UnPaid Amount</TableCell>
                      <TableCell>Deduction Amount</TableCell>
                      <TableCell>Deduction Particular</TableCell>
                      <TableCell>TDS Amount</TableCell>
                      <TableCell>Pay Mode</TableCell>
                      <TableCell>Pay Date</TableCell>
                      <TableCell>Collected By</TableCell>
                      <TableCell>Collected Name</TableCell>
                      <TableCell>Cheque Number</TableCell>
                      <TableCell>Cheque Date</TableCell>
                      <TableCell>Bank Branch</TableCell>
                      {/* <TableCell>Created Date</TableCell>
                      <TableCell>Updated Date</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {collectionHistory.map((history, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{history.id}</TableCell> */}
                        <TableCell>{history.invoiceRefNo}</TableCell>
                        {/* <TableCell>{history.totalOutStandingAmount}</TableCell> */}
                        {/* <TableCell>{history.financialYear}</TableCell> */}
                        <TableCell>{history.clientNameAndCode}</TableCell>
                        <TableCell>{history.outStandingAmount}</TableCell>
                        <TableCell>{history.paidAmount}</TableCell>
                        <TableCell>{history.unPaidAmount}</TableCell>
                        <TableCell>{history.deductionAmount}</TableCell>
                        <TableCell>{history.deductionRemark}</TableCell>
                        <TableCell>{history.tdsAmount}</TableCell>
                        <TableCell>{history.payMode}</TableCell>
                        <TableCell>
                          {history.payDate
                            ? history.payDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{history.collectedBy}</TableCell>
                        <TableCell>{history.collectedName}</TableCell>
                        <TableCell>{history.chqNumber}</TableCell>
                        <TableCell>
                          {history.chqDate
                            ? history.chqDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{history.bankBranch}</TableCell>
                        {/* <TableCell>{history.createdDate}</TableCell>
                        <TableCell>{history.updatedDate}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body2" color="textSecondary" component="p">
                No Outstanding Collection Unpaid History found.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default OutStandingUnPaidHistory;
