import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Headers from "./Pages/Header";
import { createTheme, ThemeProvider } from "@material-ui/core";

import AddClientPage from "./Component/Client/Pages/RegisterClientPage";
import ClientListPage from "./Component/Client/Pages/ClientListPage";
import Login1 from "./LoginUser/ERPLogin";
// import ProjectInvoicePage from "./Component/ProjectInvoice/Pages/ProjectInvoicePage";
import OtherChargesInvoiceListPage from "./Component/InvoiceList/Pages/OtherChargesInvoiceListt";
import EmployeeInvoiceListPage from "./Component/InvoiceList/Pages/EmployeeInvoicePage";
import ProjectInvoiceListPage from "./Component/InvoiceList/Pages/ProjectInvoiceList";
import OutStandingUnPaidHistoryPage from "./Component/OutStandingCollection/Pages/UnPaidInvoiceHistoryPage";
import OutStandingPaidHistoryPage from "./Component/OutStandingCollection/Pages/PaidInvoiceHistory";
import InvoiceReportPage from "./Component/Report/Page/InvoiceReportPage";
import ProjectPOListPage from "./Validation/Page/ProjectPOList";
import EmpPOListPage from "./Validation/Page/EmployeePOList";
import OtherChargesInvoiceListValPage from "./Validation/Page/OtherChargesInvoice";
import EmployeeInvoiceList12 from "./Validation/EmployeeInvoiceList";
import UpdateEmpPOPage from "./Component/EmpPO/Pages/UpdateEmpPOPage";
import GenerateEmpPOPage from "./Component/EmpPO/Pages/GenerateEmpPOPage";
import ERPLogin from "./LoginUser/ERPLogin";
import GenerateEmployeeInvoicePage from "./Component/Invoice/Pages/Employee_Invoice_Page";
import GenerateOtherChargesInvoicePage from "./Component/Invoice/Pages/OtherChargesInvoicePage";
import GenerateProjectInvoicePage from "./Component/Invoice/Pages/ProjectInvoicePage";
import GenerateProjectPOPage from "./Component/ProjectDetails/Page/GenerateProjectPOPage";
import UpdateProjectPOPage from "./Component/ProjectDetails/Page/UpdateProjectPOPage";
import UpdateEmployeePage from "./Component/Employee/Pages/UpdateEmployeePage";
import RegisterEmployeePage from "./Component/Employee/Pages/RegisterEmployeePage";
// import ProjectInvoiceAdd from "./Component/ProjectInvoice/ProjectInvoice";
import OtherChargesInvoiceListVal from "./Validation/ValidationOtherChargesList";
import UpdateClientPage from "./Component/Client/Pages/ClientListPage";
import RegisterClientPage from "./Component/Client/Pages/RegisterClientPage";
import UpdateEmpPOSearchPage from "./Pages/EmpPOSearchPagination";
import GenerateEmployeeInvoice2 from "./Validation/GenereteEmployeeInvoiceTest";

import RegisterClientVal from "./Validation/RegisterClientVal";
import UpdateClient2 from "./Validation/UpdateClientN";
import GenerateEmployeeInvoiceDate from "./Validation/InvoiceDate";
import GenerateProjectInvoiceDate from "./Validation/ProjectInvoiceDate";
import MyForm from "./Validation/Date";
// import UpdateEmpPOLL from "./Validation/New";
// import UpdateEmployee3 from "./Validation/New3";
// import GenerateOtherChargeInvoice2 from "./Validation/Othercharges";
// import OtherChargeInvoice from "./Component/OtherChargesInvoice/OtherChargesInvoice";
import RegisterBank from "./Component/Bank/RegisterBank";
import UpdateBank from "./Component/Bank/UpdateBank";
import RegisterBankPage from "./Component/Bank/Page/RegisterBankPage";
import UpdateBankPage from "./Component/Bank/Page/UpdateBank";
import UpdateEmpPONew3 from "./Validation/EmpPOListNew";
import RegisterCompanyProfilePage from "./Component/CompanyProfile/Page/RegisterCompanyProfileLPage";
import UpdateCompanyProfilePage from "./Component/CompanyProfile/Page/UpdateCompanyProfilePage";
import EmployeeSummary from "./Component/Summary/EmployeSummary";
import EmployeeSummaryPage from "./Component/Summary/Page/EmployeeSummaryPage";
import GenerateEmployeeInvoiceTest from "./Validation/GenereteInvoiceEmployee";
import UpdateEmployee2 from "./Validation/EmployeeupdateNew";
import GenerateProjectInvoiceNewVal from "./Validation/GeneteteProjectInvoice";
import GenerateQuotation from "./Component/Quotation/GenereateQuotation";
import GenerateQuotationPage from "./Component/Quotation/Pages/GenerateQuotationPage";
import UpdateQuotationPage from "./Component/Quotation/Pages/UpdateQuotationPage";
import ProjectPOSummaryPage from "./Component/Summary/Page/ProjectPOSummaryPage";
import TableDataComponent from "./Component/Summary/TableDataComponent";
function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Roboto Condensed", "sans-serif"].join(","),
      fontWeightBold: 700,
    },
  });
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/empList" element={<EmployeeInvoiceList12 />}></Route>
          <Route path="/" element={<Login1 />}></Route>
          <Route path="/navBar" element={<Headers />}></Route>
          <Route path="/login" element={<ERPLogin />}></Route>

          {/* Client Add */}
          <Route
            path="/registerClient"
            element={<RegisterClientPage />}
          ></Route>
          <Route path="/updateClient" element={<UpdateClientPage />}></Route>

          {/* Employee Add */}
          <Route
            path="/registerEmployee"
            element={<RegisterEmployeePage />}
          ></Route>
          <Route
            path="/updateEmployee"
            element={<UpdateEmployeePage />}
          ></Route>
          {/* Generate Employee PO */}
          <Route
            path="/generateEmployeePO"
            element={<GenerateEmpPOPage />}
          ></Route>
          <Route path="/updateEmployeePO" element={<UpdateEmpPOPage />}></Route>
          {/* Generete Project PO */}
          <Route
            path="/generateProjectPO"
            element={<GenerateProjectPOPage />}
          ></Route>
          <Route
            path="/updateProjectPO"
            element={<UpdateProjectPOPage />}
          ></Route>

          {/* Generete Invoices */}
          <Route
            path="/generateEmployeeInvoice"
            element={<GenerateEmployeeInvoicePage />}
          ></Route>
          <Route
            path="/generateProjectInvoice"
            element={<GenerateProjectInvoicePage />}
          ></Route>
          <Route
            path="/generateOtherChargesInvoice"
            element={<GenerateOtherChargesInvoicePage />}
          ></Route>

          {/* Routing For Invoice List Page */}
          <Route
            path="/otherChargesInvoiceList"
            element={<OtherChargesInvoiceListPage />}
          ></Route>
          <Route
            path="/employeeInvoiceList"
            element={<EmployeeInvoiceListPage />}
          ></Route>
          <Route
            path="/projectInvoiceList"
            element={<ProjectInvoiceListPage />}
          ></Route>

          {/* Routing For OutStanding Invoice */}
          <Route
            path="/outStandingUnpaidHistory"
            element={<OutStandingUnPaidHistoryPage />}
          ></Route>
          <Route
            path="/outStandingpaidHistory"
            element={<OutStandingPaidHistoryPage />}
          ></Route>

          {/* Invoice Report */}
          <Route
            path="/invoiceReportPage"
            element={<InvoiceReportPage />}
          ></Route>

          {/* Testing Routes */}
          {/* <Route
            path="/projectInvoice"
            element={<ProjectInvoicePage />}
          ></Route> */}

          {/* Validate Purchase Order List */}
          <Route
            path="/projectPOListPage"
            element={<ProjectPOListPage />}
          ></Route>
          <Route path="/empPOListPage" element={<EmpPOListPage />}></Route>

          <Route
            path="/otherChargesInvoiceListValPage"
            element={<OtherChargesInvoiceListValPage />}
          ></Route>
          {/* <Route
            path="/projectInvoiceAdd"
            element={<ProjectInvoiceAdd />}
          ></Route> */}
          <Route
            path="/otherChargesInvoiceList"
            element={<OtherChargesInvoiceListVal />}
          ></Route>
          <Route
            path="/updateEmpPOSearchPage"
            element={<UpdateEmpPOSearchPage />}
          ></Route>
          {/* <Route
            path="/otherChargeInvoice"
            element={<OtherChargeInvoice />}
          ></Route> */}
          <Route
            path="/generateEmployeeInvoice2"
            element={<GenerateEmployeeInvoice2 />}
          ></Route>
          <Route
            path="/registerClientVal"
            element={<RegisterClientVal />}
          ></Route>
          <Route path="/updateClient2" element={<UpdateClient2 />}></Route>
          <Route
            path="/generateEmployeeInvoiceDate"
            element={<GenerateEmployeeInvoiceDate />}
          ></Route>

          <Route
            path="/generateEmployeeInvoiceDate"
            element={<GenerateEmployeeInvoiceDate />}
          ></Route>

          <Route
            path="/registerBankPage"
            element={<RegisterBankPage />}
          ></Route>

          <Route path="/updateBankPage" element={<UpdateBankPage />}></Route>
          <Route path="/updateEmpPONew3" element={<UpdateEmpPONew3 />}></Route>
          <Route
            path="/registerCompanyProfile"
            element={<RegisterCompanyProfilePage />}
          ></Route>
          <Route
            path="/updateCompanyProfile"
            element={<UpdateCompanyProfilePage />}
          ></Route>
          <Route
            path="/employeeSummary"
            element={<EmployeeSummaryPage />}
          ></Route>
          <Route
            path="/projectPOSummary"
            element={<ProjectPOSummaryPage />}
          ></Route>
          <Route
            path="/tableDataComponent"
            element={<TableDataComponent />}
          ></Route>

          <Route
            path="/generateQuotationPage"
            element={<GenerateQuotationPage />}
          ></Route>
          <Route
            path="/updateQuotationPage"
            element={<UpdateQuotationPage />}
          ></Route>
          <Route
            path="/generateEmployeeInvoiceTest"
            element={<GenerateEmployeeInvoiceTest />}
          ></Route>
          <Route path="/updateEmployee2" element={<UpdateEmployee2 />}></Route>
          <Route
            path="/generateProjectInvoiceNewVal"
            element={<GenerateProjectInvoiceNewVal />}
          ></Route>

          {/* <Route path="/pdf" element={<PDFGenerator/>}></Route> */}
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
