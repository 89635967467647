import React, { useState, useEffect } from "react";
import {
  TextField,
  Container,
  Paper,
  Grid,
  Card,
  CardHeader,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  IconButton,
  MenuItem,
  Typography,
  Link,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";

import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import "./EmpPo.css";
const GenerateEmpPO = () => {
  const navigate = useNavigate();

  const initialFormData = {
    poNumber: "",
    empPoUniqueNoAndGSCode: "",
    clientNameAndCode: "",
    purchaseOrderAmount: "",
    kindAttention: "",
    department: "",
    subDepartment: "",
    empPODetailsUI: [
      {
        employeeNameAndCode: "",
        poNotes: "",
        poAssignDate: "",

        empPORateUI: {
          poPerMonth: "",
          poPerHour: "",
          poPerDay: "",
        },
        empPOAssignDetailsUI: {
          poStartDate: "",
          poEndDate: "",
          poAmount: "",
        },
        empPOClientAssesmentUI: {
          jobLocation: "",
          jobPost: "",
          poDate: "",
          employeeCategory: "",
        },
        empPOAssignFacilityUI: {
          canteenAmount: "",
          transportationAmount: "",
        },
      },
    ],
  };

  const [clientNames, setClientNames] = useState([]);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeNames, setEmployeeNames] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const handleAddData = () => {
    const isDuplicateEmployee = tableData.some(
      (entry) =>
        entry.empPODetailsUI[0].employeeNameAndCode.trim() ===
        formData.empPODetailsUI[0].employeeNameAndCode.trim()
    );
    if (isDuplicateEmployee) {
      alert("Duplicate Entry! Cannot Add The Same EmployeeName.");
      return;
    }
    const empPORateUIFilled =
      formData.empPODetailsUI[0].empPORateUI.poPerMonth.trim() !== "" ||
      formData.empPODetailsUI[0].empPORateUI.poPerHour.trim() !== "" ||
      formData.empPODetailsUI[0].empPORateUI.poPerDay.trim() !== "";
    if (!empPORateUIFilled) {
      alert("Please fill at least one field in PO Rate.");
      return;
    }
    const empPODetailsUIFilled =
      formData.empPODetailsUI[0].employeeNameAndCode.trim() !== "" &&
      formData.empPODetailsUI[0].poAssignDate.trim() !== "";
    if (!empPODetailsUIFilled) {
      alert("Please fill Employee and Assign Date Before Add.");
      return;
    }
    // Add data to the table
    setTableData((prevData) => [...prevData, formData]);
    // Reset empPODetailsUI part
    setFormData((prevData) => ({
      ...prevData,
      empPODetailsUI: [initialFormData.empPODetailsUI[0]],
    }));
  };

  useEffect(() => {
    const fetchEmployeeNames = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/employee/getEmployeeNameList"
        );
        setEmployeeNames(response.data);
      } catch (error) {
        console.error("Error fetching employee names:", error);
      }
    };

    fetchEmployeeNames();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/employeePo/uniqueNoEmployeePo"
        );
        const data = response.data;

        setFormData((prevState) => ({
          ...prevState,
          poNumber: "", // Set initial value for poNumber
          empPoUniqueNoAndGSCode: data,
          clientNameAndCode: "", // Set initial value for clientName
          purchaseOrderAmount: "", // Set initial value for purchaseOrderAmount
          department: "",
          subDepartment: "",
        }));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchClientNames = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/client/getClientNameList"
        );
        setClientNames(response.data);
      } catch (error) {
        console.error("Error fetching client names:", error);
      }
    };

    fetchClientNames();
  }, []);

  const handleFormChange = (section, field, value, index) => {
    if (section === "formData") {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (section === "empPODetailsUI") {
      setFormData((prevData) => ({
        ...prevData,
        empPODetailsUI: prevData.empPODetailsUI.map((item, i) =>
          i === index
            ? {
                ...item,
                [field]: value,
              }
            : item
        ),
      }));
    }
    if (field === "employeeNameAndCode" && value) {
      setEmployeeNames((prevNames) => [...prevNames, value]);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);

    if (!isEditing) {
      setEditedData(tableData); // Assuming tableData is an array of objects
    }
  };
  const handleSaveData = async () => {
    try {
      setLoadingOverlay(true); // Set loading state to true
      setLoading(true);

      // Extract only the empPODetailsUI part from tableData
      const empPODetailsData = tableData.map(
        (entry) => entry.empPODetailsUI[0]
      );

      // Combine poInformation and empPODetailsUI to send as one payload
      if (empPODetailsData.length === 0) {
        alert("Cannot submit without adding an Employee to the table");
        return;
      }
      if (
        formData.empPoUniqueNoAndGSCode.trim() === "" ||
        formData.clientNameAndCode.trim() === "" ||
        empPODetailsData.some(
          (detail) => detail.employeeNameAndCode.trim() === ""
        )
      ) {
        alert("Please fill in all required fields.");
        return;
      }
      const postData = {
        poNumber: formData.poNumber,
        empPoUniqueNoAndGSCode: formData.empPoUniqueNoAndGSCode,
        clientNameAndCode: formData.clientNameAndCode,
        kindAttention: formData.kindAttention,
        purchaseOrderAmount: formData.purchaseOrderAmount,
        department: formData.department,
        subDepartment: formData.subDepartment,
        empPODetailsUI: empPODetailsData,
      };
      console.log("Saved Data:");
      console.log(JSON.stringify(postData, null, 2));
      const response = await myAxiosCommonHost.post(
        "gst/employeePo/createEmployeePO",
        postData
      );
      if (response.status === 200) {
        console.log("Data saved successfully!");
        alert("Employee PO added successfully!");
        // Reset form data and table data
        setFormData({ ...initialFormData });
        setTableData([]);
        // Navigate to EmpPOList3 component after successfully saving data
        navigate("/updateEmployeePO");
      } else {
        alert("Error saving data. Please try again.");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error saving data. Please try again.");
    } finally {
      setLoading(false);
      setLoadingOverlay(false); // Set loading state to false
    }
  };

  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateEmployeePO");
    }
  };
  const fetchEmpUniquePONo = async () => {
    try {
      const response = await myAxiosCommonHost.get(
        "gst/employeePo/uniqueNoEmployeePo"
      );
      const data = response.data;
      setFormData((prevState) => ({
        ...prevState,
        poNumber: "", // Set initial value for poNumber
        empPoUniqueNoAndGSCode: data,
        clientNameAndCode: "", // Set initial value for clientName
        purchaseOrderAmount: "", // Set initial value for purchaseOrderAmount
        department: "",
        subDepartment: "",
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleReset = () => {
    setFormData({ ...initialFormData });
    setTableData([]);
    setIsEditing(false);
    fetchEmpUniquePONo(); // Fetch new EmpuniquePONo after resetting the form
  };
  return (
    <Container maxWidth="lg">
      <form>
        {/* {loadingOverlay && (
          <div className="loading-overlay">
            <CircularProgress size={60} />
          </div>
        )} */}
        <Card elevation={3} style={{ marginBottom: "40px", marginTop: "20px" }}>
          <CardHeader
            title="Employee Purchase Order"
            style={{
              backgroundColor: "#2196f3",
              color: "white",
              padding: "10px",
              textAlign: "center",
            }}
          />
          <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
            <Paper
              elevation={3}
              style={{
                padding: "10px",
                marginBottom: "30px",
                backgroundColor: "darkgray",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginBottom: "15px",
                  color: "darkblue",
                  textAlign: "center",
                }}
              >
                Employee PO Details
              </Typography>
            </Paper>
            {loadingOverlay && (
              <div className="loading-overlay">
                <CircularProgress size={60} />
                <div className="loading-message">Data is saving...</div>
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="PO Number"
                  value={formData.poNumber}
                  onChange={(e) =>
                    handleFormChange("formData", "poNumber", e.target.value)
                  }
                  variant="standard"
                  style={{ margin: "0" }}
                />
              </Grid>
              <Grid item xs={4}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <div>
                    <TextField
                      label="Emp Po Unique No"
                      value={formData.empPoUniqueNoAndGSCode}
                      fullWidth
                      disabled
                      variant="standard"
                      required
                      style={{ margin: "0" }}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={clientNames}
                  getOptionLabel={(option) => option}
                  value={formData.clientNameAndCode}
                  onChange={(e, newValue) =>
                    handleFormChange("formData", "clientNameAndCode", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name And Code"
                      variant="standard"
                      fullWidth
                      margin="dense"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Purchase Order Amount"
                  value={formData.purchaseOrderAmount}
                  onChange={(e) =>
                    handleFormChange(
                      "formData",
                      "purchaseOrderAmount",
                      e.target.value
                    )
                  }
                  variant="standard"
                  style={{ margin: "0" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  value={formData.kindAttention}
                  onChange={(e) =>
                    handleFormChange(
                      "formData",
                      "kindAttention",
                      e.target.value
                    )
                  }
                  variant="standard"
                  style={{ margin: "0" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Department"
                  value={formData.department}
                  onChange={(e) =>
                    handleFormChange("formData", "department", e.target.value)
                  }
                  variant="standard"
                  style={{ margin: "0" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Sub Department"
                  value={formData.subDepartment}
                  onChange={(e) =>
                    handleFormChange(
                      "formData",
                      "subDepartment",
                      e.target.value
                    )
                  }
                  variant="standard"
                  style={{ margin: "0" }}
                />
              </Grid>
            </Grid>
          </Paper>

          {formData.empPODetailsUI.map((empPODetails, index) => (
            <Paper
              key={index}
              elevation={3}
              style={{ padding: "20px", margin: "20px" }}
            >
              <Paper
                key={index}
                elevation={3}
                style={{ padding: "20px", margin: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                    }}
                  >
                    PO Employee
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={3} md={4}>
                    <Autocomplete
                      options={employeeNames}
                      getOptionLabel={(option) => option}
                      value={empPODetails.employeeNameAndCode}
                      onChange={(e, newValue) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "employeeNameAndCode",
                          newValue,
                          index
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employee Name And Code"
                          variant="standard"
                          fullWidth
                          margin="dense"
                          required
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={3} md={4}>
                    <TextField
                      label="PO Notes "
                      value={empPODetails.poNotes}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "poNotes",
                          e.target.value,
                          index
                        )
                      }
                      variant="standard"
                      fullWidth
                      style={{ margin: "0" }}
                    />
                  </Grid>

                  <Grid item xs={3} md={4}>
                    <TextField
                      label=" PO Assign Date"
                      name="poAssignDate"
                      type="date" // Set type to 'date'
                      value={empPODetails.poAssignDate}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "poAssignDate",
                          e.target.value,
                          index
                        )
                      }
                      variant="standard"
                      fullWidth
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      style={{ margin: "0" }}
                    />
                  </Grid>
                </Grid>
              </Paper>
              {/* <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: "10px",
                        marginBottom: "20px",
                        backgroundColor: "darkgray",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          marginBottom: "15px",
                          color: "darkblue",
                        }}
                      >
                        Employee Release
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Employee Code"
                      value={empPODetails.empPOReleaseUI.employeeCode}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOReleaseUI",
                          {
                            ...empPODetails.empPOReleaseUI,
                            employeeCode: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Release Date Notes"
                      value={empPODetails.empPOReleaseUI.releaseDateNotes}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOReleaseUI",
                          {
                            ...empPODetails.empPOReleaseUI,
                            releaseDateNotes: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper> */}

              <Paper
                elevation={3}
                style={{ padding: "20px", margin: "20px", marginTop: "40px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                    }}
                  >
                    Employee PO Rate
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Per Month"
                      value={empPODetails.empPORateUI.poPerMonth}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPORateUI",
                          {
                            ...empPODetails.empPORateUI,
                            poPerMonth: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PO Per Hour"
                      value={empPODetails.empPORateUI.poPerHour}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPORateUI",
                          {
                            ...empPODetails.empPORateUI,
                            poPerHour: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PO Per Day"
                      value={empPODetails.empPORateUI.poPerDay}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPORateUI",
                          {
                            ...empPODetails.empPORateUI,
                            poPerDay: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                elevation={3}
                style={{ padding: "20px", margin: "20px", marginTop: "40px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                    }}
                  >
                    PO Assign Details
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PO Start Date"
                      type="date"
                      value={empPODetails.empPOAssignDetailsUI.poStartDate}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOAssignDetailsUI",
                          {
                            ...empPODetails.empPOAssignDetailsUI,
                            poStartDate: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                      fullWidth
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ margin: "0" }} // Adjust the margin as needed
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PO End Date"
                      type="date"
                      value={empPODetails.empPOAssignDetailsUI.poEndDate}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOAssignDetailsUI",
                          {
                            ...empPODetails.empPOAssignDetailsUI,
                            poEndDate: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                      fullWidth
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ margin: "0" }} // Adjust the margin as needed
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PO Amount"
                      value={empPODetails.empPOAssignDetailsUI.poAmount}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOAssignDetailsUI",
                          {
                            ...empPODetails.empPOAssignDetailsUI,
                            poAmount: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                      style={{ margin: "0" }} // Adjust the margin as needed
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                elevation={3}
                style={{ padding: "20px", margin: "20px", marginTop: "40px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                    }}
                  >
                    Client Assesment Details
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Job Location"
                      value={empPODetails.empPOClientAssesmentUI.jobLocation}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOClientAssesmentUI",
                          {
                            ...empPODetails.empPOClientAssesmentUI,
                            jobLocation: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Job Post"
                      value={empPODetails.empPOClientAssesmentUI.jobPost}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOClientAssesmentUI",
                          {
                            ...empPODetails.empPOClientAssesmentUI,
                            jobPost: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PO Date"
                      type="date"
                      value={empPODetails.empPOClientAssesmentUI.poDate}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOClientAssesmentUI",
                          {
                            ...empPODetails.empPOClientAssesmentUI,
                            poDate: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                      fullWidth
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Employee Category"
                      value={
                        empPODetails.empPOClientAssesmentUI.employeeCategory
                      }
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOClientAssesmentUI",
                          {
                            ...empPODetails.empPOClientAssesmentUI,
                            employeeCategory: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                  {/* <Grid item xs={2} md={4}>
                    <TextField
                      label="Department"
                      value={empPODetails.empPOClientAssesmentUI.department}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOClientAssesmentUI",
                          {
                            ...empPODetails.empPOClientAssesmentUI,
                            department: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Sub Department"
                      value={empPODetails.empPOClientAssesmentUI.subDepartment}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOClientAssesmentUI",
                          {
                            ...empPODetails.empPOClientAssesmentUI,
                            subDepartment: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid> */}
                </Grid>
              </Paper>
              <Paper
                elevation={3}
                style={{ padding: "20px", margin: "20px", marginTop: "40px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "30px",
                    backgroundColor: "darkgray",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "darkblue",
                    }}
                  >
                    PO Assign Facility
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Canteen Amount"
                      value={empPODetails.empPOAssignFacilityUI.canteenAmount}
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOAssignFacilityUI",
                          {
                            ...empPODetails.empPOAssignFacilityUI,
                            canteenAmount: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Transportation Amount"
                      value={
                        empPODetails.empPOAssignFacilityUI.transportationAmount
                      }
                      onChange={(e) =>
                        handleFormChange(
                          "empPODetailsUI",
                          "empPOAssignFacilityUI",
                          {
                            ...empPODetails.empPOAssignFacilityUI,
                            transportationAmount: e.target.value,
                          },
                          index
                        )
                      }
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Paper>
          ))}
        </Card>
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "darkcyan",
                  fontWeight: "bold",
                  color: "darkblue",
                }}
              >
                <TableCell></TableCell>
                <TableCell>Employee Name</TableCell>
                <TableCell>PO Notes</TableCell>
                <TableCell>PO Assign Date</TableCell>
                {/* <TableCell>Employee Code</TableCell> */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((rowData, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {/* {isEditing ? (
                      // Render editable fields in editing mode
                      Object.keys(rowData).map((field) => (
                        <TextField
                          key={field}
                          label={field}
                          value={editedData[field]}
                          onChange={(e) =>
                            handleEditChange(field, e.target.value)
                          }
                        />
                      ))
                    ) : (
                      <Button onClick={handleEditClick}>Add Employee PO</Button>
                    )} */}
                  </TableCell>

                  <TableCell>
                    {rowData.empPODetailsUI[0].employeeNameAndCode}
                  </TableCell>
                  <TableCell>{rowData.empPODetailsUI[0].poNotes}</TableCell>
                  <TableCell>
                    {rowData.empPODetailsUI[0].poAssignDate
                      ? rowData.empPODetailsUI[0].poAssignDate
                          .split("-")
                          .reverse()
                          .join("-")
                      : "N/A"}
                  </TableCell>
                  {/* <TableCell>
                    {rowData.empPODetailsUI[0].empPOReleaseUI.employeeCode}
                  </TableCell> */}
                  <TableCell>
                    <IconButton
                      onClick={() => handleDeleteRow(index)}
                      variant="contained"
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddData}
            style={{
              marginLeft: "20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            Add Employee
          </Button>
        </TableContainer>
        <Paper
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveData}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            disabled={tableData.length === 0}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleReset}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            style={{
              marginBottom: "20px",
              marginLeft: "20px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Cancel
          </Button>
        </Paper>
      </form>
    </Container>
  );
};

export default GenerateEmpPO;
