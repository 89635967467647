import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { saveAs } from "file-saver";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedClient, setEditedClient] = useState({ ...row });
  const [editedGstDetails, setEditedGstDetails] = useState({
    ...row.gstdetailsUI,
  });
  const [editedContractDate, setEditedContractDate] = useState({
    ...row.contractdateUI,
  });
  const [editedContactPersons, setEditedContactPersons] = useState(
    Array.isArray(row.contactpersonUI) ? [...row.contactpersonUI] : []
  );
  const [tabValue, setTabValue] = useState(0);
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.clientCode);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedClient((prevClient) => ({ ...prevClient, [name]: value }));
    setIsDataChangedEdit(true);
  };

  const handleContactPersonChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContactPersons = [...editedContactPersons];
    updatedContactPersons[index] = {
      ...updatedContactPersons[index],
      [name]: value,
    };
    setEditedContactPersons(updatedContactPersons);
    setIsDataChangedEdit(true);
  };
  const handleAddContactpersons = () => {
    setEditedContactPersons([
      ...editedContactPersons,
      {
        contactPersonName: "",
        designation: "",
        emailID: "",
        contactNumber: "",
      },
    ]);
  };
  const handleDeleteContactpersons = (index) => {
    const updatedContactPersons = [...editedContactPersons];
    updatedContactPersons.splice(index, 1);
    setEditedContactPersons(updatedContactPersons);
    setIsDataChangedEdit(true);
  };

  const handleGstDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditedGstDetails((prevGstDetails) => ({
      ...prevGstDetails,
      [name]: value,
    }));
    setIsDataChangedEdit(true);
  };

  const handleContractDateChange = (e) => {
    const { name, value } = e.target;
    setEditedContractDate((prevContractDate) => ({
      ...prevContractDate,
      [name]: value,
    }));
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    const updatedClient = {
      ...editedClient,
      gstdetailsUI: editedGstDetails,
      contactpersonUI: editedContactPersons,
      contractdateUI: editedContractDate,
    };
    onUpdate(updatedClient);
    setDialogOpen(false);
  };
  if (!editedClient) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.clientCode}</TableCell>
        <TableCell>{row.clientName}</TableCell>
        <TableCell>{row.clientType}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} variant="contained" color="primary">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Basic Information</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Client Code:</TableCell>
                  <TableCell>Client Name:</TableCell>
                  <TableCell>Client Type:</TableCell>
                  <TableCell>Client Address:</TableCell>
                  <TableCell>Client RegNumber:</TableCell>
                  <TableCell>Client Website:</TableCell>
                  <TableCell>Client ContactNumber:</TableCell>
                  <TableCell>Billing Period:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.clientCode || "N/A"}</TableCell>
                  <TableCell>{row.clientName || "N/A"}</TableCell>
                  <TableCell>{row.clientType || "N/A"}</TableCell>
                  <TableCell>{row.clientAddress || "N/A"}</TableCell>
                  <TableCell>{row.clientRegNumber || "N/A"}</TableCell>
                  <TableCell>{row.clientWebsite || "N/A"}</TableCell>
                  <TableCell> {row.clientContactNumber || "N/A"}</TableCell>
                  <TableCell>{row.billingPeriod || "N/A"}</TableCell>
                </TableRow>
                <Typography variant="h6">GST Details</Typography>
                {row.gstdetailsUI ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>State</TableCell>
                      <TableCell>State Code</TableCell>
                      <TableCell>PAN Number</TableCell>
                      <TableCell>GSTIN</TableCell>
                      <TableCell>HSN/SAC</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.gstdetailsUI.state || "N/A"}</TableCell>
                      <TableCell>
                        {row.gstdetailsUI.stateCode || "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.gstdetailsUI.panNumber || "N/A"}
                      </TableCell>
                      <TableCell>{row.gstdetailsUI.gstin || "N/A"}</TableCell>
                      <TableCell>{row.gstdetailsUI.hsnsac || "N/A"}</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No GST Details Found
                    </TableCell>
                  </TableRow>
                )}
                <Typography variant="h6">Contract Date</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>MSA</TableCell>
                  <TableCell>NDA</TableCell>
                </TableRow>
                {row.contractdateUI ? (
                  <TableRow>
                    <TableCell>
                      {row.contractdateUI.startDate
                        ? row.contractdateUI.startDate
                            .split("-")
                            .reverse()
                            .join("-")
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {row.contractdateUI.endDate
                        ? row.contractdateUI.endDate
                            .split("-")
                            .reverse()
                            .join("-")
                        : "N/A"}
                    </TableCell>
                    <TableCell>{row.contractdateUI.msa || "N/A"}</TableCell>
                    <TableCell>{row.contractdateUI.nda || "N/A"}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Contract Date Found
                    </TableCell>
                  </TableRow>
                )}
                {/* <Typography variant="h6">Contact Persons</Typography>
                {row.contactpersonUI.map((contactPerson, index) => (
                  <div key={index}>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell> Contact Person {index + 1}: </TableCell>
                      <TableCell> Designation{index + 1}:</TableCell>
                      <TableCell>Email ID{index + 1}</TableCell>
                      <TableCell> Contact Number{index + 1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {contactPerson.contactPersonName || "N/A"}
                      </TableCell>
                      <TableCell>
                        {contactPerson.designation || "N/A"}
                      </TableCell>
                      <TableCell>{contactPerson.emailID || "N/A"}</TableCell>
                      <TableCell>
                        {contactPerson.contactNumber || "N/A"}
                      </TableCell>
                    </TableRow>
                  </div>
                ))} */}
                <Typography variant="h6">Contact Persons</Typography>
                {row.contactpersonUI ? (
                  row.contactpersonUI.map((contactPerson, index) => (
                    <div key={index}>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell> Contact Person {index + 1}: </TableCell>
                        <TableCell> Designation{index + 1}:</TableCell>
                        <TableCell>Email ID{index + 1}</TableCell>
                        <TableCell> Contact Number{index + 1}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {contactPerson?.contactPersonName || "N/A"}
                        </TableCell>
                        <TableCell>
                          {contactPerson?.designation || "N/A"}
                        </TableCell>
                        <TableCell>{contactPerson?.emailID || "N/A"}</TableCell>
                        <TableCell>
                          {contactPerson?.contactNumber || "N/A"}
                        </TableCell>
                      </TableRow>
                    </div>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No contact persons found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Edit Client Information</DialogTitle>
        <DialogContent>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Basic Information" />
            <Tab label="GST Details" />
            <Tab label="Contract Date" />
            <Tab label="Contact Persons" />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Code"
                      name="clientCode"
                      value={editedClient.clientCode || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Name"
                      name="clientName"
                      value={editedClient.clientName || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <Autocomplete
                      options={[
                        "Automobile",
                        "IT",
                        "Manufacturing",
                        "Marketing",
                      ]}
                      value={editedClient.clientType || null}
                      onChange={(event, newValue) =>
                        handleFieldChange({
                          target: { name: "clientType", value: newValue },
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Type"
                          fullWidth
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Address"
                      name="clientAddress"
                      value={editedClient.clientAddress || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Registration Number"
                      name="clientRegNumber"
                      value={editedClient.clientRegNumber || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Website"
                      name="clientWebsite"
                      value={editedClient.clientWebsite || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Contact Number"
                      name="clientContactNumber"
                      value={editedClient.clientContactNumber || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Billing Period"
                      name="billingPeriod"
                      value={editedClient.billingPeriod || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 1 && (
            <>
              <Typography variant="h6">GST Details</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="State"
                      name="state"
                      value={editedGstDetails.state || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="State Code"
                      name="stateCode"
                      value={editedGstDetails.stateCode || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="PAN Number"
                      name={"panNumber"}
                      value={editedGstDetails.panNumber || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="GSTIN"
                      name={"gstin"}
                      value={editedGstDetails.gstin || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="HSN/SAC"
                      name={"hsnsac"}
                      value={editedGstDetails.hsnsac || "N/A"}
                      onChange={handleGstDetailsChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 2 && (
            <>
              <Typography variant="h6">Contract Date</Typography>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Start Date"
                      name="startDate"
                      type="date"
                      value={editedContractDate.startDate}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={2} md={4}>
                    <TextField
                      label="End Date"
                      name="endDate"
                      type="date"
                      value={editedContractDate.endDate}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="MSA"
                      name="msa"
                      value={editedContractDate.msa || "N/A"}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xl={4}>
                    <TextField
                      label="NDA"
                      name="nda"
                      value={editedContractDate.nda || "N/A"}
                      onChange={handleContractDateChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {tabValue === 3 && (
            <>
              <Typography variant="h6">Contact Persons</Typography>
              {editedContactPersons.map((contactPerson, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
                >
                  <Grid container spacing={4} style={{ marginTop: 10 }}>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Contact Person Name ${index + 1}`}
                        name={"contactPersonName"}
                        value={contactPerson.contactPersonName}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Designation ${index + 1}`}
                        name="designation"
                        value={contactPerson.designation}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>

                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Email ID ${index + 1}`}
                        name="emailID"
                        value={contactPerson.emailID}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Contact Number ${index + 1}`}
                        name={"contactNumber"}
                        value={contactPerson.contactNumber}
                        onChange={(e) => handleContactPersonChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                      <Paper
                        elevation={1}
                        style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleDeleteContactpersons(index)}
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          Delete
                        </Button>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              <Paper
                elevation={2}
                style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddContactpersons}
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  Add Contact Persons
                </Button>
              </Paper>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} disabled={!isDataChangedEdit}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const UpdateClient = () => {
  const [clientData, setClientData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTermClientCode, setSearchTermClientCode] = useState("");
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchClientData = () => {
      setLoading(true); // Set loading to true when fetching data
      myAxiosCommonHost
        .get("gst/client/getClientList")
        .then((response) => {
          setClientData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching client data:", error);
          setError(error); // Set the error state
          setLoading(false);
        });
    };

    fetchClientData();
  }, []);

  const handleUpdateClient = (updatedClient) => {
    setIsUpdating(true); // Show update overlay
    myAxiosCommonHost
      .put("gst/client/updateClient", updatedClient)
      .then((response) => {
        console.log("Client data updated successfully.");
        fetchClientData();
        setIsUpdating(false); // Hide update overlay after update
      })
      .catch((error) => {
        console.error("Error updating Client data: ", error);
        setIsUpdating(false); // Hide update overlay on error
      });
  };
  const handleDeleteClient = (clientCode) => {
    // Delete the client with the given clientCode
    if (window.confirm("Are you sure you want to delete this Client?")) {
      setIsDeleting(true); // Show deletion overlay
      const encodedclientCode = encodeURIComponent(clientCode);
      myAxiosCommonHost
        .delete(
          `gst/client/deleteClient/clientCode?clientCode=${encodedclientCode}`
        )
        .then((response) => {
          console.log("Client deleted successfully.");
          fetchClientData();
          setIsDeleting(false); // Hide deletion overlay after deletion
        })
        .catch((error) => {
          console.error("Error deleting client: ", error);
          setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };

  const fetchClientData = () => {
    setLoading(true); // Set loading to true when fetching data
    myAxiosCommonHost
      .get("gst/client/getClientList")
      .then((response) => {
        setClientData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Client data:", error);
        setLoading(false);
      });
  };

  const exportToExcel = () => {
    const dataToExport = clientData.map((client) => ({
      "Client Code": client.clientCode,
      "Client Name": client.clientName,
      "Client Type": client.clientType,
      "Client Address": {
        v: client.clientAddress,
        t: "s",
        s: {
          alignment: { wrapText: true, vertical: "top", horizontal: "left" },
        },
      }, // Left align and wrap text
      "Client RegNumber": {
        v: client.clientRegNumber,
        t: "s",
        s: { alignment: { horizontal: "left" } },
      }, // Left align client reg number
      "Client Website": client.clientWebsite,
      "Client ContactNumber": {
        v: client.clientContactNumber,
        t: "s",
        s: { alignment: { horizontal: "left" } },
      }, // Left align client contact number
      "Billing Period": client.billingPeriod,
      "GST State": {
        v: client.gstdetailsUI.state,
        t: "s",
        s: { alignment: { horizontal: "left" } },
      }, // Left align GST state
      "State Code": {
        v: client.gstdetailsUI.stateCode,
        t: "s",
        s: { alignment: { horizontal: "left" } },
      }, // Left align state code
      "PAN Number": client.gstdetailsUI.panNumber,
      GSTIN: client.gstdetailsUI.gstin,
      "HSN/SAC": client.gstdetailsUI.hsnsac,
      "Start Date": client.contractdateUI.startDate,
      "End Date": client.contractdateUI.endDate,
      MSA: client.contractdateUI.msa,
      NDA: client.contractdateUI.nda,
      //'Contact Persons': client.contactpersonUI ? client.contactpersonUI.map(cp => `${cp.contactPersonName}, ${cp.designation}, ${cp.emailID}, ${cp.contactNumber}`).join(';') : 'N/A'
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

    // Adjust column width for better visibility
    const wscols = [
      { wch: 15 }, // Client Code
      { wch: 30 }, // Client Name
      { wch: 15 }, // Client Type
      { wch: 95 }, // Client Address
      { wch: 15 }, // Client RegNumber
      { wch: 20 }, // Client Website
      { wch: 20 }, // Client ContactNumber
      { wch: 15 }, // Billing Period
      { wch: 15 }, // GST State
      { wch: 15 }, // State Code
      { wch: 15 }, // PAN Number
      { wch: 25 }, // GSTIN
      { wch: 15 }, // HSN/SAC
      { wch: 15 }, // Start Date
      { wch: 15 }, // End Date
      { wch: 15 }, // MSA
      { wch: 15 }, // NDA
    ];

    worksheet["!cols"] = wscols;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_data.xlsx"
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredClient = clientData.filter(
    (client) =>
      client.clientName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      client.clientCode
        .toLowerCase()
        .includes(searchTermClientCode.toLowerCase())
  );

  return (
    <div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Client is Deleting...</Typography>
          </div>
        </div>
      )}
      {isUpdating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Client is Updating...</Typography>
          </div>
        </div>
      )}
      <TextField
        label="Search by Client Name"
        variant="standard"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <TextField
        label="Search by Client Code"
        variant="standard"
        value={searchTermClientCode}
        onChange={(e) => setSearchTermClientCode(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Link to="/registerClient">
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
          >
            Register Client
          </Button>
        </Link>
      </Paper>
      <Button
        variant="contained"
        color="secondary"
        onClick={exportToExcel}
        style={{ marginLeft: "10px" }}
      >
        Export to Excel
      </Button>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Client List
          </Typography>
        </div>
      </Paper>

      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {/* {loading ? (
            <p>Loading...</p>
          ) : ( */}
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Client Code</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Client Type</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No clients found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredClient
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((client) => (
                        <Row
                          key={client.clientCode}
                          row={client}
                          onDelete={handleDeleteClient}
                          onUpdate={handleUpdateClient}
                        />
                      ))
                  )}
                </TableBody>
              </Table>
              {/* <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={clientData.length}
                rowsPerPage={2}
                page={0}
                onPageChange={(event, newPage) => {}}
              /> */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={filteredClient.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage} // Make sure this line is correct
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateClient;
