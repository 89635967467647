import React, { useEffect, useState } from "react";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { CardContent } from "@material-ui/core";
import {
  Card,
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  TablePagination,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";

const EmployeeSummary = () => {
  const [emplyoeeNameAndCode, setEmplyoeeNameAndCode] = useState("");
  const [emplyoeeNameOptions, setEmplyoeeNameOptions] = useState("");
  const [employeeSummary, setEmployeeSummary] = useState("");
  const [isSummaryGet, setIsSummaryGet] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  useEffect(() => {
    myAxiosCommonHost
      .get("gst/employee/getEmployeeNameList")
      .then((response) => {
        setEmplyoeeNameOptions(response.data);
      })
      .catch((error) => {
        console.error("Error Fetching EmployeeName:", error);
      });
  }, []);
  useEffect(() => {
    setIsSummaryGet(true);
    // Fetch Employee Summary  based on selected emplyoeeNameAndCode
    
    if (emplyoeeNameAndCode) {
      const encodedEmplyoeeNameAndCode = encodeURIComponent(emplyoeeNameAndCode);
      myAxiosCommonHost
        .get(
          `gst/employee/getEmployeeHistoryList?emplyoeeNameAndCode=${encodedEmplyoeeNameAndCode}`
        )
        .then((response) => {
          setEmployeeSummary(response.data);
        })
        .catch((error) => {
          console.error("Error fetching collection history:", error);
        });
      setIsSummaryGet(false);
    }
  }, [emplyoeeNameAndCode]);
  const handleEmployeeNameAndCodeChange = (event) => {
    setEmplyoeeNameAndCode(event.target.value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <Grid>
        <Grid>
          <Card>
            <CardContent>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2196f3",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Employee Summary
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={emplyoeeNameOptions}
                      getOptionLabel={(option) => option}
                      value={emplyoeeNameAndCode}
                      onChange={(event, newValue) =>
                        setEmplyoeeNameAndCode(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Emplyoee Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {employeeSummary.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Employee Code</TableCell>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Joining Date</TableCell>
                    <TableCell>CTC</TableCell>
                    <TableCell>Monthly</TableCell>
                    <TableCell>Position Change</TableCell>
                    <TableCell>Seperation Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeSummary
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.employeeCode}</TableCell>
                        <TableCell>{row.employeeName}</TableCell>
                        <TableCell>
                          {row.joiningDate
                            ? row.joiningDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.ctc}</TableCell>
                        <TableCell>{row.monthly}</TableCell>
                        <TableCell>{row.positionChange}</TableCell>
                        <TableCell>
                          {row.seperationDate
                            ? row.seperationDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 4, 16]}
              component="div"
              count={employeeSummary.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
    </div>
  );
};

export default EmployeeSummary;
