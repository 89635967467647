import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CircularProgress } from "@material-ui/core";
import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";

const ERPLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleLogin = () => {
    console.log("Username: ", username);
    console.log("Password: ", password);
    const encodedUsername = encodeURIComponent(username);
    // encodedPassWord = encodeURIComponent(password);
    setIsLoggedIn(true);
    myAxiosCommonHost
      .get("/gst/login/loginUser", {
        params: {
          userName: encodedUsername,
          passWord: password,
        },
      })
      .then((response) => {
        if (response.data === true) {
          console.log("Login successful");
          alert("Login successfully");
          navigate("/navBar");
        } else {
          console.log("Login failed");
          alert("Login failed");
        }
      })
      .catch((error) => {
        console.error("Error logging in:", error);
        alert("Error logging in");
      });
    setIsLoggedIn(false);
  };
  return (
    <Container>
      {isLoggedIn && (
        <div className="loading-overlay">
          <CircularProgress size={60} />
          <div className="loading-message">Login...</div>
        </div>
      )}
      <Paper
        elevation={3}
        style={{
          padding: 20,
          maxWidth: 400,
          margin: "auto",
          marginTop: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center items horizontally
          justifyContent: "center", // Center items vertically
        }}
      >
        <h2>Login</h2>
        <form>
          <TextField
            label="Username"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            style={{ marginTop: 10 }} // Adjusted the marginTop value
          >
            Log In
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ERPLogin;
