import React, { useState, useEffect } from "react";
import numberToWords from "number-to-words";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Button,
  Card,
  CardHeader,
  Container,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Logo from "../Invoice/Logo1.jpg";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { Autocomplete } from "@mui/material";
import "./Invoice.css";

const GenerateEmployeeInvoice = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const getCurrentMonth = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 to get the previous month
    const previousMonthIndex = currentDate.getMonth();
    return months[previousMonthIndex];
  };
  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const fiscalStartYear = currentYear - (currentDate.getMonth() >= 3 ? 0 : 1);
    const fiscalEndYear = fiscalStartYear + 1;
    return `${(fiscalStartYear % 100).toString().padStart(2, "0")}-${(
      fiscalEndYear % 100
    )
      .toString()
      .padStart(2, "0")}`;
  };
  const navigate = useNavigate();
  const [invoiceHeaderDetails, setInvoiceHeaderDetails] = useState({
    selectCompany: "GST",
    financialYear: getCurrentFinancialYear(),
    invoiceNo: "",
    invoiceRefNo: "",
    invoiceDate: format(new Date(), "yyyy-MM") + "-01", // Default to the first day of the current month
    issueDate: format(new Date(), "yyyy-MM-dd"), // Default to today's date
    invoiceType: "",
    clientType: "AutoMobile",
    paymentYear: getCurrentYear(),
    paymentMonth: getCurrentMonth(),
    clientNameAndCode: "",
    sacHsn: "",
    uniqueNoPoNo: "",
    buyersPoNumber: "",
    poNumber: "",
    workingDays: "",
    invoiceTitle: "",
    invoiceRemarks: "",
    empRateUnit: "",
    annexureFlag: "",
  });

  const [invoiceCalculation, setInvoiceCalculation] = useState({
    totalPresentyAmount: 0,
    cgst: 0,
    igst: 0,
    sgst: 0,
    grandTotalAmount: 0,
    totalTaxAmt: 0,
    deductionAmount: 0,
    deductionParticular: "",
    totalInWord: "",
    igstAmount: "",
    cgstAmount: "",
    sgstAmount: "",
  });

  const [paymentMode, setPaymentMode] = useState({
    ModeTermsOfPayment: "15 Days Bank Transfer",
    KindAttention: "",
    SelectBankAccNo: "",
  });
  const [isCalculateClicked, setIsCalculateClicked] = useState(false);
  const [financialYears, setFinancialYears] = useState([]);
  const [paymentYears, setPaymentYears] = useState([]);
  const [paymentMonths, setPaymentMonths] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [poNumbers, setPoNumbers] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [bankAccNoAndIfscCodes, setBankAccNoAndIfscCodes] = useState([]);
  const [bankUIData, setBankUIData] = useState(null);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [workingDays, setWorkingDays] = useState(0);
  const [isPdfButtonDisabled, setIsPdfButtonDisabled] = useState(true);
  const [invoiceRemarksOptions, setInvoiceRemarksOptions] = useState([]);
  const [isSubmitButtonDisabledForPdf, setIsSubmitButtonDisabledForPdf] =
    useState(false);
  const [invoiceEmployeePoDetailsUIList, setInvoiceEmployeePoDetailsUIList] =
    useState({
      invoiceEmployeePoDetailsUIList: [
        {
          empRateUnit: "",
          presenty: "",
          presentyAmt: "",
          canteenAmt: "",
          transportationAmt: "",
          fromDate: "",
          toDate: "",
          employeeCode: "",
          emplyoyeeName: "",
          currentPoRate: "",
          fixmonthlyCanteen: "",
          isCanteenAssign: "",
          isTransportationAssign: "",
          activeStatus: "",
          releaseDate: "",
          currentEmplyoyee: "",
          currentPoNo: "",
          currentPoDate: "",
          annenuxFlag: "",
          uniqueNoPoNo: "",
        },
      ],
    });
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to track submit button disable/enable status
  useEffect(() => {
    const fetchClientList = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/employeePo/getClientListEmpPurchaseOrder"
        );
        setClientList(response.data);
      } catch (error) {
        console.error("Error fetching client list:", error);
      }
    };

    fetchClientList();
  }, []);
  useEffect(() => {
    const fetchFinancialYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYears(response.data);
      } catch (error) {
        console.error("Error fetching financialYears:", error);
      }
    };

    fetchFinancialYears();
  }, []);

  useEffect(() => {
    const fetchPaymentYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getPaymentYear"
        );
        setPaymentYears(response.data);
      } catch (error) {
        console.error("Error fetching paymentYears:", error);
      }
    };

    fetchPaymentYears();
  }, []);

  useEffect(() => {
    const fetchPaymentMonths = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getShortMonth"
        );
        setPaymentMonths(response.data);
      } catch (error) {
        console.error("Error fetching paymentMonths:", error);
      }
    };

    fetchPaymentMonths();
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/invoice/getInvoiceUniqueNo"
  //       );

  //       if (response.status === 200) {
  //         const data = response.data;
  //         setInvoiceHeaderDetails((prevState) => ({
  //           ...prevState,
  //           selectCompany: "GST",
  //           financialYear: getCurrentFinancialYear(),
  //           invoiceNo: data,
  //           invoiceRefNo: "",
  //           issueDate: format(new Date(), "yyyy-MM-dd"), // Update the default value for issueDate
  //           invoiceDate: format(new Date(), "yyyy-MM") + "-01", // Update the default value for invoiceDate
  //           invoiceType: "",
  //           clientType: "AutoMobile",
  //           paymentYear: getCurrentYear(),
  //           paymentMonth: getCurrentMonth(),
  //           clientNameAndCode: "",
  //           sacHsn: "",
  //           poNumber: "",
  //           uniqueNoPoNo: "",
  //           buyersPoNumber: "",
  //           workingDays: "",
  //           invoiceTitle: "",
  //invoiceRemarks: "",
  //         }));
  //         setInvoiceCalculation({
  //           totalPresentyAmount: 0,
  //           cgst: 0,
  //           igst: 0,
  //           sgst: 0,
  //           grandTotalAmount: 0,
  //           totalTaxAmt: 0,
  //           deductionAmount: 0,
  //           deductionParticular: "",
  //           totalInWord: "",
  //           igstAmount: "",
  //           cgstAmount: "",
  //           sgstAmount: "",
  //         });

  //         setPaymentMode({
  //           ModeTermsOfPayment: "15 Days Bank Transfer",
  //           KindAttention: "",
  //           SelectBankAccNo: "",
  //         });

  //         setInvoiceEmployeePoDetailsUIList({
  //           invoiceEmployeePoDetailsUIList: [
  //             {
  //               empRateUnit: "",
  //               presenty: "",
  //               presentyAmt: "",
  //               canteenAmt: "",
  //               transportationAmt: "",
  //               fromDate: "",
  //               toDate: "",
  //               employeeCode: "",
  //               emplyoyeeName: "",
  //               currentPoRate: "",
  //               fixmonthlyCanteen: "",
  //               isCanteenAssign: "",
  //               isTransportationAssign: "",
  //               activeStatus: "",
  //               releaseDate: "",
  //               currentEmplyoyee: "",
  //               currentPoNo: "",
  //               currentPoDate: "",
  //               annenuxFlag: "",
  //               uniqueNoPoNo: "",
  //             },
  //           ],
  //         });
  //         setWorkingDays(0);
  //       } else {
  //         console.error("Error fetching data. Status:", response.status);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const fetchPoNumbers = async (clientNameAndCode) => {
    const encodedclientNameAndCode = encodeURIComponent(clientNameAndCode);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getPoNumberAndUniqueNumberEmpPurchaseOrderList?clientNameAndCode=${encodedclientNameAndCode}`
      );
      setPoNumbers(response.data);
    } catch (error) {
      console.error("Error fetching poNumbers:", error);
    }
  };

  const fetchBankAccNoAndIfscCodes = async (
    uniqueNoPoNo,
    paymentYear,
    paymentMonth
  ) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
      );
      const data = response.data.bankAccNoAndIfscCode || [];
      setBankAccNoAndIfscCodes(data);
      return data;
    } catch (error) {
      console.error("Error fetching bankAccNoAndIfscCodes:", error);
      return [];
    }
  };

  const generateInvoiceRefNo = () => {
    const { selectCompany, financialYear, invoiceNo } = invoiceHeaderDetails;
    return `${selectCompany}${financialYear}${invoiceNo}`;
  };

  const invoiceRefNo = generateInvoiceRefNo();
  useEffect(() => {
    if (
      invoiceHeaderDetails.selectCompany &&
      invoiceHeaderDetails.financialYear &&
      invoiceHeaderDetails.invoiceNo
    ) {
      const newInvoiceRefNo = generateInvoiceRefNo();
      setInvoiceHeaderDetails((prevDetails) => ({
        ...prevDetails,
        invoiceRefNo: newInvoiceRefNo,
      }));
    }
  }, [
    invoiceHeaderDetails.selectCompany,
    invoiceHeaderDetails.financialYear,
    invoiceHeaderDetails.invoiceNo,
  ]);

  const handleWorkingDaysChange = (event) => {
    const value = event.target.value;
    const updatedWorkingDays = parseFloat(value);
    setWorkingDays(updatedWorkingDays);
  };

  const handleInvoiceEmployeeDetailsChange = (index, field) => (event) => {
    console.log("handleInvoiceEmployeeDetailsChange called");

    const value = event.target.value;

    setInvoiceEmployeePoDetailsUIList((prevDetails) => {
      const updatedList = [...prevDetails.invoiceEmployeePoDetailsUIList];
      const currentItem = { ...updatedList[index], [field]: value };

      // Check if both presenty and currentPoRate are valid numbers before calculating presentyAmt
      if (!isNaN(currentItem.presenty) && !isNaN(currentItem.currentPoRate)) {
        if (
          currentItem.empRateUnit === "Per Day" ||
          currentItem.empRateUnit === "Per Hour"
        ) {
          currentItem.presentyAmt = (
            parseFloat(currentItem.presenty) *
            parseFloat(currentItem.currentPoRate)
          ).toFixed(2);
        } else if (currentItem.empRateUnit === "Per Month") {
          currentItem.presentyAmt = (
            (parseFloat(currentItem.currentPoRate) / parseFloat(workingDays)) *
            parseFloat(currentItem.presenty)
          ).toFixed(2);
        } else {
          currentItem.presentyAmt = "";
        }
      } else {
        currentItem.presentyAmt = ""; // Handle the case where either presenty or currentPoRate is not a valid number
      }

      console.log("currentItem:", currentItem);

      updatedList[index] = currentItem;

      return {
        ...prevDetails,
        invoiceEmployeePoDetailsUIList: updatedList,
      };
    });
  };

  const fetchProjectPoData = async (
    uniqueNoPoNo,
    paymentYear,
    paymentMonth
  ) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
      );
      if (Array.isArray(response)) {
        setPaymentMode((prevPaymentMode) => ({
          ...prevPaymentMode,
          SelectBankAccNo: null, // Set to null initially
        }));
        setBankAccNoAndIfscCodes(response);
      } else {
        console.error("Invalid response from server:", response);
      }
      const kindAttention = response.data.kindAttention || "";
      setPaymentMode((prevPaymentMode) => ({
        ...prevPaymentMode,
        KindAttention: kindAttention,
      }));
      const empRateUnit = response.data.empRateUnit || "";
      setInvoiceHeaderDetails((prevInvoiceHeaderDetails) => ({
        ...prevInvoiceHeaderDetails,
        empRateUnit: empRateUnit,
        poNumber: response.data.empPoRateInvoiceUIList[0]?.currentPoNo || "",
      }));

      return response.data;
    } catch (error) {
      console.error("Error fetching project data:", error);
      return null;
    }
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleInvoiceHeaderDetailsChange = (field) => async (event) => {
    const value = event.target.value;
    let projectData;
    let paymentYear;
    let paymentMonth;

    const updateInvoiceHeaderDetails = (newDetails) => {
      setInvoiceHeaderDetails((prevDetails) => ({
        ...prevDetails,
        ...newDetails,
      }));
    };
    if (field === "invoiceTitle") {
      setInvoiceHeaderDetails((prevDetails) => ({
        ...prevDetails,
        invoiceRemarks: "", // Reset invoiceRemarks
      }));
      // Set options for invoiceRemarks based on invoiceTitle value
      if (value === "PROFORMA INVOICE") {
        setInvoiceRemarksOptions([
          "QUOTATION UNDER PROCESS",
          "PR UNDER PROCESS",
          "PO UNDER PROCESS",
        ]);
      } else if (value === "TAX INVOICE") {
        setInvoiceRemarksOptions([
          "Invoice in submission process",
          "Invoice with GSQUARE Representative",
          "Invoice with compliance",
          "Invoice with user",
          "Invoice with account/GRN",
        ]);
      } else {
        // Clear invoiceRemarksOptions if invoiceTitle is neither "PROFORMA INVOICE" nor "TAX INVOICE"
        setInvoiceRemarksOptions([]);
      }
    }
    // Update invoiceHeaderDetails with the selected value
    setInvoiceHeaderDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    if (field === "clientNameAndCode") {
      updateInvoiceHeaderDetails({
        clientNameAndCode: value,
        uniqueNoPoNo: "",
      });
      fetchPoNumbers(value);
    } else {
      updateInvoiceHeaderDetails({
        [field]: value,
      });
    }

    if (field === "uniqueNoPoNo") {
      setIsFetchingData(true);
      try {
        paymentYear = invoiceHeaderDetails.paymentYear;
        paymentMonth = invoiceHeaderDetails.paymentMonth;

        projectData = await fetchProjectPoData(
          value,
          paymentYear,
          paymentMonth
        );

        if (projectData && Array.isArray(projectData.empPoRateInvoiceUIList)) {
          const fromDate = new Date(
            paymentYear,
            months.indexOf(paymentMonth),
            1
          );
          const lastDayOfMonth = new Date(
            paymentYear,
            months.indexOf(paymentMonth) + 1,
            0
          );
          const toDate = format(lastDayOfMonth, "yyyy-MM-dd");

          console.log("Selected paymentYear:", paymentYear);
          console.log("Selected paymentMonth:", paymentMonth);
          console.log("Calculated fromDate:", format(fromDate, "yyyy-MM-dd"));
          console.log("Calculated toDate:", toDate);

          setInvoiceEmployeePoDetailsUIList((prevDetails) => ({
            ...prevDetails,
            invoiceEmployeePoDetailsUIList:
              projectData.empPoRateInvoiceUIList.map((project) => ({
                empRateUnit: project.empRateUnit,
                canteenAmt: project.canteenAmount,
                transportationAmt: project.transportAmount,
                fromDate: format(fromDate, "yyyy-MM-dd"), // Update fromDate with calculated value
                toDate: toDate, // Update toDate with calculated value
                employeeCode: project.employeeCode,
                emplyoyeeName: project.employeeName,
                currentPoRate: project.currentPoRate,
                fixmonthlyCanteen: project.fixmonthlyCanteen,
                isCanteenAssign: project.isCanteenAssigned,
                isTransportationAssign: project.isTransportationAssigned,
                activeStatus: project.activeStatus,
                releaseDate: project.employeeReleaseDate,
                currentEmplyoyee: project.employeeName,
                currentPoDate: project.currentPoDate,
                annenuxFlag: project.annenuxFlag,
                presenty: project.presenty,
                presentyAmt: project.presentyAmt,
                currentPoNo: project.currentPoNo,
              })),
          }));
          setProjectDetails(projectData);
          const { cgst, sgst, igst } = projectData.gstdetailsUI;
          setInvoiceCalculation((prevData) => ({
            ...prevData,
            cgst,
            sgst,
            igst,
          }));
          try {
            const gstdetailsResponse = await myAxiosCommonHost.get(
              `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${value}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
            );
            updateInvoiceHeaderDetails({
              sacHsn: gstdetailsResponse.data.gstdetailsUI.hsnsac || "",
            });
          } catch (error) {
            console.error("Error fetching SAC/HSN data:", error);
          }
        } else {
          console.error("Invalid response from server:", projectData);
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }

      // try {
      //   const response = await fetchBankAccNoAndIfscCodes(
      //     value,
      //     paymentYear,
      //     paymentMonth
      //   );

      //   if (Array.isArray(response)) {
      //     setPaymentMode((prevPaymentMode) => ({
      //       ...prevPaymentMode,
      //       SelectBankAccNo: response,
      //     }));
      //   } else {
      //     console.error("Invalid response from server:", response);
      //   }
      // } catch (error) {
      //   console.error("Error fetching bankAccNoAndIfscCodes:", error);
      // }
      try {
        const response = await fetchBankAccNoAndIfscCodes(
          value,
          paymentYear,
          paymentMonth
        );

        if (Array.isArray(response)) {
          setPaymentMode((prevPaymentMode) => ({
            ...prevPaymentMode,
            SelectBankAccNo: null, // Set to null initially
          }));
          setBankAccNoAndIfscCodes(response);
        } else {
          console.error("Invalid response from server:", response);
        }
      } catch (error) {
        console.error("Error fetching bankAccNoAndIfscCodes:", error);
      } finally {
        setIsFetchingData(false); // Set flag to indicate data fetching is completed
      }

      const newInvoiceRefNo = generateInvoiceRefNo();
      updateInvoiceHeaderDetails({
        invoiceRefNo: newInvoiceRefNo,
        poNumber: projectData.empPoRateInvoiceUIList[0]?.currentPoNo || "",
      });
    }
  };

  const handleInvoiceCalculationChange = (field, value) => {
    setInvoiceCalculation((prevData) => ({
      ...prevData,
      [field]: parseFloat(value) || 0,
    }));
  };

  const handlePaymentModeChange = (field) => (event) => {
    setPaymentMode({
      ...paymentMode,
      [field]: event.target.value,
    });
  };
  useEffect(() => {
    const {
      uniqueNoPoNo,
      clientNameAndCode,
      invoiceRefNo,
      financialYear,
      // workingDays,
    } = invoiceHeaderDetails;

    const { grandTotalAmount, totalPresentyAmount, totalTaxAmt } =
      invoiceCalculation;

    const { SelectBankAccNo } = paymentMode;

    // Check if any required field is null or empty
    const isDisabled =
      !uniqueNoPoNo ||
      !clientNameAndCode ||
      // !workingDays ||
      !invoiceRefNo ||
      !financialYear ||
      !SelectBankAccNo ||
      grandTotalAmount === 0 ||
      totalPresentyAmount === 0 ||
      totalTaxAmt === 0;

    // Update the state to enable/disable the submit button
    setIsSubmitDisabled(isDisabled);
  }, [
    invoiceHeaderDetails.uniqueNoPoNo,
    invoiceHeaderDetails.clientNameAndCode,
    // invoiceHeaderDetails.workingDays,
    invoiceHeaderDetails.invoiceRefNo,
    invoiceHeaderDetails.financialYear,
    invoiceCalculation.grandTotalAmount,
    invoiceCalculation.totalPresentyAmount,
    invoiceCalculation.totalTaxAmt,
    paymentMode.SelectBankAccNo,
  ]);
  const isPaymentModeValid = () => {
    const { SelectBankAccNo } = paymentMode;
    console.log("Payment Mode:", JSON.stringify(paymentMode, null, 2)); // Log paymentMode in JSON format
    return SelectBankAccNo !== null;
  };

  const isSubmitButtonDisabled = () => {
    const {
      uniqueNoPoNo,
      clientNameAndCode,
      // workingDays,
      invoiceRefNo,
      financialYear, // Add invoiceRefNo to the destructuring assignment
    } = invoiceHeaderDetails;

    const { grandTotalAmount, totalPresentyAmount, totalTaxAmt } =
      invoiceCalculation;
    // console.log("Unique No:", JSON.stringify(uniqueNoPoNo, null, 2)); // Log uniqueNoPoNo in JSON format
    // console.log(
    //   "Client Name and Code:",
    //   JSON.stringify(clientNameAndCode, null, 2)
    // ); // Log clientNameAndCode in JSON format
    // console.log("Working Days:", workingDays); // Log workingDays directly, assuming it's a primitive type
    // console.log("Invoice Ref No:", invoiceRefNo); // Log invoiceRefNo directly
    // console.log("Grand Total Amount:", grandTotalAmount); // Log grandTotalAmount directly
    // console.log("Total Presenty Amount:", totalPresentyAmount); // Log totalPresentyAmount directly
    // console.log("Total Tax Amount:", totalTaxAmt); // Log totalTaxAmt directly
    // console.log("Is Payment Mode Valid:", isPaymentModeValid()); // Log the result of isPaymentModeValid()

    // Check conditions for disabling the Submit button
    if (
      !uniqueNoPoNo ||
      !clientNameAndCode ||
      // !workingDays ||
      !isPaymentModeValid() ||
      grandTotalAmount === undefined ||
      totalPresentyAmount === undefined ||
      totalTaxAmt === undefined ||
      !invoiceRefNo ||
      !financialYear
    ) {
      return true; // Disable the button
    }

    return false; // Enable the button
  };

  const handleSubmit = async () => {
    setLoadingOverlay(true);
    const projectDetailsListData = {
      selectCompany: invoiceHeaderDetails.selectCompany,
      financialYear: invoiceHeaderDetails.financialYear,
      invoiceNo: invoiceHeaderDetails.invoiceNo,
      invoiceRefNo: invoiceHeaderDetails.invoiceRefNo,
      invoiceDate: invoiceHeaderDetails.invoiceDate,
      issueDate: invoiceHeaderDetails.issueDate,
      invoiceType: invoiceHeaderDetails.invoiceType,
      clientType: invoiceHeaderDetails.clientType,
      paymentYear: invoiceHeaderDetails.paymentYear,
      paymentMonth: invoiceHeaderDetails.paymentMonth,
      clientNameAndCode: invoiceHeaderDetails.clientNameAndCode,
      sacHsn: invoiceHeaderDetails.sacHsn,
      poNumber: invoiceHeaderDetails.poNumber,
      buyersPoNumber: invoiceHeaderDetails.buyersPoNumber,
      uniqueNoPoNo: invoiceHeaderDetails.uniqueNoPoNo,
      workingDays: invoiceHeaderDetails.workingDays,
      invoiceTitle: invoiceHeaderDetails.invoiceTitle,
      invoiceRemarks: invoiceHeaderDetails.invoiceRemarks,
      empRateUnit: invoiceHeaderDetails.empRateUnit,
      annexureFlag: invoiceHeaderDetails.annexureFlag,
      invoiceEmployeePoDetailsUIList:
        invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
          (item) => ({
            empRateUnit: item.empRateUnit,
            canteenAmt: item.canteenAmt,
            transportationAmt: item.transportationAmt,
            fromDate: item.fromDate,
            toDate: item.toDate,
            employeeCode: item.employeeCode,
            emplyoyeeName: item.emplyoyeeName,
            currentPoRate: item.currentPoRate,
            fixmonthlyCanteen: item.fixmonthlyCanteen,
            isCanteenAssign: item.isCanteenAssign,
            isTransportationAssign: item.isTransportationAssign,
            activeStatus: item.activeStatus,
            releaseDate: item.releaseDate,
            currentEmplyoyee: item.employeeNameAndCode,
            currentPoDate: item.currentPoDate,
            annenuxFlag: item.annenuxFlag,
            presenty: item.presenty,
            presentyAmt: item.presentyAmt,
          })
        ),
      invoiceGstDetailsUI: {
        totalPresentyAmount: invoiceCalculation.totalPresentyAmount,
        cGST: invoiceCalculation.cgst,
        sGST: invoiceCalculation.sgst,
        iGST: invoiceCalculation.igst,
        grandTotalAmount: invoiceCalculation.grandTotalAmount,
        totalTaxAmt: invoiceCalculation.totalTaxAmt,
        deductionAmount: invoiceCalculation.deductionAmount,
        deductionParticular: invoiceCalculation.deductionParticular,
        totalInWord: invoiceCalculation.totalInWord,
        igstAmount: invoiceCalculation.igstAmount,
        cgstAmount: invoiceCalculation.cgstAmount,
        sgstAmount: invoiceCalculation.sgstAmount,
      },
      invoicePaymentModeUI: {
        modeTermsOfPayment: paymentMode.ModeTermsOfPayment,
        kindAttention: paymentMode.KindAttention,
        selectBankAccNo: paymentMode.SelectBankAccNo,
      },
      bankUI: bankUIData,
    };
    console.log(JSON.stringify(projectDetailsListData, null, 2));
    try {
      const response = await myAxiosCommonHost.post(
        "gst/invoice/createInvoice?filterType=EMPLOYEEPOINVOICE",
        projectDetailsListData
      );

      console.log("Server response:", response.data);
      window.alert("Invoice Saved Successfully!");
      // Disable the submit button and enable the PDF button
      setIsSubmitButtonDisabledForPdf(true);
      // Enable the PDF button after successful data submission
      setIsPdfButtonDisabled(false);
      //navigate("/employeeInvoiceList");
    } catch (error) {
      console.error("Error submitting data:", error);
      window.alert("Error saving data on the server. Please try again.");
    } finally {
      setLoadingOverlay(false);
    }
  };
  const fetchInvoiceNo = async () => {
    try {
      const response = await myAxiosCommonHost.get(
        "gst/invoice/getInvoiceUniqueNo"
      );

      if (response.status === 200) {
        const data = response.data;
        setInvoiceHeaderDetails((prevState) => ({
          ...prevState,
          invoiceNo: data,
        }));
      } else {
        console.error("Error fetching data. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchInvoiceNo();
  }, []);
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/employeeInvoiceList");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getInvoiceUniqueNo"
        );

        if (response.status === 200) {
          const data = response.data;
          setInvoiceHeaderDetails((prevState) => ({
            ...prevState,
            invoiceNo: data,
            //invoiceRefNo: "",
            issueDate: format(new Date(), "yyyy-MM-dd"),
            invoiceDate: format(new Date(), "yyyy-MM") + "-01",
            // invoiceType: "",
            clientType: "AutoMobile",
            // clientNameAndCode: "",
            sacHsn: "",
            poNumber: "",
            buyersPoNumber: "",
            workingDays: "",
            // invoiceTitle: "",
            // invoiceRemarks: "",
            empRateUnit: "",
          }));
          setInvoiceCalculation({
            totalPresentyAmount: 0,
            cgst: 0,
            igst: 0,
            sgst: 0,
            grandTotalAmount: 0,
            totalTaxAmt: 0,
            deductionAmount: 0,
            deductionParticular: "",
            totalInWord: "",
            igstAmount: "",
            cgstAmount: "",
            sgstAmount: "",
          });
          setPaymentMode({
            ModeTermsOfPayment: "15 Days Bank Transfer",
            KindAttention: "",
            SelectBankAccNo: "",
          });
          setInvoiceEmployeePoDetailsUIList({
            invoiceEmployeePoDetailsUIList: [
              {
                empRateUnit: "",
                presenty: "",
                presentyAmt: "",
                canteenAmt: "",
                transportationAmt: "",
                fromDate: "",
                toDate: "",
                employeeCode: "",
                emplyoyeeName: "",
                currentPoRate: "",
                fixmonthlyCanteen: "",
                isCanteenAssign: "",
                isTransportationAssign: "",
                activeStatus: "",
                releaseDate: "",
                currentEmplyoyee: "",
                currentPoNo: "",
                currentPoDate: "",
                annenuxFlag: "",
                uniqueNoPoNo: "",
              },
            ],
          });
          setWorkingDays(0);
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    invoiceHeaderDetails.paymentYear,
    invoiceHeaderDetails.paymentMonth,
    invoiceHeaderDetails.uniqueNoPoNo,
  ]);

  const handleReset = () => {
    // Reset all the form fields to their initial/default values
    setInvoiceHeaderDetails({
      selectCompany: "GST",
      financialYear: getCurrentFinancialYear(),
      invoiceNo: "",
      invoiceRefNo: "",
      invoiceDate: format(new Date(), "yyyy-MM") + "-01", // Default to the first day of the current month
      invoiceType: "",
      clientType: "AutoMobile",
      paymentYear: getCurrentYear(),
      paymentMonth: getCurrentMonth(),
      clientNameAndCode: "",
      sacHsn: "",
      uniqueNoPoNo: "",
      buyersPoNumber: "",
      poNumber: "",
      issueDate: format(new Date(), "yyyy-MM-dd"), // Default to today's date
      workingDays: "",
      invoiceTitle: "",
      invoiceRemarks: "",
      empRateUnit: "",
    });
    fetchInvoiceNo();

    setInvoiceCalculation({
      totalPresentyAmount: 0,
      cgst: 0,
      igst: 0,
      sgst: 0,
      grandTotalAmount: 0,
      totalTaxAmt: 0,
      deductionAmount: 0,
      deductionParticular: "",
      totalInWord: "",
      igstAmount: "",
      cgstAmount: "",
      sgstAmount: "",
    });

    setPaymentMode({
      ModeTermsOfPayment: "15 Days Bank Transfer",
      KindAttention: "",
      SelectBankAccNo: "",
    });

    setInvoiceEmployeePoDetailsUIList({
      invoiceEmployeePoDetailsUIList: [
        {
          empRateUnit: "",
          presenty: "",
          presentyAmt: "",
          canteenAmt: "",
          transportationAmt: "",
          fromDate: "",
          toDate: "",
          employeeCode: "",
          emplyoyeeName: "",
          currentPoRate: "",
          fixmonthlyCanteen: "",
          isCanteenAssign: "",
          isTransportationAssign: "",
          activeStatus: "",
          releaseDate: "",
          currentEmplyoyee: "",
          currentPoNo: "",
          currentPoDate: "",
          annenuxFlag: "",
          uniqueNoPoNo: "",
        },
      ],
    });
    setWorkingDays(0);
    isSubmitButtonDisabled();
    setIsPdfButtonDisabled(true);
  };
  async function fetchCompanyData(uniqueNoPoNo, paymentYear, paymentMonth) {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/employeePo/getEmployeePoInvoiceUIList?empPoNoAndUniqueCode=${uniqueNoPoNo}&paymentYear=${paymentYear}&paymentMonth=${paymentMonth}`
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.data;

      // Extract the required fields from companyProfileUI
      const { companyProfileUI, clientUI, gstdetailsUI } = data;

      // Extract the required fields from companyProfileUI
      const {
        companyName,
        companyAddress,
        website,
        eMail,
        companyWork,
        contactNo,
        gstin,
        pan,
        cin,
        iec,
      } = companyProfileUI;

      // Extract the required fields from clientUI
      const {
        clientType,
        clientCode,
        clientNameAndCode,
        clientAddress,
        clientRegNumber,
        clientWebsite,
        clientContactNumber,
        billingPeriod,
      } = clientUI;

      // Extract the required fields from gstdetailsUI
      const {
        state,
        stateCode,
        panNumber,
        gstin: gstDetailsGstin,
        hsnsac,
        igst,
        sgst,
        cgst,
      } = gstdetailsUI;

      // You can use the extracted data as needed
      console.log("Company Name:", companyName);
      console.log("Company Address:", companyAddress);
      console.log("Website:", website);
      console.log("Email:", eMail);
      console.log("Company Work:", companyWork);
      console.log("Contact No:", contactNo);
      console.log("GSTIN:", gstin);
      console.log("PAN:", pan);
      console.log("CIN:", cin);
      console.log("IEC:", iec);
      console.log("State:", state);
      console.log("State Code:", stateCode);
      console.log("PAN Number:", panNumber);
      console.log("GSTIN (from gstdetailsUI):", gstDetailsGstin);
      console.log("HSN/SAC:", hsnsac);
      console.log("IGST:", igst);
      console.log("SGST:", sgst);
      console.log("CGST:", cgst);

      // Return the extracted data
      return {
        companyName,
        companyAddress,
        website,
        eMail,
        companyWork,
        contactNo,
        gstin,
        pan,
        cin,
        iec,
        state,
        stateCode,
        panNumber,
        gstDetailsGstin,
        hsnsac,
        igst,
        sgst,
        cgst,
        clientType,
        clientCode,
        clientNameAndCode,
        clientAddress,
        clientRegNumber,
        clientWebsite,
        clientContactNumber,
        billingPeriod,
      };
    } catch (error) {
      console.error("Error fetching data:", error.message);
      throw error;
    }
  }

  // Function to handle calculation
  const handleCalculate = async () => {
    try {
        const { cgst, igst, sgst } = invoiceCalculation;

        // Update the state with the received values
        setInvoiceCalculation((prevData) => ({
            ...prevData,
            cgst,
            sgst,
            igst,
        }));

        // Calculate totalPresentyAmount
        const updatedTotalPresentyAmount =
            invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.reduce(
                (total, item) => total + parseFloat(item.presentyAmt) || 0,
                0
            );
        const roundedTotalPresentyAmount = parseFloat(
            updatedTotalPresentyAmount.toFixed(2)
        );

        // Calculate total tax amount
        const {
            cgst: updatedCgst,
            sgst: updatedSgst,
            igst: updatedIgst,
        } = invoiceCalculation;

        const cgstAmount = (updatedCgst / 100) * updatedTotalPresentyAmount;
        const sgstAmount = (updatedSgst / 100) * updatedTotalPresentyAmount;
        const igstAmount = (updatedIgst / 100) * updatedTotalPresentyAmount;
        const calculatedTotalTaxAmt = cgstAmount + sgstAmount + igstAmount;

        // Update the state with the recalculated totalTaxAmt
        setInvoiceCalculation((prevData) => ({
            ...prevData,
            totalTaxAmt: calculatedTotalTaxAmt,
            igstAmount: igstAmount,
            cgstAmount: cgstAmount,
            sgstAmount: sgstAmount,
        }));

        // Calculate deduction amount
        let deductionAmount = 0;

        if (invoiceHeaderDetails.annexureFlag === true) {
            deductionAmount =
                invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.reduce(
                    (total, item) =>
                        total +
                        (parseFloat(item.canteenAmt) || 0) +
                        (parseFloat(item.transportationAmt) || 0),
                    0
                );
        }

        // Calculate grandTotal
        const grandTotalAmount = parseFloat(
            updatedTotalPresentyAmount + calculatedTotalTaxAmt - deductionAmount
        ).toFixed(2);

        // Convert grandTotal to words
        const totalInWord = numberToWords.toWords(grandTotalAmount);

        // Update the state with the recalculated values
        setInvoiceCalculation((prevData) => ({
            ...prevData,
            totalPresentyAmount: roundedTotalPresentyAmount,
            deductionAmount,
            grandTotalAmount,
            totalInWord,
        }));

        console.log("Grand Total Calculated:", grandTotalAmount);
        console.log("Total In Words:", totalInWord);
        // You can also do other calculations or updates here as needed
    } catch (error) {
        // Handle errors appropriately
        console.error("Error calculating grand total:", error.message);
    }
    setIsCalculateClicked(true);
};


  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [swiftCode, setSwiftCode] = useState("");

  useEffect(() => {
    const fetchBankUIData = async () => {
      const encodeSelectBankAccNo = encodeURIComponent(
        paymentMode.SelectBankAccNo
      );
      try {
        const response = await myAxiosCommonHost.get(
          `gst/bank/getInvoiceBankUI?bankAccNoAndIfscCode=${encodeSelectBankAccNo}`
        );
        setBankUIData(response.data);
        // Now you can set these values in your state or do whatever you need with them
        const { bankName, bankAddress, accountNumber, ifsc, swiftCode } =
          response.data;
        setBankName(bankName);
        setBankAddress(bankAddress);
        setAccountNumber(accountNumber);
        setIfsc(ifsc);
        setSwiftCode(swiftCode);
      } catch (error) {
        console.error("Error fetching bankUI data:", error);
      }
    };

    if (paymentMode.SelectBankAccNo) {
      fetchBankUIData();
    }
  }, [paymentMode.SelectBankAccNo]);

  const handleDeleteRow = (index) => {
    // Create a copy of the array and remove the element at the specified index
    const updatedList = [
      ...invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList,
    ];
    updatedList.splice(index, 1);

    // Update the state with the new array
    setInvoiceEmployeePoDetailsUIList({
      invoiceEmployeePoDetailsUIList: updatedList,
    });
  };

  const [checkEmpRateUnit, setCheckEmpRateUnit] = useState();

  const handlegeneratePDF = () => {
    invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.forEach(
      (invoiceEmployeePoDetailsUI) => {
        setCheckEmpRateUnit(invoiceEmployeePoDetailsUI.empRateUnit);
      }
    );

    if (checkEmpRateUnit === "Per Day") {
      generatePDFPerDay(
        invoiceHeaderDetails,
        invoiceCalculation
        //empPoRateInvoiceUIList,
        // other parameters
      );
    } else if (checkEmpRateUnit === "Per Month") {
      generatePDFPerMonth(
        invoiceHeaderDetails,
        invoiceCalculation
        //empPoRateInvoiceUIList,
        // other parameters
      );
    } else if (checkEmpRateUnit === "Per Hour") {
      generatePDFPerHour(
        invoiceHeaderDetails,
        invoiceCalculation
        // empPoRateInvoiceUIList,
        // other parameters
      );
    } else {
      console.log("Invalid empRateUnit value. Unable to generate PDF");
    }
  };

  const generatePDFPerMonth = async (
    invoiceHeaderDetails,
    invoiceCalculation,
    empPoRateInvoiceUIList
    // other parameters
  ) => {
    const doc = new jsPDF();

    // Add a new rectangle to encompass all tables and containers
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText = ""; // Declare companyInfoText outside the try block

    try {
      const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
      const paymentYear = invoiceHeaderDetails.paymentYear;
      const paymentMonth = invoiceHeaderDetails.paymentMonth;
      const companyData = await fetchCompanyData(
        uniqueNoPoNo,
        paymentYear,
        paymentMonth
      );

      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyData.companyName}\n` +
        `${companyData.companyAddress}\n` +
        `${companyData.website}\n` +
        `${companyData.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
      // Handle errors if any
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });

    const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
    const paymentYear = invoiceHeaderDetails.paymentYear;
    const paymentMonth = invoiceHeaderDetails.paymentMonth;
    const companyData = await fetchCompanyData(
      uniqueNoPoNo,
      paymentYear,
      paymentMonth
    );

    const CompInvo = [
      ["Invoice No: " + invoiceHeaderDetails.invoiceNo],
      ["Company's GST NO.: " + companyData.gstin], // Include GSTIN directly
      ["Company's PAN No.: " + companyData.pan], // Include PAN directly
      ["Company's CIN: " + companyData.cin], // Include CIN directly
      ["Invoice Date: " + invoiceHeaderDetails.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
      // columnStyles: {
      //   0: { columnWidth: tableWidth }, // Set column width to accommodate both title and content
      // },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code: " + companyData.hsnsac],
      ["Client's GSTIN: " + companyData.gstDetailsGstin],
      ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
      ["Billing Period: " + companyData.billingPeriod],
      ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
    ];

    const clientInvoTableHeight = 40;

    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    // Assuming you have already fetched the data using fetchCompanyData function
    //const companyData = await fetchCompanyData(uniqueNoPoNo);

    const firstTableHeight1 = doc.previousAutoTable.finalY;
    const billedToContainerHeight = 38.85; // or the appropriate value you intend to use

    // Extract clientAddress from the fetched data
    const clientAddress = companyData.clientAddress;

    // Billed To container immediately after companyInfo container
    const billedToText = `Billed To: ${clientAddress}`;

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
      maxWidth: billedToContainerWidth - 10,
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row) => [
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate,
          row.toDate,
          invoiceHeaderDetails.workingDays,
          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );

    doc.autoTable({
      head: [
        [
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",
          "Working Days",
          "Billable Days",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        // Set fixed width for each column
        0: { columnWidth: 30 }, // Particulars
        1: { columnWidth: 25 }, // PO NO.
        2: { columnWidth: 20 }, // From Date
        3: { columnWidth: 20 }, // To Date
        4: { columnWidth: 17 }, // Working Days
        5: { columnWidth: 15 }, // Billable Days
        6: { columnWidth: 20 }, // Current PO Rate
        7: { columnWidth: 35 }, // Amount (Last column with a larger size)
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst } = companyData;

    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
      ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
      ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        0: { columnWidth: 147 }, // Set label column width to 40
        1: { columnWidth: 35, halign: "right" }, // Set value column width to 40
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }

    const bankData = [
      [`Bank Name: ${bankName}`],
      [`Bank Address: ${bankAddress}`],
      [`A/C No.: ${accountNumber}`],
      [`IFC Code: ${ifsc}`],
      [`Swift Code: ${swiftCode}`],
    ];

    const tableWidth2 = doc.internal.pageSize.width * 0.4;

    // Set startY for the bank table immediately after the invoice data table
    doc.autoTable({
      body: bankData,
      startY: secondTableHeight + 0, // Start immediately after the previous table with some padding
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
    });

    // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
    const rectWidth = 97;
    const rectHeight = 45.5;

    // Set rectX1 to start from the right edge of the bank table
    const rectX1 = doc.internal.pageSize.width * 0.47;

    const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

    doc.setDrawColor(0); // Set border color to black
    doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

    const line1Text = "For Gsquare Techsystem PVT LTD";
    const line2Text = "Authorized Signatory & Seal";
    const textX = rectX1 + 25;
    const textY1 = rectY1 + 8;
    const textY2 = rectY1 + 30;
    doc.text(line1Text, textX - 5, textY1);
    doc.text(line2Text, textX, textY2);

    doc.save("Employee_Invoice_data_perMonth.pdf");
  };

  const generatePDFPerHour = async (
    invoiceHeaderDetails,
    invoiceCalculation,
    empPoRateInvoiceUIList,
    invoicePaymentModeUI,
    paymentMode
    // workingDayCalculationUI
  ) => {
    const doc = new jsPDF();

    // Add a new rectangle to encompass all tables and containers
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText = ""; // Declare companyInfoText outside the try block

    try {
      const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
      const paymentYear = invoiceHeaderDetails.paymentYear;
      const paymentMonth = invoiceHeaderDetails.paymentMonth;
      const companyData = await fetchCompanyData(
        uniqueNoPoNo,
        paymentYear,
        paymentMonth
      );

      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyData.companyName}\n` +
        `${companyData.companyAddress}\n` +
        `${companyData.website}\n` +
        `${companyData.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
      // Handle errors if any
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    // doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });

    const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
    const paymentYear = invoiceHeaderDetails.paymentYear;
    const paymentMonth = invoiceHeaderDetails.paymentMonth;
    const companyData = await fetchCompanyData(
      uniqueNoPoNo,
      paymentYear,
      paymentMonth
    );

    const CompInvo = [
      ["Invoice No", invoiceHeaderDetails.invoiceNo],
      ["Company's GST NO.", companyData.gstin], // Include GSTIN directly
      ["Company's PAN No.", companyData.pan], // Include PAN directly
      ["Company's CIN", companyData.cin], // Include CIN directly
      ["Invoice Date", invoiceHeaderDetails.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code", companyData.hsnsac],
      ["Client's GSTIN", companyData.gstDetailsGstin],
      ["Payment Term", "15Days Bank Transfer"],

      ["Billing Period", companyData.billingPeriod],
      [{ content: "Onsite Manpower Support for the month", colSpan: 2 }],
    ];

    const clientInvoTableHeight = 40;

    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    // Assuming you have already fetched the data using fetchCompanyData function
    //const companyData = await fetchCompanyData(uniqueNoPoNo);

    const firstTableHeight1 = doc.previousAutoTable.finalY;
    const billedToContainerHeight = 38.85; // or the appropriate value you intend to use

    // Extract clientAddress from the fetched data
    const clientAddress = companyData.clientAddress;

    // Billed To container immediately after companyInfo container
    const billedToText = `Billed To: ${clientAddress}`;

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
      maxWidth: billedToContainerWidth - 10,
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row) => [
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate,
          row.toDate,
          // Add more fields based on your data structure

          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );

    doc.autoTable({
      head: [
        [
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",

          "Billable Hours",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst } = companyData;
    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
      ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
      ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      ["H] Total In Words", totalInWord],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: {
        1: { cellWidth: "auto", halign: "right" }, // Adjust the width of the 2nd column in InvoiceData
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }

    const bankData = [
      [`Bank Name: ${bankName}`],
      [`Bank Address: ${bankAddress}`],
      [`A/C No.: ${accountNumber}`],
      [`IFC Code: ${ifsc}`],
      [`Swift Code: ${swiftCode}`],
    ];

    const tableWidth2 = doc.internal.pageSize.width * 0.4;

    // Set startY for the bank table immediately after the invoice data table
    doc.autoTable({
      body: bankData,
      startY: secondTableHeight + 0, // Start immediately after the previous table with some padding
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
    });

    // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
    const rectWidth = 97;
    const rectHeight = 45.5;

    // Set rectX1 to start from the right edge of the bank table
    const rectX1 = doc.internal.pageSize.width * 0.47;

    const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

    doc.setDrawColor(0); // Set border color to black
    doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

    const line1Text = "For Gsquare Techsystem PVT LTD";
    const line2Text = "Authorized Signatory & Seal";
    const textX = rectX1 + 25;
    const textY1 = rectY1 + 8;
    const textY2 = rectY1 + 30;
    doc.text(line1Text, textX - 5, textY1);
    doc.text(line2Text, textX, textY2);

    doc.save("Employee_Invoice_data_perHour.pdf");
  };

  const generatePDFPerDay = async (
    invoiceHeaderDetails,
    invoiceCalculation,
    empPoRateInvoiceUIList,
    invoicePaymentModeUI,
    paymentMode
    // workingDayCalculationUI
  ) => {
    const doc = new jsPDF();

    // Add a new rectangle to encompass all tables and containers
    const encompassingRectX = 3;
    const encompassingRectY = 4;
    const encompassingRectWidth = doc.internal.pageSize.width - 6;
    const encompassingRectHeight = doc.internal.pageSize.height - 8;

    doc.setDrawColor(0); // Set border color to black
    doc.setLineWidth(0.4); // Set border width
    doc.rect(
      encompassingRectX,
      encompassingRectY,
      encompassingRectWidth,
      encompassingRectHeight
    );

    let companyInfoText = ""; // Declare companyInfoText outside the try block

    try {
      const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
      const paymentYear = invoiceHeaderDetails.paymentYear;
      const paymentMonth = invoiceHeaderDetails.paymentMonth;
      const companyData = await fetchCompanyData(
        uniqueNoPoNo,
        paymentYear,
        paymentMonth
      );

      // Information for GSQUARE TECHSYSTEMS PVT LTD
      companyInfoText =
        `${companyData.companyName}\n` +
        `${companyData.companyAddress}\n` +
        `${companyData.website}\n` +
        `${companyData.eMail}`;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      // Add the company information to the PDF
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...
    } catch (error) {
      console.error("Error fetching company data:", error);
      // Handle errors if any
    }

    const companyInfoContainerX = 14;
    const companyInfoContainerY = 10;
    const companyInfoContainerWidth =
      doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
    const companyInfoContainerHeight = 40;

    // Draw the container first
    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      companyInfoContainerX,
      companyInfoContainerY,
      companyInfoContainerWidth,
      companyInfoContainerHeight,
      "FD"
    );

    // Add the logo with additional margin from the left border
    // doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

    // Position the company information text with reduced margin after the logo
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(companyInfoText, 25 + 20 + 2, 18, {
      // Change margin after logo width (e.g., 2 or 3)
      maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
    });

    const uniqueNoPoNo = invoiceHeaderDetails.uniqueNoPoNo;
    const paymentYear = invoiceHeaderDetails.paymentYear;
    const paymentMonth = invoiceHeaderDetails.paymentMonth;
    const companyData = await fetchCompanyData(
      uniqueNoPoNo,
      paymentYear,
      paymentMonth
    );

    const CompInvo = [
      ["Invoice No", invoiceHeaderDetails.invoiceNo],
      ["Company's GST NO.", companyData.gstin], // Include GSTIN directly
      ["Company's PAN No.", companyData.pan], // Include PAN directly
      ["Company's CIN", companyData.cin], // Include CIN directly
      ["Invoice Date", invoiceHeaderDetails.invoiceDate],
    ];

    const tableWidth = doc.internal.pageSize.width * 0.45;
    const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

    // Calculate the height of the CompInvo table
    const compInvoTableHeight = doc.autoTable({
      body: CompInvo,
      theme: "grid",
      margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 2,
        marginBottom: 0,
        marginTop: 0,
        padding: 0,
      },
    }).lastAutoTable.finalY;

    const ClientInvo = [
      ["Service ACC Code", companyData.hsnsac],
      ["Client's GSTIN", companyData.gstDetailsGstin],
      ["Payment Term", "15Days Bank Transfer"],

      ["Billing Period", companyData.billingPeriod],
      [{ content: "Onsite Manpower Support for the month", colSpan: 2 }],
    ];

    const clientInvoTableHeight = 40;

    // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
    doc.autoTable({
      body: ClientInvo,
      theme: "grid",
      columnStyles: { 0: { cellWidth: "auto" } },
      startY: compInvoTableHeight, // Set startY explicitly
      margin: {
        top: compInvoTableHeight,
        left: leftMargin,
        right: 14,
        bottom: 0,
      },
      styles: {
        fontSize: 10,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
        tableHeight: 40,
      },
    });

    // Assuming you have already fetched the data using fetchCompanyData function
    //const companyData = await fetchCompanyData(uniqueNoPoNo);

    const firstTableHeight1 = doc.previousAutoTable.finalY;
    const billedToContainerHeight = 38.85; // or the appropriate value you intend to use

    // Extract clientAddress from the fetched data
    const clientAddress = companyData.clientAddress;

    // Billed To container immediately after companyInfo container
    const billedToText = `Billed To: ${clientAddress}`;

    const billedToContainerX = companyInfoContainerX;
    const topMargin = 0;
    const containerHeight = 40; // Set the common height for both the billedToContainer and the table
    const billedToContainerY =
      companyInfoContainerY + companyInfoContainerHeight + topMargin;
    const billedToContainerWidth = companyInfoContainerWidth;

    doc.setDrawColor(0);
    doc.setLineWidth(0.4);
    doc.setFillColor(255, 255, 255);
    doc.rect(
      billedToContainerX,
      billedToContainerY,
      billedToContainerWidth,
      containerHeight,
      "FD"
    );

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
      maxWidth: billedToContainerWidth - 10,
    });
    doc.setFillColor(255, 255, 255);

    const tableData =
      invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
        (row) => [
          row.emplyoyeeName,
          row.currentPoNo,
          row.fromDate,
          row.toDate,
          // Add more fields based on your data structure

          row.presenty,
          row.currentPoRate,
          row.presentyAmt,
        ]
      );

    doc.autoTable({
      head: [
        [
          "Particulars",
          "PO NO.",
          "From Date",
          "To date",

          "Billable Days",
          "Current PO Rate",
          "Amount",
        ],
      ],
      body: tableData,
      startY: billedToContainerY + billedToContainerHeight + 0,
      theme: "grid",
      styles: {
        fontSize: 9,
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
    });
    // Calculate the height of the first table (invoice data table)
    const firstTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    const { totalPresentyAmount, grandTotalAmount, totalTaxAmt, totalInWord } =
      invoiceCalculation;
    const { cgst, sgst, igst } = companyData;
    const InvoiceData = [
      ["A] Total", totalPresentyAmount],
      ["B] CGST (1)", ` ${(cgst / 100) * totalPresentyAmount}`],
      ["C] SGST (2)", ` ${(sgst / 100) * totalPresentyAmount}`],
      ["D] IGST (3)", ` ${(igst / 100) * totalPresentyAmount}`],
      ["E] Total (A+B+C+D)", grandTotalAmount],
      ["F] Deduction ", 0],
      ["G] Grand Total (F-G)", grandTotalAmount],
      ["H] Total In Words", totalInWord],
    ];

    // Set startY for the second table immediately after the first table
    doc.autoTable({
      body: InvoiceData,
      startY: firstTableHeight + 0, // You can adjust the spacing as needed
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },

      columnStyles: {
        1: { halign: "right" }, // Adjust the width of the 2nd column in InvoiceData
      },
    });

    // Calculate the height of the second table (invoice data table)
    const secondTableHeight = doc.previousAutoTable
      ? doc.previousAutoTable.finalY
      : 0;

    // Calculate remaining space on the page
    const remainingSpace =
      doc.internal.pageSize.getHeight() - secondTableHeight - 20;

    // Check if there is enough space for the bank table
    if (remainingSpace < 0) {
      doc.addPage();
    }

    const bankData = [
      [`Bank Name: ${bankName}`],
      [`Bank Address: ${bankAddress}`],
      [`A/C No.: ${accountNumber}`],
      [`IFC Code: ${ifsc}`],
      [`Swift Code: ${swiftCode}`],
    ];

    const tableWidth2 = doc.internal.pageSize.width * 0.4;

    // Set startY for the bank table immediately after the invoice data table
    doc.autoTable({
      body: bankData,
      startY: secondTableHeight + 0, // Start immediately after the previous table with some padding
      theme: "grid",
      styles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        marginBottom: 0,
      },
      columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
    });

    // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
    const rectWidth = 97;
    const rectHeight = 45.5;

    // Set rectX1 to start from the right edge of the bank table
    const rectX1 = doc.internal.pageSize.width * 0.47;

    const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

    doc.setDrawColor(0); // Set border color to black
    doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

    const line1Text = "For Gsquare Techsystem PVT LTD";
    const line2Text = "Authorized Signatory & Seal";
    const textX = rectX1 + 25;
    const textY1 = rectY1 + 8;
    const textY2 = rectY1 + 30;
    doc.text(line1Text, textX - 5, textY1);
    doc.text(line2Text, textX, textY2);

    doc.save("Employee_Invoice_data_perDay.pdf");
  };

  return (
    <Container maxWidth="xl" onSubmit={handleSubmit}>
      <Card elevation={3} style={{ margin: "40px auto" }}>
        <CardHeader
          title="Employee Invoice Header Details"
          style={{
            backgroundColor: "#2196f3",
            color: "white",
            padding: "10px",
            textAlign: "center",
          }}
        />
        <Paper elevation={3} style={{ padding: "10px", marginBottom: "10px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Invoice Header Details
              </Typography>
            </Paper>
            {loadingOverlay && (
              <div className="loading-overlay">
                <CircularProgress size={60} />
                <div className="loading-message">Creating Invoice...</div>
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Autocomplete
                  options={[{ label: "GSquare", value: "GST" }]}
                  value={invoiceHeaderDetails.selectCompany}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("selectCompany")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Company"
                      margin="normal"
                      variant="standard"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={financialYears}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.financialYear}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("financialYear")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Financial Year"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice No"
                  fullWidth
                  value={invoiceHeaderDetails.invoiceNo}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceNo")}
                  variant="standard"
                  margin="normal"
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice RefNo"
                  name="invoiceRefNo"
                  value={invoiceHeaderDetails.invoiceRefNo}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceRefNo")}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Issue Date"
                  name="issueDate"
                  type="date"
                  value={invoiceHeaderDetails.issueDate}
                  onChange={handleInvoiceHeaderDetailsChange("issueDate")}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Invoice Date"
                  name="invoiceDate"
                  type="date"
                  value={invoiceHeaderDetails.invoiceDate}
                  onChange={handleInvoiceHeaderDetailsChange("invoiceDate")}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={paymentYears}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.paymentYear}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("paymentYear")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service Of Year"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={paymentMonths}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.paymentMonth}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("paymentMonth")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service Of Month"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={["AutoMobile", "Mechanical"]}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.clientType}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("clientType")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Type"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={clientList}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.clientNameAndCode}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("clientNameAndCode")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name And Code"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={["Debit Note", "Credit Note", "Tax Invoice"]}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.invoiceType}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("invoiceType")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Type"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="SAC/HSN"
                  fullWidth
                  value={invoiceHeaderDetails.sacHsn}
                  onChange={handleInvoiceHeaderDetailsChange("sacHsn")}
                  variant="standard"
                  disabled
                  margin="normal"
                />
              </Grid>

              <Grid item xs={3}>
                <Autocomplete
                  options={["PROFORMA INVOICE", "TAX INVOICE"]}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.invoiceTitle}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("invoiceTitle")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Title"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  value={invoiceHeaderDetails.invoiceRemarks}
                  onChange={(event, newValue) => {
                    setInvoiceHeaderDetails((prevDetails) => ({
                      ...prevDetails,
                      invoiceRemarks: newValue,
                    }));
                  }}
                  options={invoiceRemarksOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Remarks"
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Emp Rate Unit"
                  fullWidth
                  value={invoiceHeaderDetails.empRateUnit}
                  onChange={handleInvoiceHeaderDetailsChange("empRateUnit")}
                  variant="standard"
                  margin="normal"
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={["Yes", "No"]}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.annexureFlag ? "Yes" : "No"} // Set the initial value based on the boolean value
                  onChange={(event, newValue) => {
                    const newValueBoolean = newValue === "Yes"; // Convert "Yes" to true and "No" to false
                    handleInvoiceHeaderDetailsChange("annexureFlag")({
                      target: { value: newValueBoolean }, // Set the boolean value in the target object
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Annexure Flag"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Paper>

        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Details
              </Typography>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  options={poNumbers}
                  getOptionLabel={(option) => option}
                  value={invoiceHeaderDetails.uniqueNoPoNo}
                  onChange={(event, newValue) => {
                    handleInvoiceHeaderDetailsChange("uniqueNoPoNo")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unique Po Number"
                      margin="dense"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="PO Number"
                  fullWidth
                  value={invoiceHeaderDetails.poNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                  margin="dense"
                  required
                />
              </Grid>
              {/* {invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList
                .length > 0 && (
                <Grid item xs={4}>
                  <TextField
                    label="Emp Rate Unit"
                    fullWidth
                    value={
                      invoiceEmployeePoDetailsUIList
                        .invoiceEmployeePoDetailsUIList[0].empRateUnit || ""
                    }
                    onChange={handleInvoiceHeaderDetailsChange(
                      0,
                      "empRateUnit"
                    )}
                    variant="standard"
                  />
                </Grid>
              )} */}
              <Grid item xs={4}>
                <TextField
                  label="Working Days"
                  fullWidth
                  value={invoiceHeaderDetails.workingDays}
                  onChange={(event) => {
                    handleWorkingDaysChange(event);
                    handleInvoiceHeaderDetailsChange("workingDays")(event);
                  }}
                  variant="standard"
                  margin="dense"
                />
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Employee Calculation Details
              </Typography>
            </Paper>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell style={{ width: "30px" }}>Sr. No.</TableCell>
                    <TableCell style={{ width: "30px" }}>Action</TableCell>
                    <TableCell style={{ width: "150px" }}>Presenty</TableCell>
                    <TableCell style={{ width: "250px" }}>
                      Presenty Amount
                    </TableCell>

                    <TableCell style={{ width: "150px" }}>From Date</TableCell>
                    <TableCell style={{ width: "150px" }}>To Date</TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Current Po No
                    </TableCell>

                    <TableCell style={{ width: "150px" }}>
                      Employee Name
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Employee Code
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Current PO Rate
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Canteen Amount
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Transportation Amount
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Active Status
                    </TableCell>
                    <TableCell style={{ width: "150px" }}>
                      Release Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList
                    .length === 0 ? (
                    <TableRow>
                      <TableCell
                        style={{ width: "150px" }}
                        colSpan={5}
                        align="center"
                      >
                        No Invoice Record found
                      </TableCell>
                    </TableRow>
                  ) : (
                    invoiceEmployeePoDetailsUIList.invoiceEmployeePoDetailsUIList.map(
                      (project, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: "30px" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ width: "30px" }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleDeleteRow(index)}
                            >
                              X
                            </Button>
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            <TextField
                              fullWidth
                              value={project.presenty}
                              onChange={handleInvoiceEmployeeDetailsChange(
                                index,
                                "presenty"
                              )}
                            />
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            <TextField
                              fullWidth
                              value={project.presentyAmt}
                              onChange={handleInvoiceEmployeeDetailsChange(
                                index,
                                "presentyAmt"
                              )}
                            />
                          </TableCell>

                          <TableCell style={{ width: "100px" }}>
                            <TextField
                              type="date"
                              fullWidth
                              value={project.fromDate}
                              onChange={handleInvoiceEmployeeDetailsChange(
                                index,
                                "fromDate"
                              )}
                            />
                          </TableCell>
                          <TableCell style={{ width: "100px" }}>
                            <TextField
                              type="date"
                              fullWidth
                              value={project.toDate}
                              onChange={handleInvoiceEmployeeDetailsChange(
                                index,
                                "toDate"
                              )}
                            />
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.currentPoNo}
                          </TableCell>

                          <TableCell style={{ width: "150px" }}>
                            {project.emplyoyeeName}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.employeeCode}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.currentPoRate}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.canteenAmt}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.transportationAmt}
                          </TableCell>

                          <TableCell style={{ width: "150px" }}>
                            {project.activeStatus}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {project.releaseDate}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Total Presenty Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    value={invoiceCalculation.totalPresentyAmount}
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "totalPresentyAmount",
                        e.target.value
                      )
                    }
                    fullWidth
                  />

                  <TextField
                    label="Total Tax Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalTaxAmt}
                    fullWidth
                  />
                  <TextField
                    label="Grand Total Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.grandTotalAmount}
                    fullWidth
                    style={{ color: "black" }}
                  />
                  <TextField
                    label=" Deduction Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.deductionAmount}
                    fullWidth
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Deduction Particular"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    multiline
                    rows={4}
                    value={invoiceCalculation.deductionParticular}
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "deductionParticular",
                        e.target.value
                      )
                    }
                    fullWidth
                    style={{ fontSize: "16px" }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="CGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.cgst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("cgst", e.target.value)
                    }
                  />
                  <TextField
                    label="SGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.sgst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("sgst", e.target.value)
                    }
                  />
                  <TextField
                    label="IGST"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.igst}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) =>
                      handleInvoiceCalculationChange("igst", e.target.value)
                    }
                  />
                  <TextField
                    label="IGST Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.igstAmount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    disabled
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "igstAmount",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    label="CGST Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.cgstAmount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    disabled
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "cgstAmount",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    label="SGST Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.sgstAmount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    disabled
                    onChange={(e) =>
                      handleInvoiceCalculationChange(
                        "sgstAmount",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    label="Total Tax Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalTaxAmt}
                    fullWidth
                  />
                  <TextField
                    label="Total in Words"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    multiline
                    rows={4}
                    variant="standard"
                    size="small"
                    value={invoiceCalculation.totalInWord}
                    fullWidth
                    disabled
                  />
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button variant="contained" onClick={handleCalculate}>
                    Calculate
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "10px", marginBottom: "10px" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ marginBottom: "15px", color: "#2196f3" }}
              >
                Payment Mode Details
              </Typography>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  fullWidth
                  margin="normal"
                  options={["15 Days Bank Transfer"]} // Add other options as needed
                  value={paymentMode.ModeTermsOfPayment}
                  onChange={(event, newValue) =>
                    handlePaymentModeChange("ModeTermsOfPayment")({
                      target: { value: newValue },
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mode Terms Of Payment"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  fullWidth
                  value={paymentMode.KindAttention}
                  onChange={handlePaymentModeChange("KindAttention")}
                  variant="standard"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={bankAccNoAndIfscCodes}
                  getOptionLabel={(option) => option}
                  value={paymentMode.SelectBankAccNo}
                  onChange={(event, newValue) => {
                    handlePaymentModeChange("SelectBankAccNo")({
                      target: { value: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Bank Acc No"
                      margin="normal"
                      variant="standard"
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Paper>
        <Paper
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            // disabled={isSubmitButtonDisabled() || isSubmitButtonDisabledForPdf}
            disabled={isSubmitDisabled || !isPdfButtonDisabled} // Disable the submit button based on state
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handlegeneratePDF}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            disabled={isPdfButtonDisabled} // Conditionally disable based on state
          >
            PDF
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleReset}
            style={{
              marginLeft: "20px",
              marginBottom: "20px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Reset
          </Button>
        </Paper>
      </Card>
    </Container>
  );
};

export default GenerateEmployeeInvoice;
