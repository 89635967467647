import Headers from "../../../Pages/Header";
import EmployeeInvoiceList from "../EmployeeInvoiceList";

const EmployeeInvoiceListPage = () => {
  return (
    <Headers>
      <EmployeeInvoiceList />
    </Headers>
  );
};
export default EmployeeInvoiceListPage;