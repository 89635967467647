import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tab,
  Tabs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  TablePagination,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";

const UpdateProjectPO = () => {
  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [clientDialogOpen, setClientDialogOpen] = useState(false);
  const [editedClientData, setEditedClientData] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermClientName, setSearchTermClientName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [isProjectAdd, setIsProjectAdd] = useState(false); // State to control update overlay
  const [isProjectDelete, setIsProjectDelete] = useState(false); // State to control update overlay
  const [isProjectRelease, setIsProjectRelease] = useState(false); // State to control update overlay
  const [isProjectUpdated, setIsProjectUpdated] = useState(false); // State to control update overlay
  const [isReleaseDataChanged, setReleaseIsDataChanged] = useState(false);
  const [newClientData, setNewClientData] = useState({
    projectPoNo: "",
    gsProjectUniqueCode: "",
    clientNameAndCode: "",
    projectName: "",
    projectPoDetailsUIList: [
      {
        projectPoNo: "",
        poDate: "",
        poStartDate: "",
        poEndDate: "",
        poAmount: "",
        description: "",
        ratePerDay: "",
        ratePerhour: "",
        projectHourOrDay: "",
        isActive: "",
        projectPOReleaseUI: {
          projectPoNo: "",
          clientNameAndCode: "",
          projectName: "",
          gsProjectUniqueCode: "",
          description: "",
          releaseNotes: "",
        },
      },
    ],
  });
  const [clientOptions, setClientOptions] = useState([]);
  const [addedClient, setAddedClient] = useState([]);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedProjectUniqueCode, setSelectedProjectUniqueCode] =
    useState("");

  useEffect(() => {
    myAxiosCommonHost
      .get("gst/client/getClientNameList")
      .then((response) => {
        setClientOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching data
      try {
        const response = await myAxiosCommonHost.get(
          "gst/projectpo/getProjectPoList"
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleAddClientClick = (po) => {
    setNewClientData({
      ...newClientData,
      projectPoNo: po.projectPoNo,
      gsProjectUniqueCode: po.gsProjectUniqueCode,
      clientNameAndCode: po.clientNameAndCode,
      projectName: po.projectName,
    });
    setAddClientDialogOpen(true);
  };
  const handleAddClientDialogClose = () => {
    setAddClientDialogOpen(false);
  };
  const handleReleaseButtonClick = (editedData, clientData) => {
    setEditedClientData(clientData);
    setEditedData(editedData);
    setReleaseDialogOpen(true);
  };
  const handleReleaseDialogClose = () => {
    setReleaseDialogOpen(false);
  };

  const handleEditClick = (editedData) => {
    setEditedData(editedData);
    setOpenDialog(true);
  };

  const handleClientEditClick = (editedData, clientData) => {
    setEditedData(editedData);
    setEditedClientData(clientData);
    setClientDialogOpen(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAddToTable = () => {
    // Check for duplicate entry based on description
    const isDuplicateDescription = addedClient.some(
      (client) =>
        client.projectPoDetailsUIList[0].description.trim() ===
        newClientData.projectPoDetailsUIList[0].description.trim()
    );

    if (isDuplicateDescription) {
      alert("Duplicate entry! Cannot add the same description.");
      return;
    }

    // Check if required fields are filled for newClientData
    const newClientDataFilled =
      // newClientData.projectPoDetailsUIList[0].poDate.trim() !== "" &&
      // newClientData.projectPoDetailsUIList[0].poStartDate.trim() !== "" &&
      // newClientData.projectPoDetailsUIList[0].poEndDate.trim() !== "" &&
      // newClientData.projectPoDetailsUIList[0].poAmount.trim() !== "" &&
      newClientData.projectPoDetailsUIList[0].description.trim() !== "";

    if (!newClientDataFilled) {
      alert("Please fill all required fields before adding to the table.");
      return;
    }

    // Add data to the table
    setAddedClient([...addedClient, newClientData]);

    // Reset newClientData
    setNewClientData({
      projectPoNo: newClientData.projectPoNo,
      gsProjectUniqueCode: newClientData.gsProjectUniqueCode,
      clientNameAndCode: newClientData.clientNameAndCode,
      projectName: newClientData.projectName,
      kindAttention: newClientData.kindAttention,
      projectPoDetailsUIList: [
        {
          projectPoNo: "",
          poDate: "",
          poStartDate: "",
          poEndDate: "",
          poAmount: "",
          description: "",
          ratePerDay: "",
          ratePerhour: "",
          projectHourOrDay: "",
          isActive: "",
        },
      ],
    });
  };

  const handleAddProject = async () => {
    if (addedClient.length === 0) {
      alert("Cannot submit without adding an Project to the table");
      return;
    }
    const requestData = {
      projectPoNo: addedClient[0].projectPoNo,
      gsProjectUniqueCode: addedClient[0].gsProjectUniqueCode,
      clientNameAndCode: addedClient[0].clientNameAndCode,
      projectName: addedClient[0].projectName,

      projectPoDetailsUIList: addedClient.flatMap((client) =>
        client.projectPoDetailsUIList.map((projectPo) => ({
          projectPoNo: projectPo.projectPoNo,
          poDate: projectPo.poDate,
          poStartDate: projectPo.poStartDate,
          poEndDate: projectPo.poEndDate,
          poAmount: projectPo.poAmount,
          ratePerDay: projectPo.ratePerDay,
          ratePerhour: projectPo.ratePerhour,
          projectHourOrDay: projectPo.projectHourOrDay,
          description: projectPo.description,
          isActive: projectPo.isActive,
        }))
      ),
    };

    console.log("Request Data:", JSON.stringify(requestData, null, 2));
    setIsProjectAdd(true);
    try {
      const updateResponse = await myAxiosCommonHost.put(
        "gst/projectpo/updateProjectPO",
        requestData
      );

      if (updateResponse.status === 200) {
        console.log("Update Response:", updateResponse.data);

        // Fetch updated data after successful addition
        const fetchDataResponse = await myAxiosCommonHost.get(
          "gst/projectpo/getProjectPoList"
        );

        if (fetchDataResponse.status === 200) {
          const updatedResult = await fetchDataResponse.data;
          setData(updatedResult); // Update the state with the new data
          setEditedClientData(updatedResult);
          setEditedData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }
        setAddClientDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error adding Project. Please try again.");
      }
      // const getResponse = await myAxiosCommonHost.get(
      //   "gst/projectpo/getProjectPoList"
      // );
      // console.log("Get Response:", getResponse.data);
    } catch (error) {
      console.error("Error sending or fetching data from the backend:", error);
    }
    setIsProjectAdd(false);
  };
  const handleClientDialogClose = () => {
    setClientDialogOpen(false);
  };
  const handleUpdateData = async () => {
    setIsUpdating(true);
    try {
      const updatedData = {
        ...editedData,
        projectPoDetailsUIList: [editedClientData],
      };

      const updateResponse = await myAxiosCommonHost.put(
        "gst/projectpo/updateProjectPO",
        updatedData
      );
      if (updateResponse.status === 200) {
        console.log("Successfully updated data:", updatedData);

        const fetchDataResponse = await myAxiosCommonHost.get(
          "gst/projectpo/getProjectPoList"
        );

        if (fetchDataResponse.status === 200) {
          const updatedResult = fetchDataResponse.data;
          setData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }

        setOpenDialog(false);
        setClientDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Error updating data. Please try again.");
    }
    setIsUpdating(false);
  };
  const handleUpdateProjectData = async () => {
    setIsProjectUpdated(true);
    try {
      const updatedData = {
        ...editedData,
        projectPoDetailsUIList: [editedClientData],
      };

      const updateResponse = await myAxiosCommonHost.put(
        "gst/projectpo/updateProjectPO",
        updatedData
      );
      if (updateResponse.status === 200) {
        console.log("Successfully updated data:", updatedData);

        const fetchDataResponse = await myAxiosCommonHost.get(
          "gst/projectpo/getProjectPoList"
        );

        if (fetchDataResponse.status === 200) {
          const updatedResult = fetchDataResponse.data;
          setData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }

        setOpenDialog(false);
        setClientDialogOpen(false);
      } else {
        console.error("Failed to update data.");
        alert("Error updating data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Error updating data. Please try again.");
    }
    setIsProjectUpdated(false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const handleDeleteRow = (index) => {
    const updatedClient = [...addedClient];
    updatedClient.splice(index, 1);
    setAddedClient(updatedClient);
  };
  const handleReleaseProject = async () => {
    setIsProjectRelease(true);
    try {
      const releaseData = {
        projectPoNo: editedClientData.projectPoNo || "",
        clientNameAndCode: editedData.clientNameAndCode || "",
        projectName: editedData.projectName || "",
        gsProjectUniqueCode: editedData.gsProjectUniqueCode || "",
        description: editedClientData.description || "",
        releaseNotes: editedData.releaseNotes || "",
        releaseDate: editedData.releaseDate || "",
      };
      console.log("Release Data:", JSON.stringify(releaseData, null, 2));
      const releaseResponse = await myAxiosCommonHost.post(
        "/gst/projectpo/projectReleaseAsPerDescription",
        releaseData
      );

      if (releaseResponse.status === 200) {
        console.log("Successfully Released Project:", releaseData);
        const fetchDataResponse = await myAxiosCommonHost.get(
          "gst/projectpo/getProjectPoList"
        );
        if (fetchDataResponse.status === 200) {
          const updatedResult = await fetchDataResponse.data;
          setData(updatedResult);
          setEditedData(updatedResult);
        } else {
          console.error("Failed to fetch updated data.");
        }
        setReleaseDialogOpen(false);
      } else {
        console.error(
          "Failed to release Project. Status:",
          releaseResponse.status
        );
        alert("Error releasing Project. Please try again.");
      }
    } catch (error) {
      console.error("Error releasing Project:", error);
      alert("Error releasing Project. Please try again.");
    }
    setIsProjectRelease(false);
  };
  const handleDeleteClick = async (gsProjectUniqueCode) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await handleDeleteButtonClick(gsProjectUniqueCode);
      setIsDeleting(false);
    }
  };
  // const handleDeleteButtonClick = async (gsProjectUniqueCode) => {
  //   try {
  //     const response = await myAxiosCommonHost.delete(
  //       `gst/projectpo/deleteProjectPO/uniqueProjectAndPoNo?uniqueProjectAndPoNo=${gsProjectUniqueCode}`
  //     );
  //     if (response.status === 200) {
  //       const updatedDataResponse = await myAxiosCommonHost.get(
  //         "gst/projectpo/getProjectPoList"
  //       );
  //       //alert("Project PO deleted successfully!");
  //       setData(updatedDataResponse.data);
  //     } else {
  //       alert("Failed to delete invoice. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting PO:", error);
  //     alert("An error occurred while deleting the invoice.");
  //   }
  // };
  // if (fetchError) {
  //   return <div>Error fetching data. Please try again later.</div>;
  // }
  // if (loading) {
  //   return <div>Loading...</div>;
  // }
  const resetNewClientData = () => {
    setNewClientData({
      projectPoNo: newClientData.projectPoNo,
      gsProjectUniqueCode: newClientData.gsProjectUniqueCode,
      clientNameAndCode: newClientData.clientNameAndCode,
      projectName: newClientData.projectName,
      kindAttention: newClientData.kindAttention,
      projectPoDetailsUIList: [
        {
          projectPoNo: "",
          poDate: "",
          poStartDate: "",
          poEndDate: "",
          poAmount: "",
          description: "",
          ratePerDay: "",
          ratePerhour: "",
          projectHourOrDay: "",
          isActive: "",
          projectPOReleaseUI: {
            projectPoNo: "",
            clientNameAndCode: "",
            projectName: "",
            gsProjectUniqueCode: "",
            description: "",
            releaseNotes: "",
          },
        },
      ],
    });
  };
  const handleFieldChange = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
    setIsDataChanged(true); // Set to true whenever a field is changed
  };
  const handleFieldEditPO = (fieldName, value) => {
    setEditedClientData({
      ...editedClientData,
      [fieldName]: value,
    });
    setIsDataChanged(true); // Set to true whenever a field is changed
  };
  const handleReleasePO = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
    setEditedClientData({
      ...editedClientData,
      [fieldName]: value,
    });
    setReleaseIsDataChanged(true);
    setIsDataChanged(true); // Set to true whenever a field is changed
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteProject = async (projectData) => {
    setIsProjectDelete(true);
    try {
      if (projectData && projectData.projectPOReleaseUI) {
        const projectPOReleaseUI = projectData.projectPOReleaseUI;
        const deleteData = {
          projectPoNo: projectPOReleaseUI.projectPoNo,
          projectName: projectPOReleaseUI.projectName,
          description: projectPOReleaseUI.description,
          releaseNotes: projectPOReleaseUI.releaseNotes,
          gsProjectUniqueCode: projectPOReleaseUI.gsProjectUniqueCode,
          clientNameAndCode: projectPOReleaseUI.clientNameAndCode,
          releaseDate: projectPOReleaseUI.releaseDate,
        };
        const confirmDelete = window.confirm(
          "Are you sure you want to delete this project record?"
        );
        if (confirmDelete) {
          // Perform the POST request to delete the Project data using Axios
          const response = await myAxiosCommonHost.post(
            "gst/projectpo/projectDeleteAsPerDescription",
            deleteData
          );
          console.log(JSON.stringify(deleteData, null, 2));

          if (response.status === 200) {
            const updatedDataResponse = await myAxiosCommonHost.get(
              "gst/projectpo/getProjectPoList"
            );
            setData(updatedDataResponse.data);
            setEditedData(updatedDataResponse.data);
            console.log("Project data deleted successfully:", deleteData);
          } else {
            console.error("Failed to delete Project data.");
            alert("Error deleting data. Please try again.");
          }
        }
      } else {
        console.error(
          "Project data or projectPoDetailsUIList is missing:",
          projectData
        );
        alert("Error deleting data. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting Project data:", error);
      alert("Error deleting data. Please try again.");
    }
    setIsProjectDelete(false);
  };
  const handleDeleteButtonClick = async (gsProjectUniqueCode) => {
    setSelectedProjectUniqueCode(gsProjectUniqueCode);
    setDeleteDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/login/verificationForDeletionRecord?userPassword=${password}`
      );
      if (response.status === 200 && response.data) {
        const confirmDelete = window.confirm(
          "Password verified. Are You Want to Delete This Record..."
        );
        console.log("Password verified. Deleting record...");
        if (confirmDelete) {
          // Perform deletion logic here
          const deleteResponse = await myAxiosCommonHost.delete(
            `gst/projectpo/deleteProjectPO/uniqueProjectAndPoNo?uniqueProjectAndPoNo=${selectedProjectUniqueCode}`
          );

          if (deleteResponse.status === 200) {
            console.log("PO deleted successfully!");
            const updatedDataResponse = await myAxiosCommonHost.get(
              "gst/projectpo/getProjectPoList"
            );
            if (updatedDataResponse.status === 200) {
              const updatedResult = await updatedDataResponse.data;
              setData(updatedResult);
              setEditedData(updatedResult);
            } else {
              console.error("Failed to fetch updated data.");
            }
            setDeleteDialogOpen(false);
          } else {
            console.error("Failed to delete PO.");
            alert("Failed to delete PO. Please try again.");
          }
        } else {
          console.error("Failed to verify password.");
          alert("Failed to verify password. Deletion canceled.");
        }
      }
    } catch (error) {
      console.error("Error deleting PO:", error);
      alert("An error occurred while deleting the PO.");
    }
    setIsDeleting(false);
    setDeleteDialogOpen(false);
  };
  return (
    <div>
      {isDeleting && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">PO is Deleting...</div>
        </div>
      )}
      {isUpdating && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">PO is Updating...</div>
        </div>
      )}
      {isProjectAdd && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Project is Adding...</div>
        </div>
      )}
      {isProjectDelete && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Project is Deleting...</div>
        </div>
      )}
      {isProjectRelease && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Project is Releasing...</div>
        </div>
      )}
      {isProjectUpdated && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Project is Updating...</div>
        </div>
      )}

      <TextField
        label="Search By Client Name"
        variant="standard"
        value={searchTermClientName}
        onChange={(e) => setSearchTermClientName(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      {/* <TextField
        label="Search by Po Number"
        variant="standard"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      /> */}
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Link to="/generateProjectPO" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
          >
            Generate Project PO
          </Button>
        </Link>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Project Purchase Order
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>No Record Found !... Add New Project PO.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {/* {loading ? (
            <p>Loading...</p>
          ) : ( */}
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              {data.length === 0 ? (
                <p>No data found.</p>
              ) : (
                <Grid item xs={12}>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow
                          style={{
                            backgroundColor: "darkcyan",
                            fontWeight: "bold",
                            color: "darkblue",
                          }}
                        >
                          <TableCell />
                          <TableCell>Project Po No</TableCell>
                          <TableCell>Client Name</TableCell>
                          <TableCell>Project Name</TableCell>
                          <TableCell>Project Unique Code</TableCell>
                          <TableCell>Kind Attention</TableCell>
                          <TableCell></TableCell>
                          <TableCell>Action</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .filter((po) =>
                            po.clientNameAndCode
                              .toLowerCase()
                              .includes(searchTermClientName)
                          )
                          // .filter((po) =>
                          //   po.poNumber
                          //     .toString()
                          //     .includes(searchTerm.toString().toLowerCase())
                          // )
                          .map((po, index) => (
                            <React.Fragment key={index}>
                              <TableRow>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleExpandClick(index)}
                                    aria-expanded={expandedRow === index}
                                    aria-label="show more"
                                  >
                                    <ExpandMoreIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>{po.projectPoNo}</TableCell>
                                <TableCell>{po.clientName}</TableCell>
                                <TableCell>{po.projectName}</TableCell>
                                <TableCell>{po.gsProjectUniqueCode}</TableCell>
                                <TableCell>{po.kindAttention}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleEditClick(po)}
                                  >
                                    Update PO
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAddClientClick(po)}
                                  >
                                    Add Project
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      handleDeleteClick(po.gsProjectUniqueCode)
                                    }
                                  >
                                    Delete PO
                                  </Button>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Collapse
                                    in={expandedRow === index}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {(po.projectPoDetailsUIList || []).map(
                                      (client, clientIndex) => (
                                        <Accordion key={clientIndex}>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${index}-content`}
                                            id={`panel${index}-header`}
                                            IconButtonProps={{ edge: "start" }}
                                          >
                                            <Typography>
                                              {"Description -:"}
                                              {
                                                po.projectPoDetailsUIList[
                                                  clientIndex
                                                ].description
                                              }
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <Table style={{ width: "100%" }}>
                                              <TableBody>
                                                <TableRow
                                                  style={{
                                                    backgroundColor:
                                                      "goldenrod",
                                                    fontWeight: "bold",
                                                    color: "darkblue",
                                                  }}
                                                >
                                                  {/* <TableCell>Project PoNo</TableCell> */}
                                                  <TableCell>
                                                    Description
                                                  </TableCell>
                                                  <TableCell>
                                                    Po Amount
                                                  </TableCell>
                                                  <TableCell>
                                                    Rate Per Day
                                                  </TableCell>
                                                  <TableCell>
                                                    Rate Per Hour
                                                  </TableCell>
                                                  <TableCell>Po Date</TableCell>
                                                  <TableCell>
                                                    Po StartDate
                                                  </TableCell>
                                                  <TableCell>
                                                    Po EndDate
                                                  </TableCell>
                                                  <TableCell></TableCell>
                                                  <TableCell></TableCell>
                                                  <TableCell>Action</TableCell>
                                                  <TableCell></TableCell>
                                                </TableRow>
                                                {client ? (
                                                  <TableRow>
                                                    {/* <TableCell>{client.projectPoNo}</TableCell> */}
                                                    <TableCell>
                                                      {client?.description ||
                                                        "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {client?.poAmount ||
                                                        "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {client?.ratePerDay ||
                                                        "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {client?.ratePerhour ||
                                                        "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {client?.projectHourOrDay ||
                                                        "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {client.poDate
                                                        ? client?.poDate
                                                            .split("-")
                                                            .reverse()
                                                            .join("-")
                                                        : "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {client.poStartDate
                                                        ? client?.poStartDate
                                                            .split("-")
                                                            .reverse()
                                                            .join("-")
                                                        : "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {client.poEndDate
                                                        ? client?.poEndDate
                                                            .split("-")
                                                            .reverse()
                                                            .join("-")
                                                        : "N/A"}
                                                    </TableCell>

                                                    {/* <TableCell>{client.isActive}</TableCell> */}
                                                    <TableCell>
                                                      <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                          handleClientEditClick(
                                                            po,
                                                            client
                                                          )
                                                        }
                                                      >
                                                        Update
                                                      </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                      <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                          handleReleaseButtonClick(
                                                            po,
                                                            client
                                                          )
                                                        }
                                                      >
                                                        Release
                                                      </Button>
                                                    </TableCell>

                                                    <TableCell>
                                                      <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                          handleDeleteProject(
                                                            client
                                                          )
                                                        }
                                                      >
                                                        Delete Project
                                                      </Button>
                                                    </TableCell>
                                                  </TableRow>
                                                ) : (
                                                  <TableRow>
                                                    <TableCell
                                                      colSpan={4}
                                                      align="center"
                                                    >
                                                      No Project Details Found
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                                <TableRow></TableRow>
                                              </TableBody>
                                            </Table>
                                          </AccordionDetails>
                                        </Accordion>
                                      )
                                    )}
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Grid>
              )}
            </>
          )}
        </TableContainer>
      )}
      <Dialog
        open={addClientDialogOpen}
        onClose={handleAddClientDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Add Project</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  label="Project Po No"
                  value={newClientData.projectPoNo || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Name And Code"
                  value={newClientData.clientNameAndCode || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Project Unique Code"
                  value={newClientData.gsProjectUniqueCode || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Project Name"
                  value={newClientData.projectName || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Kind Attention"
                  value={newClientData.kindAttention || "N/A"}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="subtitle1">Client Details</Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Description"
                  value={newClientData.projectPoDetailsUIList[0].description}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          description: e.target.value,
                        },
                      ],
                    })
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Amount"
                  value={newClientData.projectPoDetailsUIList[0].poAmount}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poAmount: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rate Per Day"
                  value={newClientData.projectPoDetailsUIList[0].ratePerDay}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          ratePerDay: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rate Per Hour"
                  value={newClientData.projectPoDetailsUIList[0].ratePerhour}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          ratePerhour: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Po Date"
                  type="date"
                  value={newClientData.projectPoDetailsUIList[0].poDate}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO Start Date"
                  type="date"
                  value={newClientData.projectPoDetailsUIList[0].poStartDate}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poStartDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PO End Date"
                  type="date"
                  value={newClientData.projectPoDetailsUIList[0].poEndDate}
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          poEndDate: e.target.value,
                        },
                      ],
                    })
                  }
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Project Hour Or Day"
                  value={
                    newClientData.projectPoDetailsUIList[0].projectHourOrDay
                  }
                  onChange={(e) =>
                    setNewClientData({
                      ...newClientData,
                      projectPoDetailsUIList: [
                        {
                          ...newClientData.projectPoDetailsUIList[0],
                          projectHourOrDay: e.target.value,
                        },
                      ],
                    })
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  {/* <TableCell>Project PoNo</TableCell> */}
                  <TableCell>Description</TableCell>
                  <TableCell>Po Amount</TableCell>
                  <TableCell>Rate Per Day</TableCell>
                  <TableCell>Rate Per Hour</TableCell>
                  <TableCell>Po Date</TableCell>
                  <TableCell>Po StartDate</TableCell>
                  <TableCell>Po EndDate</TableCell>

                  {/* <TableCell>Is Active</TableCell> */}
                  <TableCell>Action</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedClient.map((client, index) => (
                  <TableRow key={index}>
                    {/* <TableCell>
                      {client.projectPoDetailsUIList[0].projectPoNo}
                    </TableCell> */}
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].description || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poAmount || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].ratePerDay || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0]?.ratePerhour || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0]?.projectHourOrDay ||
                        "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poDate
                        .split("-")
                        .reverse()
                        .join("-") || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poStartDate
                        .split("-")
                        .reverse()
                        .join("-") || "N/A"}
                    </TableCell>
                    <TableCell>
                      {client?.projectPoDetailsUIList[0].poEndDate
                        .split("-")
                        .reverse()
                        .join("-") || "N/A"}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteRow(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button onClick={handleAddToTable} color="primary">
            Add to Table
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClientDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAddProject}
            color="primary"
            disabled={addedClient.length === 0}
          >
            Add Project
          </Button>
          <Button onClick={resetNewClientData} color="secondary">
            Reset
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxHeight="md"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit PO Data</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <TextField
                  label="Project Po No"
                  value={editedData ? editedData.projectPoNo : ""}
                  onChange={(e) =>
                    handleFieldChange("projectPoNo", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Client Name And Code"
                  value={editedData ? editedData.clientNameAndCode : ""}
                  onChange={(e) =>
                    handleFieldChange("clientNameAndCode", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Project Name"
                  value={editedData ? editedData.projectName : ""}
                  onChange={(e) =>
                    handleFieldChange("projectName", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Project Unique Code"
                  value={editedData ? editedData.gsProjectUniqueCode : ""}
                  onChange={(e) =>
                    handleFieldChange("gsProjectUniqueCode", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  value={editedData ? editedData.kindAttention : ""}
                  onChange={(e) =>
                    handleFieldChange("kindAttention", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateData}
            color="primary"
            disabled={!isDataChanged} // Disable the button if no changes
          >
            Update PO
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={clientDialogOpen}
        onClose={handleClientDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Project Data</DialogTitle>
        <DialogContent>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Client Details" />
          </Tabs>
          {currentTab === 0 && (
            <div>
              <Paper
                elevation={3}
                style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Description"
                      value={
                        editedClientData ? editedClientData.description : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     description: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("description", e.target.value)
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="PO Amount"
                      value={editedClientData ? editedClientData.poAmount : ""}
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     poAmount: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("poAmount", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Rate Per Day"
                      value={
                        editedClientData ? editedClientData.ratePerDay : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     ratePerDay: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("ratePerDay", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Rate Per Hour"
                      value={
                        editedClientData ? editedClientData.ratePerhour : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     ratePerhour: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("ratePerhour", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="PO Date"
                      value={editedClientData ? editedClientData.poDate : ""}
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     poDate: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("poDate", e.target.value)
                      }
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po Start Date"
                      value={
                        editedClientData ? editedClientData.poStartDate : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     poStartDate: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("poStartDate", e.target.value)
                      }
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Po End Date"
                      value={editedClientData ? editedClientData.poEndDate : ""}
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     poEndDate: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("poEndDate", e.target.value)
                      }
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Project Hour Or Day"
                      value={
                        editedClientData
                          ? editedClientData.projectHourOrDay
                          : ""
                      }
                      // onChange={(e) =>
                      //   setEditedClientData({
                      //     ...editedClientData,
                      //     projectHourOrDay: e.target.value,
                      //   })
                      // }
                      onChange={(e) =>
                        handleFieldEditPO("projectHourOrDay", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClientDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateData}
            disabled={!isDataChanged}
            color="primary"
          >
            Update Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={releaseDialogOpen}
        onClose={handleReleaseDialogClose}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Project Release</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Project Po No"
                  value={editedClientData ? editedClientData.projectPoNo : ""}
                  onChange={(e) =>
                    handleReleasePO("projectPoNo", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Client Name And Code"
                  value={editedData ? editedData.clientNameAndCode : ""}
                  onChange={(e) =>
                    handleReleasePO("clientNameAndCode", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Description"
                  value={editedClientData ? editedClientData.description : ""}
                  onChange={(e) =>
                    handleReleasePO("description", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Project Unique Code"
                  value={editedData ? editedData.gsProjectUniqueCode : ""}
                  onChange={(e) =>
                    handleReleasePO("gsProjectUniqueCode", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Project Name"
                  value={editedData ? editedData.projectName : ""}
                  onChange={(e) =>
                    handleReleasePO("projectName", e.target.value)
                  }
                  required
                  disabled
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Release Note"
                  value={editedData ? editedData.releaseNotes : ""}
                  onChange={(e) =>
                    handleReleasePO("releaseNotes", e.target.value)
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Release Date"
                  value={editedData ? editedData.releaseDate : ""}
                  onChange={(e) =>
                    handleReleasePO("releaseDate", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReleaseDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleReleaseProject}
            color="primary"
            disabled={!isReleaseDataChanged}
          >
            Project Release
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setPassword(""); // Reset password field
        }}
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Enter Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="primary"
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateProjectPO;
