import React, { useState, useEffect } from "react";
import {
  Card,
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { CardHeader } from "react-bootstrap";
import jsPDF from "jspdf";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";
const InvoiceReport = () => {
  const [formData, setFormData] = useState({
    allOrSelected: "",
    filterType: "",
    clientNameAndCode: "",
    financialYear: "",
    fromDate: "",
    toDate: "",
    invoiceType: "",
    gstFilter: "",
    paidAndUnPaidAmtFilter: "",
    paymentMonth: "",
    paymentYear: "",
    invoiceTitle: "",
    bankRefNum: "",
    poNumber: "",
    uniquePoNo: "",
  });

  const [reportData, setReportData] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [paymentYears, setPaymentYears] = useState([]);
  const [uniquePoNos, setUniquePoNos] = useState([]);
  const [paymentMonths, setPaymentMonths] = useState([]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  useEffect(() => {
    myAxiosCommonHost
      .get("gst/report/getUniquePoNoReportList")
      .then((response) => {
        setUniquePoNos(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    myAxiosCommonHost
      .get("gst/client/getClientNameList")
      .then((response) => {
        setClientOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchPaymentYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getPaymentYear"
        );
        setPaymentYears(response.data);
      } catch (error) {
        console.error("Error fetching paymentYears:", error);
      }
    };

    fetchPaymentYears();
  }, []);

  useEffect(() => {
    const fetchPaymentMonths = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getShortMonth"
        );
        setPaymentMonths(response.data);
      } catch (error) {
        console.error("Error fetching paymentMonths:", error);
      }
    };

    fetchPaymentMonths();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    const fetchFinancialYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYears(response.data);
      } catch (error) {
        console.error("Error fetching financialYears:", error);
      }
    };

    fetchFinancialYears();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingOverlay(true);
    try {
      const response = await myAxiosCommonHost.post(
        "gst/report/getAllOrFilterReportList",
        formData
      );
      console.log(JSON.stringify(formData, null, 2));
      setReportData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingOverlay(false);
    }
  };
  const downloadPDF = () => {
    const doc = new jsPDF();

    // Set page border
    doc.rect(
      5,
      5,
      doc.internal.pageSize.width - 10,
      doc.internal.pageSize.height - 10
    );

    const headingText = "Invoice Report";
    const headingWidth =
      (doc.getStringUnitWidth(headingText) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (doc.internal.pageSize.width - headingWidth) / 2;
    doc.setFont("bold");
    doc.setFontSize(14);
    doc.text(headingText, headingX, 15);

    // Add table headers
    const headers = [
      "Sr.No",
      "Invoice Title",
      "Client Name",
      "Employee Name",
      "Department",
      "Sub Department",
      "Invoice Type",
      "Invoice Class",
      "MonthYear",
      "Invoice Date",
      "PO Number",
      "Invoice Ref",
      "Taxable Amount",
      "Buyers PO",
      "IGST",
      "CGST",
      "SGST",
      "Total Invoice Amount",
      "TDS Amount",
      "Paid Amount",
      "Unpaid Amount",
      "Bank Ref Number",
      "Payment Date",
      "Payment Mode",

      "Remark",
    ];

    const data = reportData.map((row, index) => [
      index + 1,
      row.invoiceTitle,
      row.clientName,
      row.employeeName,
      row.department,
      row.subDepartment,
      row.invoiceType,
      row.invoiceClass,
      row.monthYear,
      row.invoiceDate,
      row.poNumber,
      row.invoiceRef,
      row.taxableAmount,
      row.buyersPo,
      row.igst,
      row.cgst,
      row.sgst,
      row.totalAmount,
      row.tdsAmount,
      row.paidAmount,
      row.unpaidAmount,
      row.bankRefNumber,
      row.paymentDate,
      row.paymentMode,

      row.remark,
    ]);

    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
      startX: 10,
      theme: "grid",
      styles: {
        cellPadding: 2,
        fontSize: 8,
        valign: "middle",
        halign: "center",
        textColor: [0, 0, 0],
        lineWidth: 0.2,
        lineColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.2,
        lineColor: [0, 0, 0],
      },
      columnStyles: {
        0: { halign: "left" },
      },
      margin: { top: 35, left: 10, right: 10 },
      tableWidth: "15000",
    });

    doc.save("Invoice_Collection_Report.pdf");
  };
  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(reportData);

    // Define column widths (in characters)
    const columnWidths = [];
    const defaultColumnWidth = 15; // Width of 2 cells for all columns
    const numColumns = 16; // Number of columns in your data

    for (let i = 0; i < numColumns; i++) {
      columnWidths.push({ wch: defaultColumnWidth });
    }

    // Set column widths
    ws["!cols"] = columnWidths;

    // Check if !rows is defined before iterating
    if (ws["!rows"]) {
      ws["!rows"].forEach((row) => {
        // Apply style to center align
        row.s = { alignment: { horizontal: "center" } };
      });
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Invoice Report");
    XLSX.writeFile(wb, "Invoice_Collection_Report.xlsx");
  };

  return (
    <Card elevation={3} style={{ marginBottom: "20px" }}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <form onSubmit={handleSubmit}>
          <CardHeader title=" Invoice Report" />
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{
                padding: "10px",
                marginBottom: "10px",
                backgroundColor: "#2196f3",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginBottom: "15px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Invoice Report
              </Typography>
            </Paper>
            {loadingOverlay && (
              <div className="loading-overlay">
                <CircularProgress size={60} />
                <div className="loading-message">Viewing Invoice Report...</div>
              </div>
            )}
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Autocomplete
                  options={["ALL", "SELECTED"]}
                  value={formData.allOrSelected}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, allOrSelected: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="All or Selected"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={[
                    " OTHERCHARGESINVOICE",
                    "EMPLOYEEPOINVOICE",
                    "PROJECTPOINVOICE",
                  ]}
                  value={formData.filterType}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, filterType: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter Type"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={clientOptions}
                  value={formData.clientNameAndCode}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, clientNameAndCode: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name And Code"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={uniquePoNos}
                  value={formData.uniquePoNo}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, uniquePoNo: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unique Po Number"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={financialYears}
                  value={formData.financialYear}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, financialYear: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Financial Year"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="From Date"
                  name="fromDate"
                  type="date"
                  variant="standard"
                  value={formData.fromDate}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="To Date"
                  name="toDate"
                  type="date"
                  variant="standard"
                  value={formData.toDate}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={paymentMonths}
                  value={formData.paymentMonth}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, paymentMonth: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service Of Month"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={paymentYears}
                  value={formData.paymentYear}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, paymentYear: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service Of Year"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={["Debit Note", "Credit Note", "Tax Invoice"]}
                  value={formData.invoiceType}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, invoiceType: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Type"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={["PROFORMAINVOICE", "TAXINVOICE"]}
                  value={formData.invoiceTitle}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      invoiceTitle: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Title "
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  options={["IGST", "SGSTCGST"]}
                  value={formData.gstFilter}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, gstFilter: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="GST Filter"
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={[, "PAID", "UNPAID"]}
                  value={formData.paidAndUnPaidAmtFilter}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      paidAndUnPaidAmtFilter: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Paid and Unpaid Amount "
                      variant="standard"
                      margin="normal"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Paper
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                View
              </Button>
              {/* <Button
                variant="contained"
                color="secondary"
                onClick={downloadPDF}
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                PDF
              </Button> */}
              <Button
                variant="contained"
                color="secondary"
                onClick={downloadExcel}
                style={{
                  marginLeft: "20px",
                  backgroundColor: "green",
                  marginTop: "20px",
                }}
              >
                Excel
              </Button>
            </Paper>
          </Paper>
        </form>
        {/* {reportData.length > 0 && ( */}
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Sr.No</TableCell>
                    <TableCell>Invoice Title</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Sub Department</TableCell>
                    <TableCell>Invoice Type</TableCell>
                    <TableCell>Invoice Class</TableCell>
                    <TableCell>MonthYear</TableCell>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>PO Number</TableCell>
                    <TableCell>Invoice Ref</TableCell>
                    <TableCell>Taxable Amount</TableCell>
                    <TableCell>Buyers PO</TableCell>
                    <TableCell>IGST</TableCell>
                    <TableCell>CGST</TableCell>
                    <TableCell>SGST</TableCell>
                    <TableCell>Total Invoice Amount</TableCell>
                    <TableCell>TDS Amount</TableCell>
                    <TableCell>Paid Amount</TableCell>
                    <TableCell>Unpaid Amount</TableCell>
                    <TableCell>Bank Ref Number</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Payment Mode</TableCell>
                    <TableCell>Remark</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Invoice Report found
                      </TableCell>
                    </TableRow>
                  ) : (
                    reportData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.invoiceTitle}</TableCell>
                        <TableCell>{row.clientName}</TableCell>
                        <TableCell>{row.employeeName}</TableCell>
                        <TableCell>{row.department}</TableCell>
                        <TableCell>{row.subDepartment}</TableCell>
                        <TableCell>{row.invoiceType}</TableCell>
                        <TableCell>{row.invoiceClass}</TableCell>
                        <TableCell>{row.monthYear}</TableCell>
                        <TableCell>
                          {row.invoiceDate
                            ? row.invoiceDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.poNumber}</TableCell>
                        <TableCell>{row.invoiceRef}</TableCell>
                        <TableCell>{row.taxableAmount}</TableCell>
                        <TableCell>{row.buyersPo}</TableCell>
                        <TableCell>{row.igst}</TableCell>
                        <TableCell>{row.cgst}</TableCell>
                        <TableCell>{row.sgst}</TableCell>
                        <TableCell>{row.totalInvoiceAmount}</TableCell>
                        <TableCell>{row.tdsAmount}</TableCell>
                        <TableCell>{row.paidAmount}</TableCell>
                        <TableCell>{row.unpaidAmount}</TableCell>
                        <TableCell>{row.bankRefNumber}</TableCell>
                        <TableCell>
                          {row.paymentDate
                            ? row.paymentDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.paymentMode}</TableCell>
                        <TableCell>{row.remark}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        {/* )} */}
      </Paper>
    </Card>
  );
};

export default InvoiceReport;
