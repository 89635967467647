import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { saveAs } from "file-saver";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedQuotation, setEditedQuotation] = useState({ ...row });
  const [editedAccomodation, setEditedAccomodation] = useState(
    Array.isArray(row.accomodationUIList) ? [...row.accomodationUIList] : []
  );
  const [tabValue, setTabValue] = useState(0);
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.quotationNo);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedQuotation((prevQuotation) => ({
      ...prevQuotation,
      [name]: value,
    }));
    setIsDataChangedEdit(true);
  };

  const handleAccomodationChange = (e, index) => {
    const { name, value } = e.target;
    const updatedAccomodation = [...editedAccomodation];
    updatedAccomodation[index] = {
      ...updatedAccomodation[index],
      [name]: value,
    };
    setEditedAccomodation(updatedAccomodation);
    setIsDataChangedEdit(true);
  };
  const handleAddAccomodation = () => {
    setEditedAccomodation([
      ...editedAccomodation,
      {
        description: "",
        costPerResources: "",
        noOfResources: "",
        noOfMonth: "",
        totalCost: "",
      },
    ]);
  };
  const handleDeleteAccomodation = (index) => {
    const updatedAccomodation = [...editedAccomodation];
    updatedAccomodation.splice(index, 1);
    setEditedAccomodation(updatedAccomodation);
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    const updatedQuotation = {
      ...editedQuotation,

      accomodationUIList: editedAccomodation,
    };
    onUpdate(updatedQuotation);
    setDialogOpen(false);
  };
  if (!editedQuotation) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.selectCompany}</TableCell>
        <TableCell>{row.quotationRefNo}</TableCell>
        <TableCell>{row.quotationNo}</TableCell>
        <TableCell>{row.clientNameAndCode}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} variant="contained" color="primary">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Quotation Information</Typography>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Select Company:</TableCell>
                  <TableCell>Financial Year:</TableCell>
                  <TableCell>Financial Month:</TableCell>
                  <TableCell>Quotation Ref Number:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.selectCompany}</TableCell>
                  <TableCell> {row.financialYear || "N/A"}</TableCell>
                  <TableCell>{row.financialMonth || "N/A"}</TableCell>
                  <TableCell>{row.quotationRefNo}</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Quotation No:</TableCell>
                  <TableCell>Quotation Date:</TableCell>
                  <TableCell>Client Name And Code:</TableCell>
                  <TableCell>Kind Attention:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.quotationNo || "N/A"}</TableCell>
                  <TableCell>
                    {row.quotationDate
                      ? row.quotationDate.split("-").reverse().join("-")
                      : "N/A"}
                  </TableCell>
                  <TableCell>{row.clientNameAndCode || "N/A"}</TableCell>
                  <TableCell>{row.kindAttention || "N/A"}</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Quotation Subject:</TableCell>
                  <TableCell>Quotation Content:</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{row.quotationSubject || "N/A"}</TableCell>
                  <TableCell>{row.quotationContent || "N/A"}</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Total Amount:</TableCell>
                  <TableCell>Total In Word:</TableCell>
                </TableRow>
                <TableRow>
                  <TableRow>
                    <TableCell> {row.totalAmount || "N/A"}</TableCell>
                    <TableCell>{row.totalInWord || "N/A"}</TableCell>
                  </TableRow>
                </TableRow>
                <Typography variant="h6">Accomodation Details</Typography>
                {row.accomodationUIList ? (
                  row.accomodationUIList.map((accomodation, index) => (
                    <div key={index}>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell> Sr No : </TableCell>
                        <TableCell> Description:</TableCell>
                        <TableCell>Cost Per Resources: </TableCell>
                        <TableCell> Number Of Resources : </TableCell>
                        <TableCell> Number Of Month:</TableCell>
                        <TableCell>Total Cost</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {accomodation?.description || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.costPerResources || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.noOfResources || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.noOfMonth || "N/A"}
                        </TableCell>
                        <TableCell>
                          {accomodation?.totalCost || "N/A"}
                        </TableCell>
                      </TableRow>
                    </div>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Accomodation found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Edit Accomodation Information</DialogTitle>
        <DialogContent>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Quotation Information" />
            <Tab label="Accomodation Information" />
          </Tabs>
          {tabValue === 0 && (
            <>
              <Paper
                elevation={3}
                style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
              >
                <Grid container spacing={6} style={{ marginTop: 10 }}>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Select Company"
                      name="selectCompany"
                      value={editedQuotation.selectCompany || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation Ref Number"
                      name="quotationRefNo"
                      value={editedQuotation.quotationRefNo || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation No"
                      name="quotationNo"
                      value={editedQuotation.quotationNo || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation Date"
                      name="quotationDate"
                      type="date"
                      value={editedQuotation.quotationDate || ""}
                      onChange={handleFieldChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Client Name And Code"
                      name="clientNameAndCode"
                      value={editedQuotation.clientNameAndCode || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Kind Attention"
                      name="kindAttention"
                      value={editedQuotation.kindAttention || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  {/* <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation Subject"
                      name="quotationSubject"
                      value={editedQuotation.quotationSubject || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Quotation Content"
                      name="quotationContent"
                      value={editedQuotation.quotationContent || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Quotation Subject"
                      name="quotationSubject"
                      value={editedQuotation.quotationSubject || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="outlined" // Change the variant to outlined for multiline TextField
                      multiline // Add the multiline prop for multiline functionality
                      rows={6} // Adjust the rows prop as needed
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Quotation Content"
                      name="quotationContent"
                      value={editedQuotation.quotationContent || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="outlined" // Change the variant to outlined for multiline TextField
                      multiline // Add the multiline prop for multiline functionality
                      rows={6} // Adjust the rows prop as needed
                    />
                  </Grid>

                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Total Amount"
                      name="totalAmount"
                      value={editedQuotation.totalAmount || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Total In Word"
                      name="Total In Word"
                      value={editedQuotation.totalInWord || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Financial Year"
                      name="financialYear"
                      value={editedQuotation.financialYear || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={2} md={4}>
                    <TextField
                      label="Financial Month"
                      name="financialMonth"
                      value={editedQuotation.financialMonth || ""}
                      onChange={handleFieldChange}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}

          {tabValue === 1 && (
            <>
              <Typography variant="h6">Accomodation</Typography>
              {editedAccomodation.map((accomodation, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
                >
                  <Grid container spacing={4} style={{ marginTop: 10 }}>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Description`}
                        name="designation"
                        value={accomodation.designation}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>

                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Cost Per Resources`}
                        name="costPerResources"
                        value={accomodation.costPerResources}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Number Of Resources`}
                        name={"noOfResources"}
                        value={accomodation.noOfResources}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Number Of Month`}
                        name={"noOfMonth"}
                        value={accomodation.noOfMonth}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        label={`Total Cost`}
                        name={"totalCost"}
                        value={accomodation.totalCost}
                        onChange={(e) => handleAccomodationChange(e, index)}
                        fullWidth
                        variant="standard"
                      />
                      <Paper
                        elevation={1}
                        style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleDeleteAccomodation(index)}
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          Delete
                        </Button>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              <Paper
                elevation={2}
                style={{ padding: 5, marginBottom: 5, marginTop: 5 }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddAccomodation}
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  Add Accomodation Persons
                </Button>
              </Paper>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} disabled={!isDataChangedEdit}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const UpdateQuotation = () => {
  const [quotationData, setQuotationData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTermQuotationNo, setSearchTermQuotationNo] = useState("");
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchQuotationData = () => {
      setLoading(true); // Set loading to true when fetching data
      myAxiosCommonHost
        .get("gst/quotation/getQuotationList")
        .then((response) => {
          setQuotationData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching client data:", error);
          setError(error); // Set the error state
          setLoading(false);
        });
    };

    fetchQuotationData();
  }, []);

  const handleUpdateQuotation = (updatedQuotation) => {
    setIsUpdating(true); // Show update overlay
    myAxiosCommonHost
      .put("gst/quotation/updateQuotation", updatedQuotation)
      .then((response) => {
        console.log("Quotation Data Updated Successfully.");
        fetchQuotationData();
        setIsUpdating(false); // Hide update overlay after update
      })
      .catch((error) => {
        console.error("Error updating Quotation data: ", error);
        setIsUpdating(false); // Hide update overlay on error
      });
  };
  const handleDeleteQuotation = (quotationNo) => {
    // Delete the Quotation with the given QuotationCode
    if (window.confirm("Are You Sure You Want To Delete This Quotation?")) {
      setIsDeleting(true); // Show deletion overlay
      const encodedQuotationNo = encodeURIComponent(quotationNo);
      myAxiosCommonHost
        .delete(
          `gst/quotation/deleteQuotation?quotationNo=${encodedQuotationNo}`
        )
        .then((response) => {
          console.log("Quotation deleted successfully.");
          fetchQuotationData();
          setIsDeleting(false); // Hide deletion overlay after deletion
        })
        .catch((error) => {
          console.error("Error Deleting Quotation: ", error);
          setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };

  const fetchQuotationData = () => {
    setLoading(true); // Set loading to true when fetching data
    myAxiosCommonHost
      .get("gst/quotation/getQuotationList")
      .then((response) => {
        setQuotationData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Quotation data:", error);
        setLoading(false);
      });
  };

  const exportToExcel = () => {
    const dataToExport = quotationData.map((client) => ({
      "Client Code": client.clientCode,
      "Client Name": client.clientName,
      "Client Type": client.clientType,
      "Client Address": {
        v: client.clientAddress,
        t: "s",
        s: {
          alignment: { wrapText: true, vertical: "top", horizontal: "left" },
        },
      }, // Left align and wrap text
      "Client RegNumber": {
        v: client.clientRegNumber,
        t: "s",
        s: { alignment: { horizontal: "left" } },
      }, // Left align client reg number
      "Client Website": client.clientWebsite,
      "Client ContactNumber": {
        v: client.clientContactNumber,
        t: "s",
        s: { alignment: { horizontal: "left" } },
      }, // Left align client contact number
      "Billing Period": client.billingPeriod,
      "GST State": {
        v: client.gstdetailsUI.state,
        t: "s",
        s: { alignment: { horizontal: "left" } },
      }, // Left align GST state
      "State Code": {
        v: client.gstdetailsUI.stateCode,
        t: "s",
        s: { alignment: { horizontal: "left" } },
      }, // Left align state code
      "PAN Number": client.gstdetailsUI.panNumber,
      GSTIN: client.gstdetailsUI.gstin,
      "HSN/SAC": client.gstdetailsUI.hsnsac,
      "Start Date": client.contractdateUI.startDate,
      "End Date": client.contractdateUI.endDate,
      MSA: client.contractdateUI.msa,
      NDA: client.contractdateUI.nda,
      //'Contact Persons': client.accomodationUIList ? client.accomodationUIList.map(cp => `${cp.accomodationName}, ${cp.designation}, ${cp.emailID}, ${cp.contactNumber}`).join(';') : 'N/A'
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

    // Adjust column width for better visibility
    const wscols = [
      { wch: 15 }, // Client Code
      { wch: 30 }, // Client Name
      { wch: 15 }, // Client Type
      { wch: 95 }, // Client Address
      { wch: 15 }, // Client RegNumber
      { wch: 20 }, // Client Website
      { wch: 20 }, // Client ContactNumber
      { wch: 15 }, // Billing Period
      { wch: 15 }, // GST State
      { wch: 15 }, // State Code
      { wch: 15 }, // PAN Number
      { wch: 25 }, // GSTIN
      { wch: 15 }, // HSN/SAC
      { wch: 15 }, // Start Date
      { wch: 15 }, // End Date
      { wch: 15 }, // MSA
      { wch: 15 }, // NDA
    ];

    worksheet["!cols"] = wscols;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_data.xlsx"
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredQuotationData = quotationData.filter(
    (quotation) =>
      quotation.clientNameAndCode
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    //   &&
    // quotation.quotationNo
    //   .toLowerCase()
    //   .includes(searchTermQuotationNo.toLowerCase())
  );

  return (
    <div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Quotation is Deleting...</Typography>
          </div>
        </div>
      )}
      {isUpdating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Quotation is Updating...</Typography>
          </div>
        </div>
      )}
      <TextField
        label="Search by Client Name"
        variant="standard"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <TextField
        label="Search by Quotation Number"
        variant="standard"
        value={searchTermQuotationNo}
        onChange={(e) => setSearchTermQuotationNo(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Link to="/generateQuotationPage">
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
          >
            Generate Quotation
          </Button>
        </Link>
      </Paper>
      <Button
        variant="contained"
        color="secondary"
        onClick={exportToExcel}
        style={{ marginLeft: "10px" }}
      >
        Export to Excel
      </Button>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Quotation List
          </Typography>
        </div>
      </Paper>

      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {/* {loading ? (
            <p>Loading...</p>
          ) : ( */}
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Select Company</TableCell>
                    <TableCell>Quotation Ref Number</TableCell>
                    <TableCell>Quotation Number</TableCell>
                    <TableCell>Client Name And Code</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotationData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Quotation found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredQuotationData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((quotation) => (
                        <Row
                          key={quotation.quotationNo}
                          row={quotation}
                          onDelete={handleDeleteQuotation}
                          onUpdate={handleUpdateQuotation}
                        />
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={filteredQuotationData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage} // Make sure this line is correct
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default UpdateQuotation;
