import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  TablePagination,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Edit as EditIcon,
  Search,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";

const Row = ({ row, onDelete, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editedCompany, setEditedCompany] = useState({ ...row });

  const [tabValue, setTabValue] = useState(0);
  const [isDataChangedEdit, setIsDataChangedEdit] = useState(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    onDelete(row.companyName);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedCompany((prevCompany) => ({ ...prevCompany, [name]: value }));
    setIsDataChangedEdit(true);
  };

  const handleSave = () => {
    onUpdate(editedCompany);
    setDialogOpen(false);
  };
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.companyName}</TableCell>
        <TableCell>{row.contactNo}</TableCell>
        <TableCell>{row.pan}</TableCell>
        <TableCell>{row.gstin}</TableCell>
        <TableCell>{row.website}</TableCell>
        <TableCell>{row.eMail}</TableCell>
        <TableCell>
          <Button onClick={handleEdit} color="primary" variant="contained">
            Update
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            style={{
              marginLeft: "8px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <Box style={{ padding: 20 }}>
                <Typography variant="h6">Company Details</Typography>
                {row ? (
                  <div>
                    <TableRow
                      style={{
                        backgroundColor: "darkcyan",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      <TableCell>Company Name</TableCell>
                      <TableCell>Company Address</TableCell>
                      <TableCell>Website</TableCell>
                      <TableCell>Email </TableCell>
                      <TableCell>Company Work</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>GSTIN</TableCell>
                      <TableCell>Pan </TableCell>
                      <TableCell>CIN</TableCell>
                      <TableCell>IEC </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.companyAddress}</TableCell>
                      <TableCell>{row.website}</TableCell>
                      <TableCell>{row.eMail}</TableCell>
                      <TableCell>{row.companyWork}</TableCell>
                      <TableCell>{row.contactNo}</TableCell>
                      <TableCell>{row.gstin}</TableCell>
                      <TableCell>{row.pan}</TableCell>
                      <TableCell>{row.cin}</TableCell>
                      <TableCell>{row.iec}</TableCell>
                    </TableRow>
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No Company Details found
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Edit Company Information</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Company Information</Typography>
          <Paper
            elevation={3}
            style={{ padding: 10, marginBottom: 10, marginTop: 10 }}
          >
            <Grid container spacing={3} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Company Name"
                  name="companyName"
                  value={editedCompany.companyName}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Company Address"
                  name="companyAddress"
                  value={editedCompany.companyAddress}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="WebSite"
                  name="website"
                  value={editedCompany.website}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Email"
                  name="eMail"
                  value={editedCompany.eMail}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Company Work"
                  name="companyWork"
                  value={editedCompany.companyWork}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Contact Number"
                  name="contactNo"
                  value={editedCompany.contactNo}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="GSTIN"
                  name="gstin"
                  value={editedCompany.gstin}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="PAN"
                  name="pan"
                  value={editedCompany.pan}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="CIN"
                  name="cin"
                  value={editedCompany.cin}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="IEC"
                  name="iec"
                  value={editedCompany.iec}
                  onChange={handleFieldChange}
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setDialogOpen(false)}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isDataChangedEdit}
            style={{
              backgroundColor: isDataChangedEdit ? "green" : "lightgreen", // Change color based on isDataChangedEdit state
              color: "white",
            }}
          >
            UPDATE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const UpdateCompanyProfile = () => {
  const [companyData, setCompanyData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDeleting, setIsDeleting] = useState(false); // State to control deletion overlay
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchCompanyData = () => {
      setLoading(true);
      myAxiosCommonHost
        .get("gst/profile/getCompanyProfileUIList")
        .then((response) => {
          setCompanyData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching bank data:", error);
          setError(error);
          setLoading(false);
        });
    };

    fetchCompanyData();
  }, []);

  const handleUpdateCompanyProfile = (updatedCompany) => {
    setIsUpdating(true); // Show update overlay
    myAxiosCommonHost
      .put("gst/profile/updateCompanyProfile", updatedCompany)
      .then((response) => {
        console.log("Bank Data Updated Successfully.");
        fetchCompanyData();
        setIsUpdating(false); // Hide update overlay after update
      })
      .catch((error) => {
        console.error("Error Updating Profile Data: ", error);
        setIsUpdating(false); // Hide update overlay after update
      });
  };

  const handleDeleteCompany = (companyName) => {
    if (window.confirm("Are You Sure You Want To Delete This Bank?")) {
      setIsDeleting(true); // Show deletion overlay
      const encodedcompanyName = encodeURIComponent(companyName);
      myAxiosCommonHost
        .delete(
          `gst/profile/deleteCompanyName?companyName=${encodedcompanyName}`
        )
        .then((response) => {
          console.log("Company Deleted Successfully.");
          fetchCompanyData();
          setIsDeleting(false); // Hide deletion overlay on error
        })
        .catch((error) => {
          console.error("Error Deleting Profile: ", error);
          alert("Error Deleting Profile.");
          setIsDeleting(false); // Hide deletion overlay on error
        });
    }
  };

  const fetchCompanyData = () => {
    myAxiosCommonHost
      .get("gst/profile/getCompanyProfileUIList")
      .then((response) => {
        setCompanyData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error Fetching Company Profile data:", error);
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredCompany = companyData.filter(
    (companyData) =>
      companyData.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    //  &&
    // companyData.clientCode
    //     .toLowerCase()
    //     .includes(searchTermClientCode.toLowerCase())
  );
  return (
    <div>
      {isDeleting && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Company Profile is Deleting...</Typography>
          </div>
        </div>
      )}
      {isUpdating && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", color: "white" }}>
            <CircularProgress style={{ marginBottom: "10px" }} />
            <Typography variant="h6">Company Profile is Updating...</Typography>
          </div>
        </div>
      )}
      <TextField
        label="Search by Company Name"
        variant="standard"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <Link to="/registerCompanyProfile">
        <Button variant="contained" color="primary" style={{ margin: "10px" }}>
          Add Company Profile
        </Button>
      </Link>
      {/* <Button
        variant="contained"
        color="secondary"
        onClick={exportToExcel}
        style={{ margin: "10px" }}
      >
        Export to Excel
      </Button> */}
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          marginTop: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Company List
          </Typography>
        </div>
      </Paper>
      {error ? (
        <p>Error fetching data. Please try again.</p>
      ) : (
        <TableContainer component={Paper} style={{ height: "20%" }}>
          {/* {loading ? (
            <p>Loading...</p>
          ) : ( */}
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell />
                    <TableCell>Company Name</TableCell>
                    <TableCell>Contact Number</TableCell>
                    <TableCell>PAN </TableCell>
                    <TableCell>GST IN</TableCell>
                    <TableCell>Website </TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companyData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Company Profile found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredCompany
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      // .filter((company) =>
                      //   company.companyName
                      //     .toLowerCase()
                      //     .includes(searchTerm.toLowerCase())
                      // )
                      .map((company) => (
                        <Row
                          key={company.companyName}
                          row={company}
                          onDelete={handleDeleteCompany}
                          onUpdate={handleUpdateCompanyProfile}
                        />
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={filteredCompany.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      )}
    </div>
  );
};
export default UpdateCompanyProfile;
