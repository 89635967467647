// import React, { useEffect, useState } from "react";
// import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
// import { CardContent } from "@material-ui/core";
// import {
//   Card,
//   Paper,
//   Grid,
//   TextField,
//   Table,
//   TableContainer,
//   TableHead,
//   TableBody,
//   TableRow,
//   TableCell,
//   CircularProgress,
//   Typography,
//   TablePagination,
//   Button,
// } from "@material-ui/core";
// import { Autocomplete } from "@mui/material";

// const ProjectPOSummary = () => {
//   const [clientNameAndCode, setClientNameAndCode] = useState("");
//   const [clientNameOptions, setClientNameOptions] = useState("");
//   const [projectSummary, setProjectSummary] = useState("");
//   const [isSummaryGet, setIsSummaryGet] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [getDataTrigger, setGetDataTrigger] = useState(false); // State to trigger data fetching
//   useEffect(() => {
//     myAxiosCommonHost
//       .get("gst/projectpo/getProjectPoClientList")
//       .then((response) => {
//         setClientNameOptions(response.data);
//       })
//       .catch((error) => {
//         console.error("Error Fetching EmployeeName:", error);
//       });
//   }, []);
//   //   useEffect(() => {
//   //     setIsSummaryGet(true);
//   //     // Fetch Project Summary  based on selected clientNameAndCode

//   //     if (clientNameAndCode) {
//   //       const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
//   //       myAxiosCommonHost
//   //         .get(
//   //           `gst/projectpo/getClientProjectPoSummary?clientNameAndCode=${encodedClientNameAndCode}`
//   //         )
//   //         .then((response) => {
//   //           setProjectSummary(response.data);
//   //         })
//   //         .catch((error) => {
//   //           console.error("Error fetching collection history:", error);
//   //         });
//   //       setIsSummaryGet(false);
//   //     }
//   //   }, [clientNameAndCode]);
//   const handleEmployeeNameAndCodeChange = (event) => {
//     setClientNameAndCode(event.target.value);
//   };
//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };
// //   useEffect(() => {
// //     setIsSummaryGet(true);
// //     // Fetch Project Summary based on selected clientNameAndCode
// //     if (clientNameAndCode && getDataTrigger) {
// //       const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
// //       myAxiosCommonHost
// //         .get(
// //           `gst/projectpo/getClientProjectPoSummary?clientNameAndCode=${encodedClientNameAndCode}`
// //         )
// //         .then((response) => {
// //           setProjectSummary(response.data);
// //         })
// //         .catch((error) => {
// //           console.error("Error fetching collection history:", error);
// //         });
// //       setIsSummaryGet(false);
// //       setGetDataTrigger(false); // Reset the trigger after fetching data
// //     }
// //   }, [clientNameAndCode, getDataTrigger]); // Add getDataTrigger to the dependency array
//   useEffect(() => {
//     setIsSummaryGet(true);
//     // Fetch Project Summary based on selected clientNameAndCode
//     if (clientNameAndCode && getDataTrigger) {
//       const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
//       myAxiosCommonHost
//         .get(
//           `gst/projectpo/getClientProjectPoSummary?clientNameAndCode=${encodedClientNameAndCode}`
//         )
//         .then((response) => {
//           setProjectSummary(response.data);
//         })
//         .catch((error) => {
//           console.error('Error fetching collection history:', error);
//         });
//       setIsSummaryGet(false);
//       setGetDataTrigger(false); // Reset the trigger after fetching data
//     } else {
//       // If clientNameAndCode changes but getDataTrigger is false, reset table data
//       setProjectSummary([]);
//     }
//   }, [clientNameAndCode, getDataTrigger]); // Add getDataTrigger to the dependency array

//   const handleGetData = () => {
//     // Set getDataTrigger to true when the button is clicked
//     setGetDataTrigger(true);
//   };

//   return (
//     <div>
//       <Grid>
//         <Grid>
//           <Card>
//             <CardContent>
//               <Paper
//                 elevation={3}
//                 style={{ padding: "20px", marginBottom: "20px" }}
//               >
//                 <Paper
//                   elevation={3}
//                   style={{
//                     padding: "10px",
//                     marginBottom: "10px",
//                     backgroundColor: "#2196f3",
//                   }}
//                 >
//                   <Typography
//                     variant="h5"
//                     gutterBottom
//                     style={{
//                       marginBottom: "15px",
//                       color: "white",
//                       textAlign: "center",
//                     }}
//                   >
//                     Project Summary
//                   </Typography>
//                 </Paper>
//                 <Grid container spacing={2}>
//                   <Grid item xs={6}>
//                     <Autocomplete
//                       options={clientNameOptions}
//                       getOptionLabel={(option) => option}
//                       value={clientNameAndCode}
//                       onChange={(event, newValue) =>
//                         setClientNameAndCode(newValue)
//                       }
//                       renderInput={(params) => (
//                         <TextField
//                           {...params}
//                           label="Client Name"
//                           variant="standard"
//                           margin="normal"
//                           fullWidth
//                           required
//                         />
//                       )}
//                     />
//                   </Grid>
//                   <Grid item xs={6}>
//                     {/* Button to get data */}
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleGetData}
//                     >
//                       Get Data
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </Paper>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//       {projectSummary.length > 0 && (
//         <Grid>
//           <Paper
//             elevation={3}
//             style={{ padding: "20px", marginBottom: "20px" }}
//           >
//             <TableContainer component={Paper} style={{ marginTop: "20px" }}>
//               <Table>
//                 <TableHead>
//                   <TableRow
//                     style={{
//                       backgroundColor: "darkcyan",
//                       fontWeight: "bold",
//                       color: "darkblue",
//                     }}
//                   >
//                     <TableCell>SR.No</TableCell>
//                     <TableCell>Project Name</TableCell>
//                     <TableCell>Project Description</TableCell>
//                     <TableCell>Is Active</TableCell>
//                     <TableCell>Flag Is Active Value</TableCell>
//                     <TableCell>Project Po No</TableCell>
//                     <TableCell>Project Po Amount</TableCell>
//                     <TableCell>Project Po Date</TableCell>
//                     <TableCell>Project Po StartDate</TableCell>
//                     <TableCell>Project Po EndDate</TableCell>
//                     <TableCell>ClientCode</TableCell>
//                     <TableCell>ClientName</TableCell>
//                     <TableCell>Project Unique Code</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {projectSummary
//                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                     .map((row) => (
//                       <TableRow key={row.id}>
//                         <TableCell>{row.id + 1}</TableCell>
//                         <TableCell>{row.projectName}</TableCell>
//                         <TableCell>{row.projectDescription}</TableCell>
//                         <TableCell>{row.isActive}</TableCell>
//                         <TableCell>{row.flagIsActiveValue}</TableCell>
//                         <TableCell>{row.projectPoNo}</TableCell>
//                         <TableCell>{row.projectPoAmount}</TableCell>
//                         <TableCell>
//                           {row.projectPoDate
//                             ? row.projectPoDate.split("-").reverse().join("-")
//                             : "N/A"}
//                         </TableCell>
//                         <TableCell>
//                           {row.projectPoStartDate
//                             ? row.projectPoStartDate
//                                 .split("-")
//                                 .reverse()
//                                 .join("-")
//                             : "N/A"}
//                         </TableCell>
//                         <TableCell>
//                           {row.projectPoEndDate
//                             ? row.projectPoEndDate
//                                 .split("-")
//                                 .reverse()
//                                 .join("-")
//                             : "N/A"}
//                         </TableCell>
//                         <TableCell>{row.clientCode}</TableCell>
//                         <TableCell>{row.clientName}</TableCell>
//                         <TableCell>{row.gsProjectUniqueCode}</TableCell>
//                       </TableRow>
//                     ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             <TablePagination
//               rowsPerPageOptions={[2, 4, 16]}
//               component="div"
//               count={projectSummary.length}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               onChangePage={handleChangePage}
//               onChangeRowsPerPage={handleChangeRowsPerPage}
//             />
//           </Paper>
//         </Grid>
//       )}
//     </div>
//   );
// };

// export default ProjectPOSummary;

import React, { useEffect, useState } from "react";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import { CardContent } from "@material-ui/core";
import {
  Card,
  Paper,
  Grid,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  TablePagination,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";

const ProjectPOSummary = () => {
  const [clientNameAndCode, setClientNameAndCode] = useState("");
  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [projectSummary, setProjectSummary] = useState([]);
  const [isSummaryGet, setIsSummaryGet] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [getDataTrigger, setGetDataTrigger] = useState(false); // State to trigger data fetching
  const [projectNameOptions, setProjectNameOptions] = useState([]); // State for project name options
  const [selectedProjectName, setSelectedProjectName] = useState(""); // State for selected project name
  const [projectNameSummary, setProjectNameSummary] = useState([]); // State for project name summary data
  const [getProjectNameDataTrigger, setGetProjectNameDataTrigger] =
    useState(false); // State for project name summary data

  useEffect(() => {
    myAxiosCommonHost
      .get("gst/projectpo/getProjectPoClientList")
      .then((response) => {
        setClientNameOptions(response.data);
      })
      .catch((error) => {
        console.error("Error Fetching EmployeeName:", error);
      });
    // Fetch project name options
    myAxiosCommonHost
      .get("/gst/projectpo/getProjectNameList")
      .then((response) => {
        setProjectNameOptions(response.data);
      })
      .catch((error) => {
        console.error("Error Fetching Project Names:", error);
      });
  }, []);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    setIsSummaryGet(true);
    // Fetch Project Summary based on selected clientNameAndCode
    if (clientNameAndCode && getDataTrigger) {
      const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
      myAxiosCommonHost
        .get(
          `gst/projectpo/getClientProjectPoSummary?clientNameAndCode=${encodedClientNameAndCode}`
        )
        .then((response) => {
          setProjectSummary(response.data);
        })
        .catch((error) => {
          console.error("Error fetching collection history:", error);
        });
      setIsSummaryGet(false);
      setGetDataTrigger(false); // Reset the trigger after fetching data
    } else {
      // If clientNameAndCode changes but getDataTrigger is false, reset table data
      setProjectSummary([]);
    }
  }, [clientNameAndCode, getDataTrigger]); // Add getDataTrigger to the dependency array
  useEffect(() => {
    setIsSummaryGet(true);
    // Fetch Project Summary based on selected clientNameAndCode and projectName
    if (clientNameAndCode && getProjectNameDataTrigger && selectedProjectName) {
      const encodedClientNameAndCode = encodeURIComponent(clientNameAndCode);
      const encodedProjectName = encodeURIComponent(selectedProjectName);
      myAxiosCommonHost
        .get(
          `gst/projectpo/getNameProjectPoSummary?clientNameAndCode=${encodedClientNameAndCode}&projectName=${encodedProjectName}`
        )
        .then((response) => {
          setProjectNameSummary(response.data.projectNameActivePoSummaryList);
        })
        .catch((error) => {
          console.error("Error fetching project summary:", error);
        });
      setIsSummaryGet(false);
      setGetProjectNameDataTrigger(false); // Reset the trigger after fetching data
    } else {
      // If clientNameAndCode or projectName changes but getProjectNameDataTrigger is false, reset table data
      setProjectSummary([]);
      setProjectNameSummary([]);
    }
  }, [clientNameAndCode, getProjectNameDataTrigger, selectedProjectName]); // Add selectedProjectName to the dependency array

  const handleGetData = () => {
    // Set getDataTrigger to true when the button is clicked
    setGetDataTrigger(true);
  };
  const handleGetProjectNameData = () => {
    // Set getDataTrigger to true when the button is clicked
    setGetProjectNameDataTrigger(true);
  };

  return (
    <div>
      <Grid>
        <Grid>
          <Card>
            <CardContent>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#2196f3",
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginBottom: "15px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Project Summary
                  </Typography>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={clientNameOptions}
                      getOptionLabel={(option) => option}
                      value={clientNameAndCode}
                      onChange={(event, newValue) =>
                        setClientNameAndCode(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {/* Autocomplete for projectName */}
                    <Autocomplete
                      options={projectNameOptions}
                      getOptionLabel={(option) => option}
                      value={selectedProjectName}
                      onChange={(event, newValue) =>
                        setSelectedProjectName(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGetData}
                    >
                      View Client Wise Summary
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGetProjectNameData}
                    >
                      View Project Wise Summary
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {projectSummary.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>SR.No</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Project Description</TableCell>
                    <TableCell>Is Active</TableCell>
                    <TableCell>Flag Is Active Value</TableCell>
                    <TableCell>Project Po No</TableCell>
                    <TableCell>Project Po Amount</TableCell>
                    <TableCell>Project Po Date</TableCell>
                    <TableCell>Project Po StartDate</TableCell>
                    <TableCell>Project Po EndDate</TableCell>
                    <TableCell>ClientCode</TableCell>
                    <TableCell>ClientName</TableCell>
                    <TableCell>Project Unique Code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectSummary
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.projectName}</TableCell>
                        <TableCell>{row.projectDescription}</TableCell>
                        <TableCell>
                          {row.isActive === "true" ? "true" : "false"}
                        </TableCell>
                        <TableCell>{row.flagIsActiveValue}</TableCell>
                        <TableCell>{row.projectPoNo}</TableCell>
                        <TableCell>{row.projectPoAmount}</TableCell>
                        <TableCell>
                          {row.projectPoDate
                            ? row.projectPoDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.projectPoStartDate
                            ? row.projectPoStartDate
                                .split("-")
                                .reverse()
                                .join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.projectPoEndDate
                            ? row.projectPoEndDate
                                .split("-")
                                .reverse()
                                .join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.clientCode}</TableCell>
                        <TableCell>{row.clientName}</TableCell>
                        <TableCell>{row.gsProjectUniqueCode}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 4, 16]}
              component="div"
              count={projectSummary.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      )}
      {projectNameSummary.length > 0 && (
        <Grid>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>SR.No</TableCell>
                    <TableCell>IsActive</TableCell>
                    <TableCell>Project Po No</TableCell>
                    <TableCell>Project Po Amount</TableCell>
                    <TableCell>Project Po Date</TableCell>
                    <TableCell>Project Po Start Date</TableCell>
                    <TableCell>Project Po End Date</TableCell>
                    <TableCell>Flag IsActive Value</TableCell>
                    <TableCell>GS Project Unique Code</TableCell>
                    <TableCell>Client Code</TableCell>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Project Description</TableCell>
                    <TableCell>Project Hour or Day</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectNameSummary.map((row, index) => (
                    <TableRow key={row.gsProjectUniqueCode}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {row.isActive === "true" ? "true" : "false"}
                      </TableCell>
                      <TableCell>{row.projectPoNo}</TableCell>
                      <TableCell>{row.projectPoAmount}</TableCell>
                      <TableCell>{row.projectPoDate}</TableCell>
                      <TableCell>{row.projectPoStartDate}</TableCell>
                      <TableCell>{row.projectPoEndDate}</TableCell>
                      <TableCell>{row.flagIsActiveValue}</TableCell>
                      <TableCell>{row.gsProjectUniqueCode}</TableCell>
                      <TableCell>{row.clientCode}</TableCell>
                      <TableCell>{row.clientName}</TableCell>
                      <TableCell>{row.projectName}</TableCell>
                      <TableCell>{row.projectDescription}</TableCell>
                      <TableCell>{row.projectHourOrDay}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
    </div>
  );
};

export default ProjectPOSummary;
