import Navigation from "../Component/NavigationBar/Navigation";

const Headers = ({ title = "Welcome to my website", children }) => {
  return (
    <div>
      < Navigation/>
      {children}
      
    </div>
  );
};
export default Headers;
