import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Typography,
  CircularProgress,
  TableContainer,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";

const OutStandingPaidHistory = () => {
  const [financialYear, setFinancialYear] = useState("");
  const [clientNameAndCode, setClientName] = useState("");
  const [invoiceRefNo, setInvoiceRefNo] = useState("");
  const [financialYears, setFinancialYears] = useState([]);
  const [clientNames, setClientNames] = useState([]);
  const [invoiceRefNos, setInvoiceRefNos] = useState([]);
  const [collectionHistory, setCollectionHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isDeleting, setIsDeleting] = useState(false);
  

  useEffect(() => {
    // Fetch financial years
    myAxiosCommonHost
      .get("gst/invoice/getFiscalYear")
      .then((response) => {
        setFinancialYears(response.data);
      })
      .catch((error) => {
        console.error("Error fetching financial years:", error);
      });

    // Fetch client names
    myAxiosCommonHost
      .get("gst/outstanding/getOutstandingClientList")
      .then((response) => {
        setClientNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client names:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch invoice reference numbers based on selected client name and financial year
    if (clientNameAndCode && financialYear) {
      const encodedclientNameAndCode = encodeURIComponent(clientNameAndCode);
      const encodedFinancialYear = encodeURIComponent(financialYear);
      myAxiosCommonHost
        .get(
          `gst/outstanding/getInvoiceRefOutStandingPaidList?clientNameAndCode=${encodedclientNameAndCode}&finacialYear=${encodedFinancialYear}`
        )
        .then((response) => {
          setInvoiceRefNos(response.data);
        })
        .catch((error) => {
          console.error("Error fetching invoice reference numbers:", error);
        });
    }
  }, [clientNameAndCode, financialYear]);

  useEffect(() => {
    // Fetch collection history based on selected invoiceRefNo
    if (invoiceRefNo) {
      myAxiosCommonHost
        .get(
          `gst/outstanding/getOutstandingCollectionPaidHistoryList?invoiceRefNo=${invoiceRefNo}`
        )
        .then((response) => {
          setCollectionHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching collection history:", error);
        });
    }
  }, [invoiceRefNo]);

  const handleFinancialYearChange = (event) => {
    setFinancialYear(event.target.value);
  };

  const handleClientNameChange = (event) => {
    setClientName(event.target.value);
  };

  const handleInvoiceRefNoChange = (event) => {
    setInvoiceRefNo(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteClick = async (invoiceRefNo) => {
    const confirmDelete = window.confirm(
      "Are You Sure You Want to Delete This Record?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await handleDeleteConfirmed(invoiceRefNo);
      setIsDeleting(false);
    }
  };

  const handleDeleteConfirmed = async (invoiceRefNo) => {
    const encodedinvoiceRefNo = encodeURIComponent(invoiceRefNo);
    const encodedclientNameAndCode = encodeURIComponent(clientNameAndCode);
    const encodedFinancialYear = encodeURIComponent(financialYear);
    try {
      const response = await myAxiosCommonHost.delete(
        `gst/outstanding/deleteCollectionHistory?invoiceRefNo=${encodedinvoiceRefNo}`
      );

      if (response.status === 200) {
        const updatedDataResponse = await myAxiosCommonHost.get(
          `gst/outstanding/getInvoiceRefOutStandingPaidList?clientNameAndCode=${encodedclientNameAndCode}&finacialYear=${encodedFinancialYear}`
        );
        alert("Collection History deleted successfully!");
        setCollectionHistory(updatedDataResponse.data);
      } else {
        alert("Failed to delete invoice. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
      alert("An error occurred while deleting the invoice.");
    }
  };

  const handleExportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(collectionHistory);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Collection History");
    XLSX.writeFile(wb, "collection_history.xlsx");
  };


  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={8}>
        <Card style={{ marginTop: "20px", maxWidth: "lg" }}>
          <CardContent>
            {isDeleting && (
              <div className="blur-effect">
                <CircularProgress color="primary" />
                <div className="loading-message">
                  Collection History is Deleting...
                </div>
              </div>
            )}
            <Paper
              elevation={3}
              style={{
                padding: 20,
                marginBottom: 20,
                backgroundColor: "#2196f3",
                color: "white",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ marginBottom: "15px", color: "darkblue" }}
                >
                  Paid Invoice History
                </Typography>
              </div>

             

            </Paper>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  options={financialYears}
                  getOptionLabel={(option) => option}
                  value={financialYear}
                  onChange={(event, newValue) => setFinancialYear(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Financial Year"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={clientNames}
                  getOptionLabel={(option) => option}
                  value={clientNameAndCode}
                  onChange={(event, newValue) => setClientName(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name And Code"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={invoiceRefNos}
                  getOptionLabel={(option) => option}
                  value={invoiceRefNo}
                  onChange={(event, newValue) => setInvoiceRefNo(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Invoice Ref. Number"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid>
              <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleExportToExcel}
                  >
                    Excel
                  </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {/* {collectionHistory.length > 0 && ( */}
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "darkcyan",
                    fontWeight: "bold",
                    color: "darkblue",
                  }}
                >
                  <TableCell>Action</TableCell>
                  <TableCell>Invoice Ref No</TableCell>
                  <TableCell>Financial Year</TableCell>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Outstanding Amount</TableCell>
                  <TableCell>Paid Amount</TableCell>
                  <TableCell>Unpaid Amount</TableCell>
                  <TableCell>TDS Amount</TableCell>
                  <TableCell>Pay Mode</TableCell>
                  <TableCell>Pay Date</TableCell>
                  <TableCell>Collected By</TableCell>
                  <TableCell>Collected Name</TableCell>
                  <TableCell>Chq Number</TableCell>
                  <TableCell>Chq Date</TableCell>
                  <TableCell>Bank Branch</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Updated Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collectionHistory.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Paid History found
                    </TableCell>
                  </TableRow>
                ) : (
                  collectionHistory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDeleteClick(row.invoiceRefNo)}
                          >
                            Delete Collection History
                          </Button>
                        </TableCell>
                        {/* <TableCell>{row.id}</TableCell> */}
                        <TableCell>{row.invoiceRefNo}</TableCell>
                        {/* <TableCell>{row.totalStandingAmount}</TableCell> */}
                        <TableCell>{row.financialYear}</TableCell>
                        <TableCell>{row.clientName}</TableCell>
                        <TableCell>{row.outStandingAmount}</TableCell>
                        <TableCell>{row.paidAmount}</TableCell>
                        <TableCell>{row.unPaidAmount}</TableCell>
                        <TableCell>{row.tdsAmount}</TableCell>
                        <TableCell>{row.payMode}</TableCell>
                        <TableCell>
                          {row.payDate
                            ? row.payDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.collectedBy}</TableCell>
                        <TableCell>{row.collectedName}</TableCell>
                        <TableCell>{row.chqNumber}</TableCell>
                        <TableCell>
                          {row.chqDate
                            ? row.chqDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.bankBranch}</TableCell>
                        <TableCell>
                          {row.createdDate
                            ? row.createdDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {row.updatedDate
                            ? row.updatedDate.split("-").reverse().join("-")
                            : "N/A"}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[2, 4, 16]}
              component="div"
              count={collectionHistory.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </Grid>
      {/* )} */}
    </Grid>
  );
};

export default OutStandingPaidHistory;
