import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Paper,
  Grid,
  Card,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableHead,
  TableCell,
  Typography,
  Link,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@mui/material";
import axios from "axios";
import { Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
import "./ProjectPo.css";

const GenerateProjectPO = () => {
  const navigate = useNavigate();
  const [projectDetails, setProjectDetails] = useState({
    projectPoNo: "",
    gsProjectUniqueCode: "",
    clientNameAndCode: "",
    projectName: "",
    kindAttention: "",
  });
  const [projectPoDetailsUIList, setProjectPoDetailsUIList] = useState([]);
  const [newProject, setNewProject] = useState({
    poDate: "",
    poStartDate: "",
    poEndDate: "",
    poAmount: "",
    description: "",
    ratePerDay: "",
    ratePerhour: "",
    projectHourOrDay: "",
  });

  const [clientOptions, setClientOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  useEffect(() => {
    myAxiosCommonHost
      .get("gst/client/getClientNameList")
      .then((response) => {
        setClientOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/projectpo/getProjectPOUniqueNo"
        );
        const data = response.data;
        setProjectDetails((prevState) => ({
          ...prevState,
          projectPoNo: "",
          gsProjectUniqueCode: data,
          clientNameAndCode: "",
          projectName: "",
          kindAttention: "",
        }));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClientNameChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails((projectDetailsprevUI) => ({
      ...projectDetailsprevUI,
      [name]: value,
    }));
  };
  const handleProjectDetailsChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails((prevProjectDetails) => ({
      ...prevProjectDetails,
      [name]: value,
    }));
  };
  const handleProjectPoDetailsChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prevNewProject) => ({
      ...prevNewProject,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingOverlay(true);
      const postData = {
        ...projectDetails,
        projectPoDetailsUIList,
      };

      await myAxiosCommonHost.post("gst/projectpo/createProjectPO", postData);

      console.log(
        "Posted data in JSON format:",
        JSON.stringify(postData, null, 2)
      );
      alert("Project PO added successfully!");
      setProjectDetails({
        projectPoNo: "",
        gsProjectUniqueCode: "",
        clientNameAndCode: "",
        projectName: "",
        kindAttention: "",
      });
      setProjectPoDetailsUIList([]);
      setNewProject({});
      navigate("/updateProjectPO");
      console.log(
        "Project added successfully, fields reset, and navigated to ProjectDetailsList!"
      );
    } catch (error) {
      console.error("Error posting data:", error);
      alert("Error adding project.");
    } finally {
      setLoadingOverlay(false); // Set loading state to false
    }
  };
  const handleAddEntry = () => {
    const isDuplicateDescription = projectPoDetailsUIList.some(
      (entry) => entry.description.trim() === newProject.description.trim()
    );

    if (isDuplicateDescription) {
      alert("Duplicate entry! Cannot add the same description.");
      return;
    }

    // Check if required fields are filled for newProject
    const newProjectFilled =
      // newProject.poDate.trim() !== "" &&
      // newProject.poStartDate.trim() !== "" &&
      // newProject.poEndDate.trim() !== "" &&
      // newProject.poAmount.trim() !== "" &&
      newProject.description.trim() !== "";

    if (!newProjectFilled) {
      alert("Please fill all required fields before adding to the table.");
      return;
    }
    // Add data to the table
    setProjectPoDetailsUIList((prevProjectPoUIList) => [
      ...prevProjectPoUIList,
      newProject,
    ]);
    // Reset newProject
    setNewProject({
      poDate: "",
      poStartDate: "",
      poEndDate: "",
      poAmount: "",
      description: "",
      ratePerDay: "",
      ratePerhour: "",
      projectHourOrDay: "",
      // isActive: "",
    });
  };

  const handleDeleteRow = (index) => {
    const updatedEmployees = [...projectPoDetailsUIList];
    updatedEmployees.splice(index, 1);
    setProjectPoDetailsUIList(updatedEmployees);
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateProjectPO");
    }
  };
  const fetchData = async () => {
    try {
      const response = await myAxiosCommonHost.get(
        "gst/projectpo/getProjectPOUniqueNo"
      );
      const data = response.data;
      setProjectDetails((prevState) => ({
        ...prevState,
        projectPoNo: "",
        gsProjectUniqueCode: data,
        clientNameAndCode: "",
        projectName: "",
        kindAttention: "",
      }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const resetForm = () => {
    setProjectDetails({
      projectPoNo: "",
      gsProjectUniqueCode: "",
      clientNameAndCode: "",
      projectName: "",
      kindAttention: "",
    });
    fetchData();
    setProjectPoDetailsUIList([]);

    setNewProject({
      poDate: "",
      poStartDate: "",
      poEndDate: "",
      poAmount: "",
      description: "",
      ratePerDay: "",
      ratePerhour: "",
      projectHourOrDay: "",
    });
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: "20px" }}>
      <form onSubmit={handleSubmit}>
        <Card elevation={3} style={{ marginBottom: "20px" }}>
          <CardHeader
            title="Project Purchase Order"
            style={{
              backgroundColor: "#2196f3",
              color: "white",
              padding: "10px",
              textAlign: "center",
            }}
          />
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Project Details
                </Typography>
              </Paper>
              {loadingOverlay && (
                <div className="loading-overlay">
                  <CircularProgress size={60} />
                  <div className="loading-message">Data is saving...</div>
                </div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Project Po Number"
                    name="projectPoNo"
                    value={projectDetails.projectPoNo}
                    onChange={handleProjectDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <TextField
                      label="Project Unique Code"
                      value={projectDetails.gsProjectUniqueCode}
                      fullWidth
                      margin="dense"
                      disabled
                      variant="standard"
                      required
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={clientOptions}
                    getOptionLabel={(option) => option || ""}
                    value={projectDetails.clientNameAndCode || ""}
                    onChange={(event, newValue) => {
                      handleClientNameChange({
                        target: { name: "clientNameAndCode", value: newValue },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name And Code"
                        margin="dense"
                        variant="standard"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Project Name"
                    name="projectName"
                    value={projectDetails.projectName}
                    onChange={handleProjectDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Kind Attention"
                    name="kindAttention"
                    value={projectDetails.kindAttention}
                    onChange={handleProjectDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                  }}
                >
                  Project Purchase Order Details
                </Typography>
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    label="Description"
                    name="description"
                    value={newProject.description}
                    onChange={handleProjectPoDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="PO Amount"
                    name="poAmount"
                    value={newProject.poAmount}
                    onChange={handleProjectPoDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="PO Date"
                    name="poDate"
                    type="date"
                    InputLabelProps={{ shrink: true }} // Ensure the label doesn't overlap
                    value={newProject.poDate}
                    onChange={handleProjectPoDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Rate Per Day"
                    name="ratePerDay"
                    value={newProject.ratePerDay}
                    onChange={handleProjectPoDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Rate Per hour"
                    name="ratePerhour"
                    value={newProject.ratePerhour}
                    onChange={handleProjectPoDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="PO Start Date"
                    name="poStartDate"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={newProject.poStartDate}
                    onChange={handleProjectPoDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="PO End Date"
                    name="poEndDate"
                    type="date"
                    value={newProject.poEndDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleProjectPoDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Project Hour Or Day"
                    name="projectHourOrDay"
                    type="number"
                    value={newProject.projectHourOrDay}
                    onChange={handleProjectPoDetailsChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>Description</TableCell>
                    <TableCell>Po Amount</TableCell>
                    <TableCell>Rate Per Day</TableCell>
                    <TableCell>Rate Per Hour</TableCell>
                    <TableCell>PO Date</TableCell>
                    <TableCell>PO Start Date</TableCell>
                    <TableCell>PO End Date</TableCell>
                    <TableCell>Project Hour Or Day</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectPoDetailsUIList.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.description}</TableCell>
                      <TableCell>{entry.poAmount}</TableCell>
                      <TableCell>{entry.ratePerDay}</TableCell>
                      <TableCell>{entry.ratePerhour}</TableCell>
                      <TableCell>
                        {entry.poDate
                          ? entry.poDate.split("-").reverse().join("-")
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {entry.poStartDate
                          ? entry.poStartDate.split("-").reverse().join("-")
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {entry.poEndDate
                          ? entry.poEndDate.split("-").reverse().join("-")
                          : "N/A"}
                      </TableCell>
                      <TableCell>{entry.projectHourOrDay}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteRow(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEntry}
              style={{
                marginLeft: "20px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Add Project
            </Button>
          </Paper>
          <Paper
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: "20px", marginBottom: "20px" }}
              disabled={projectPoDetailsUIList.length === 0}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              style={{ marginBottom: "20px", marginLeft: "10px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={resetForm}
              style={{ marginLeft: "20px", marginBottom: "20px" }}
            >
              Reset
            </Button>
          </Paper>
        </Card>
      </form>
    </Container>
  );
};

export default GenerateProjectPO;
