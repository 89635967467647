import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
  Grid,
  TablePagination,
  Radio,
  Checkbox,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, IconButton } from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Logo from "../Invoice/Logo1.jpg";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
//import { myAxiosCommonHost } from "../Service/CorsHelperBaseUrl";
import * as XLSX from "xlsx";
//import "./InvoiceList.css";
import { Autocomplete } from "@mui/material";
import Swal from "sweetalert2";

const EmployeeInvoiceList = () => {
  const [invoiceHeaderDetails, setInvoiceHeaderDetails] = useState({
    selectCompany: "",
    financialYear: "",
    invoiceNo: "",
    invoiceRefNo: "",
    invoiceDate: "",
    invoiceType: "",
    clientType: "",
    paymentYear: "",
    paymentMonth: "",
    clientNameAndCode: "",
    sacHsn: "",
    uniqueNoPoNo: "",
    buyersPoNumber: "",
    poNumber: "",
    issueDate: "",
    workingDays: "",
    invoiceTitle: "",
    invoiceRemarks: "",
  });
  const [expandedRow, setExpandedRow] = useState(null);
  const [data, setData] = useState(null);
  const [editedInvoiceData, setEditedInvoiceData] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [hasChanges, setHasChanges] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermClientName, setSearchTermClientName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // State to control update overlay
  const [invoiceRemarksOptions, setInvoiceRemarksOptions] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [selectedInvoiceRefNo, setSelectedInvoiceRefNo] = useState("");
  const [passwordVerified, setPasswordVerified] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getInvoiceList?filterType=EMPLOYEEPOINVOICE"
        );
        const result = response.data;
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const handleDialogInputChange = (field, value) => {
    if (field === "invoiceTitle") {
      setEditedInvoiceData((prevDetails) => ({
        ...prevDetails,
        invoiceRemarks: "", // Reset invoiceRemarks
      }));
      // Set options for invoiceRemarks based on invoiceTitle value
      if (value === "PROFORMA INVOICE") {
        setInvoiceRemarksOptions([
          "QUOTATION UNDER PROCESS",
          "PR UNDER PROCESS",
          "PO UNDER PROCESS",
        ]);
      } else if (value === "TAX INVOICE") {
        setInvoiceRemarksOptions([
          "Invoice in submission process",
          "Invoice with GSQUARE Representative",
          "Invoice with compliance",
          "Invoice with user",
          "Invoice with account/GRN",
        ]);
      } else {
        // Clear invoiceRemarksOptions if invoiceTitle is neither "PROFORMA INVOICE" nor "TAX INVOICE"
        setInvoiceRemarksOptions([]);
      }
    }
    // Update invoiceHeaderDetails with the selected value
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setHasChanges(true); // Set the flag when there are changes
  };
  const handleProjectInputChange = (index, field, value) => {
    const updatedData = { ...editedInvoiceData };
    updatedData.invoiceEmployeePoDetailsUIList[index][field] = value;
    setEditedInvoiceData(updatedData);
    setHasChanges(true); // Set the flag when there are changes
  };

  const handleGstDetailsChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoiceGstDetailsUI: {
        ...prevData.invoiceGstDetailsUI,
        [field]: value,
      },
    }));
    setHasChanges(true); // Set the flag when there are changes
  };
  const handlePaymentModeChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      invoicePaymentModeUI: {
        ...prevData.invoicePaymentModeUI,
        [field]: value,
      },
    }));
    setHasChanges(true); // Set the flag when there are changes
  };
  const handleBankDetailsChange = (field, value) => {
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      bankUI: {
        ...prevData.bankUI,
        [field]: value,
      },
    }));
    setHasChanges(true); // Set the flag when there are changes
  };
  // const handleUpdateDialogSave = () => {
  //   console.log("Updated Invoice Data:", editedInvoiceData);
  //   console.log(JSON.stringify(editedInvoiceData, null, 2));
  //   const response = myAxiosCommonHost.put(
  //     "gst/invoice/updateInvoice?filterType=EMPLOYEEPOINVOICE",
  //     editedInvoiceData
  //   );
  //   setUpdateDialogOpen(false);
  // };
  const handleUpdateInvoiceClick = (invoiceData) => {
    setEditedInvoiceData({ ...invoiceData });
    setUpdateDialogOpen(true);
  };
  const handleUpdateDialogSave = async () => {
    setIsUpdating(true);
    try {
      console.log("Updated Invoice Data:", editedInvoiceData);
      console.log(JSON.stringify(editedInvoiceData, null, 2));
      const response = await myAxiosCommonHost.put(
        "gst/invoice/updateInvoice?filterType=EMPLOYEEPOINVOICE",
        editedInvoiceData
      );
      if (response.status === 200) {
        //alert("Invoice updated successfully!");
        const updatedDataResponse = await myAxiosCommonHost.get(
          "gst/invoice/getInvoiceList?filterType=EMPLOYEEPOINVOICE"
        );
        setData(updatedDataResponse.data);
        setUpdateDialogOpen(false);
        setHasChanges(false); // Reset the flag when changes are saved
      } else {
        alert("Failed to update invoice. Please try again.");
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
      alert("An error occurred while updating the invoice.");
    }
    setIsUpdating(false);
  };

  if (fetchError) {
    return <div>Error fetching data. Please try again later.</div>;
  }

  const handleDeleteClick = async (invoiceRefNo) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (confirmDelete) {
      setIsDeleting(true);
      await handleDeleteConfirmed(invoiceRefNo);
      setIsDeleting(false);
    }
  };
  const handleDeleteConfirmed = async (invoiceRefNo) => {
    setSelectedInvoiceRefNo(invoiceRefNo);
    setDeleteDialogOpen(true);
  };
  const handleDialogDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await myAxiosCommonHost.get(
        `gst/login/verificationForDeletionRecord?userPassword=${password}`
      );
      if (response.status === 200 && response.data) {
        setPasswordVerified(true); // Set password verification status to true
        const confirmDelete = window.confirm(
          "Password verified. Are you sure you want to delete this record?"
        );
        if (confirmDelete) {
          // Perform deletion logic here
          const deleteResponse = await myAxiosCommonHost.delete(
            `gst/invoice/deleteInvoice/invoiceRefNo?invoiceRefNo=${selectedInvoiceRefNo}`
          );

          if (deleteResponse.status === 200) {
            console.log("Invoice deleted successfully!");
            const updatedDataResponse = await myAxiosCommonHost.get(
              "gst/invoice/getInvoiceList?filterType=EMPLOYEEPOINVOICE"
            );
            if (updatedDataResponse.status === 200) {
              const updatedResult = await updatedDataResponse.data;
              setData(updatedResult);
              //  setEditedData(updatedResult);
            } else {
              console.error("Failed to fetch updated data.");
            }
            setDeleteDialogOpen(false);
            setPassword(""); // Reset password field
          } else {
            console.error("Failed to delete PO.");
            alert("Failed to delete PO. Please try again.");
          }
        }
      } else {
        setPasswordVerified(false); // Set password verification status to false
        console.error("Failed to verify password.");
        alert("Incorrect password. Deletion canceled.");
      }
    } catch (error) {
      console.error("Error deleting Invoice:", error);
      alert("An error occurred while deleting the Invoice.");
    }
    setIsDeleting(false);
  };
  // const handleDeleteConfirm = async () => {
  //   setIsDeleting(true);
  //   try {
  //     const response = await myAxiosCommonHost.get(
  //       `gst/login/verificationForDeletionRecord?userPassword=${password}`
  //     );
  //     if (response.status === 200 && response.data) {
  //       const confirmDelete = window.confirm(
  //         "Password verified. Are You Want to Delete This Record..."
  //       );
  //       console.log("Password verified. Deleting record...");
  //       if (confirmDelete) {
  //         // Perform deletion logic here
  //         const deleteResponse = await myAxiosCommonHost.delete(
  //         //  `gst/projectpo/deleteProjectPO/uniqueProjectAndPoNo?uniqueProjectAndPoNo=${selectedInvoiceRefNo}`
  //           `gst/invoice/deleteInvoice/invoiceRefNo?invoiceRefNo=${selectedInvoiceRefNo}`
  //         );

  //         if (deleteResponse.status === 200) {
  //         //  console.log("PO deleted successfully!");
  //           console.log("Invoice deleted successfully!");
  //           const updatedDataResponse = await myAxiosCommonHost.get(
  //             "gst/projectpo/getProjectPoList"
  //           );
  //           if (updatedDataResponse.status === 200) {
  //             const updatedResult = await updatedDataResponse.data;
  //             setData(updatedResult);
  //             setEditedInvoiceData(updatedResult);
  //           } else {
  //             console.error("Failed to fetch updated data.");
  //           }
  //           setDeleteDialogOpen(false);
  //         } else {
  //           console.error("Failed to delete PO.");
  //           alert("Failed to delete PO. Please try again.");
  //         }
  //       } else {
  //         console.error("Failed to verify password.");
  //         alert("Failed to verify password. Deletion canceled.");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error deleting PO:", error);
  //     alert("An error occurred while deleting the PO.");
  //   }
  //   setIsDeleting(false);
  //   setDeleteDialogOpen(false);
  // };
  //   const handleDeleteConfirmed = async (invoiceRefNo) => {
  //     const encodedinvoiceRefNo = encodeURIComponent(invoiceRefNo);
  //     try {
  //       const response = await myAxiosCommonHost.delete(
  //         `gst/invoice/deleteInvoice/invoiceRefNo?invoiceRefNo=${encodedinvoiceRefNo}`
  //       );

  //       if (response.status === 200) {
  //         const updatedDataResponse = await myAxiosCommonHost.get(
  //           "gst/invoice/getInvoiceList?filterType=EMPLOYEEPOINVOICE"
  //         );
  //         alert("Invoice deleted successfully!");
  //         setData(updatedDataResponse.data);
  //       } else {
  //         alert("Failed to delete invoice. Please try again.");
  //       }
  //     } catch (error) {
  //       console.error("Error deleting invoice:", error);
  //       alert("An error occurred while deleting the invoice.");
  //     }
  //   };

  const handlegeneratePDF = async (invoiceRefNo) => {
    try {
      const response = await myAxiosCommonHost.get(
        `gst/invoice/viewInvoice?invoiceRefNo=${invoiceRefNo}`
      );
      const invoiceData = response.data; // Assuming myAxiosCommonHost behaves similarly to axios

      // Determine empRateUnit from invoiceData
      const empRateUnit = invoiceData[0]?.empRateUnit;

      // Based on empRateUnit, generate corresponding PDF
      if (empRateUnit === "Per Day") {
        generatePDFPerDay(invoiceData);
      } else if (empRateUnit === "Per Month") {
        generatePDFPerMonth(invoiceData);
      } else if (empRateUnit === "Per Hour") {
        generatePDFPerHour(invoiceData);
      } else {
        console.log("Invalid empRateUnit value. Unable to generate PDF");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error appropriately
    }
  };

  const generatePDFPerDay = async (invoiceData) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      const {
        companyProfileUI,
        invoiceDetails,
        clientUI,
        invoiceEmployeePoDetailsUIList,
        invoiceGstDetailsUI,
        invoicePaymentModeUI,
        bankUI,
        gstdetailsUI,
        invoiceDate,
        invoiceRefNo,
        poNumber,
        workingDays,
        empRateUnit,
        clientName,
      } = invoiceData[0];

      let companyInfoText =
        `${companyProfileUI.companyName}\n` +
        `${companyProfileUI.companyAddress}\n` +
        `${companyProfileUI.website}\n` +
        `${companyProfileUI.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );

      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });

      // Use the fetched invoice data in your PDF

      //const { companyData, clientData } = await fetchData();

      const CompInvo = [
        ["Invoice No: " + invoiceRefNo],
        ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
        ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
        ["Invoice Date: " + invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstdetailsUI.hsnsac],
        ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientUI.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];

      const clientInvoTableHeight = 40;

      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });

      const firstTableHeight1 = doc.previousAutoTable.finalY;
      const billedToContainerHeight = 38.85;

      // Billed To container immediately after companyInfo container

      const billedToText = `Billed To: ${clientName}
    ${clientUI.clientAddress}
    
    
    Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
    Kind Attn: ${invoicePaymentModeUI.kindAttention}`;

      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
        maxWidth: billedToContainerWidth - 10,
      });
      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
            poNumber,
            row.employeeCategory,
            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 7;
        } else {
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,

            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 6;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceEmployeePoDetailsUIList.map((row) => [
          row.emplyoyeeName,

          row.fromDate,
          row.toDate,

          row.presenty,
          row.currentPoRate,
          row.presentyAmt, // Calculate amount
        ]);
        amountColumnIndex = 5;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "PO NO.",
              "Employee Category",
              "From Date",
              "To date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "From Date",
              "To date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Particulars",
              "From Date",
              "To date",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],

        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });

      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWord,
      } = invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } = invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { columnWidth: 157 },
          1: { columnWidth: 25, halign: "right" },
        },
      });

      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }

      const bankDataTable = [
        [`Bank Name: ${bankUI.bankName}`],
        [`Bank Address: ${bankUI.bankAddress}`],
        [`A/C No.: ${bankUI.accountNumber}`],
        [`IFC Code: ${bankUI.ifsc}`],
        [`Swift Code: ${bankUI.swiftCode}`],
      ];

      const tableWidth2 = doc.internal.pageSize.width * 0.4;

      // Set startY for the bank table immediately after the first table
      doc.autoTable({
        body: bankDataTable,
        startY: secondTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
      });

      // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
      const rectWidth = 97;
      const rectHeight = 45.5;

      // Set rectX1 to start from the right edge of the bank table
      const rectX1 = doc.internal.pageSize.width * 0.47;

      const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

      doc.setDrawColor(0); // Set border color to black
      doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

      const line1Text = "For Gsquare Techsystem PVT LTD";
      const line2Text = "Authorized Signatory & Seal";
      const textX = rectX1 + 25;
      const textY1 = rectY1 + 8;
      const textY2 = rectY1 + 30;
      doc.text(line1Text, textX - 5, textY1);
      doc.text(line2Text, textX, textY2);

      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const generatePDFPerMonth = async (invoiceData) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      const {
        companyProfileUI,
        invoiceDetails,
        clientUI,
        invoiceEmployeePoDetailsUIList,
        invoiceGstDetailsUI,
        invoicePaymentModeUI,
        bankUI,
        gstdetailsUI,
        invoiceDate,
        invoiceRefNo,
        poNumber,
        workingDays,
        empRateUnit,
        clientName,
      } = invoiceData[0];

      let companyInfoText =
        `${companyProfileUI.companyName}\n` +
        `${companyProfileUI.companyAddress}\n` +
        `${companyProfileUI.website}\n` +
        `${companyProfileUI.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );

      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });

      // Use the fetched invoice data in your PDF

      //const { companyData, clientData } = await fetchData();

      const CompInvo = [
        ["Invoice No: " + invoiceRefNo],
        ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
        ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
        ["Invoice Date: " + invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstdetailsUI.hsnsac],
        ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientUI.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];

      const clientInvoTableHeight = 40;

      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });

      const firstTableHeight1 = doc.previousAutoTable.finalY;
      const billedToContainerHeight = 38.85;

      // Billed To container immediately after companyInfo container

      const billedToText = `Billed To: ${clientName}
      ${clientUI.clientAddress}
      
      
      Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
      Kind Attn: ${invoicePaymentModeUI.kindAttention}`;

      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
        maxWidth: billedToContainerWidth - 10,
      });
      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
            poNumber,
            row.employeeCategory,
            row.fromDate,
            row.toDate,
            workingDays,
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 8;
        } else {
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
            poNumber,
            row.fromDate,
            row.toDate,
            workingDays,
            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 7;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceEmployeePoDetailsUIList.map((row) => [
          row.emplyoyeeName,

          row.fromDate,
          row.toDate,
          workingDays,
          row.presenty,
          row.currentPoRate,
          row.presentyAmt, // Calculate amount
        ]);
        amountColumnIndex = 7;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "PO NO.",
              "Employee Category",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "PO NO.",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Particulars",
              "From Date",
              "To date",
              "Working Days",
              "Billable Days",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],

        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });

      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWord,
      } = invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } = invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { columnWidth: 157 },
          1: { columnWidth: 25, halign: "right" },
        },
      });

      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }

      const bankDataTable = [
        [`Bank Name: ${bankUI.bankName}`],
        [`Bank Address: ${bankUI.bankAddress}`],
        [`A/C No.: ${bankUI.accountNumber}`],
        [`IFC Code: ${bankUI.ifsc}`],
        [`Swift Code: ${bankUI.swiftCode}`],
      ];

      const tableWidth2 = doc.internal.pageSize.width * 0.4;

      // Set startY for the bank table immediately after the first table
      doc.autoTable({
        body: bankDataTable,
        startY: secondTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
      });

      // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
      const rectWidth = 97;
      const rectHeight = 45.5;

      // Set rectX1 to start from the right edge of the bank table
      const rectX1 = doc.internal.pageSize.width * 0.47;

      const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

      doc.setDrawColor(0); // Set border color to black
      doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

      const line1Text = "For Gsquare Techsystem PVT LTD";
      const line2Text = "Authorized Signatory & Seal";
      const textX = rectX1 + 25;
      const textY1 = rectY1 + 8;
      const textY2 = rectY1 + 30;
      doc.text(line1Text, textX - 5, textY1);
      doc.text(line2Text, textX, textY2);

      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const generatePDFPerHour = async (invoiceData) => {
    const doc = new jsPDF();

    const includePO = await Swal.fire({
      title: "Do you want to include PO number in the PDF?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });

    // Define includeEmployeeCategoryWithPO variable
    let includeEmployeeCategoryWithPO;

    if (includePO) {
      includeEmployeeCategoryWithPO = await Swal.fire({
        title:
          "Do you want to include Employee Category with PO Number in the PDF?",
        icon: "question",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        } else {
          return false;
        }
      });

      await generatePDF(includePO, includeEmployeeCategoryWithPO);
    } else {
      await generatePDF(includePO, false);
    }
    // Call generatePDF function with correct parameters
    async function generatePDF(includePO, includeEmployeeCategoryWithPO) {
      const encompassingRectX = 3;
      const encompassingRectY = 4;
      const encompassingRectWidth = doc.internal.pageSize.width - 6;
      const encompassingRectHeight = doc.internal.pageSize.height - 8;

      doc.setDrawColor(0); // Set border color to black
      doc.setLineWidth(0.4); // Set border width
      doc.rect(
        encompassingRectX,
        encompassingRectY,
        encompassingRectWidth,
        encompassingRectHeight
      );

      const {
        companyProfileUI,
        invoiceDetails,
        clientUI,
        invoiceEmployeePoDetailsUIList,
        invoiceGstDetailsUI,
        invoicePaymentModeUI,
        bankUI,
        gstdetailsUI,
        invoiceDate,
        invoiceRefNo,
        poNumber,
        workingDays,
        empRateUnit,
        clientName,
      } = invoiceData[0];

      let companyInfoText =
        `${companyProfileUI.companyName}\n` +
        `${companyProfileUI.companyAddress}\n` +
        `${companyProfileUI.website}\n` +
        `${companyProfileUI.eMail}`;

      // Add the company information to the PDF for the current invoice
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.text(companyInfoText, 15, 30);
      doc.setFont("helvetica", "normal"); // Reset font to normal

      // Continue with the rest of your PDF generation logic...

      const companyInfoContainerX = 14;
      const companyInfoContainerY = 10;
      const companyInfoContainerWidth =
        doc.internal.pageSize.width * 0.55 - companyInfoContainerX - 10;
      const companyInfoContainerHeight = 40;

      // Draw the container first
      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        companyInfoContainerX,
        companyInfoContainerY,
        companyInfoContainerWidth,
        companyInfoContainerHeight,
        "FD"
      );

      // Add the logo with additional margin from the left border
      doc.addImage(Logo, "JPEG", 15, 11, 28, 28); // Change x coordinate (e.g., 25 or 30)

      // Position the company information text with reduced margin after the logo
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(companyInfoText, 25 + 20 + 2, 18, {
        // Change margin after logo width (e.g., 2 or 3)
        maxWidth: doc.internal.pageSize.width * 0.55 - 25 - 20 - 15, // Calculate available width minus adjusted logo position and margins
      });

      // Use the fetched invoice data in your PDF

      //const { companyData, clientData } = await fetchData();

      const CompInvo = [
        ["Invoice No: " + invoiceRefNo],
        ["Company's GST NO.: " + companyProfileUI.gstin], // Include GSTIN directly
        ["Company's PAN No.: " + companyProfileUI.pan], // Include PAN directly
        ["Company's CIN: " + companyProfileUI.cin], // Include CIN directly
        ["Invoice Date: " + invoiceDate],
      ];

      const tableWidth = doc.internal.pageSize.width * 0.45;
      const leftMargin = doc.internal.pageSize.width - tableWidth - 10;

      // Calculate the height of the CompInvo table
      const compInvoTableHeight = doc.autoTable({
        body: CompInvo,
        theme: "grid",
        margin: { top: 10, left: leftMargin, right: 14, bottom: 0 },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 2,
          marginBottom: 0,
          marginTop: 0,
          padding: 0,
        },
      }).lastAutoTable.finalY;

      const ClientInvo = [
        ["Service ACC Code: " + gstdetailsUI.hsnsac],
        ["Client's GSTIN: " + gstdetailsUI.gstDetailsGstin],
        ["Payment Term: 15Days Bank Transfer"], // No need to concatenate, as it's a single value
        ["Billing Period: " + clientUI.billingPeriod],
        ["Onsite Manpower Support for the month"], // No need to concatenate, as it's a single value
      ];

      const clientInvoTableHeight = 40;

      // Set the startY parameter for ClientInvo table to start immediately after CompInvo table
      doc.autoTable({
        body: ClientInvo,
        theme: "grid",
        columnStyles: { 0: { cellWidth: "auto" } },
        startY: compInvoTableHeight, // Set startY explicitly
        margin: {
          top: compInvoTableHeight,
          left: leftMargin,
          right: 14,
          bottom: 0,
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
          tableHeight: 40,
        },
      });

      const firstTableHeight1 = doc.previousAutoTable.finalY;
      const billedToContainerHeight = 38.85;

      // Billed To container immediately after companyInfo container

      const billedToText = `Billed To: ${clientName}
        ${clientUI.clientAddress}
        
        
        Place of supply: ${gstdetailsUI.state} State Code: ${gstdetailsUI.stateCode}
        Kind Attn: ${invoicePaymentModeUI.kindAttention}`;

      const billedToContainerX = companyInfoContainerX;
      const topMargin = 0;
      const containerHeight = 40; // Set the common height for both the billedToContainer and the table
      const billedToContainerY =
        companyInfoContainerY + companyInfoContainerHeight + topMargin;
      const billedToContainerWidth = companyInfoContainerWidth;

      doc.setDrawColor(0);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.rect(
        billedToContainerX,
        billedToContainerY,
        billedToContainerWidth,
        containerHeight,
        "FD"
      );

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(billedToText, billedToContainerX + 5, billedToContainerY + 10, {
        maxWidth: billedToContainerWidth - 10,
      });
      doc.setFillColor(255, 255, 255);

      let tableData;
      let amountColumnIndex;
      if (includePO) {
        // Include PO number column in the PDF
        if (includeEmployeeCategoryWithPO) {
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,
            poNumber,
            row.employeeCategory,
            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 7;
        } else {
          tableData = invoiceEmployeePoDetailsUIList.map((row) => [
            row.emplyoyeeName,

            row.fromDate,
            row.toDate,

            row.presenty,
            row.currentPoRate,
            row.presentyAmt, // Calculate amount
          ]);
          amountColumnIndex = 6;
        }
      } else {
        // Exclude PO number column in the PDF
        tableData = invoiceEmployeePoDetailsUIList.map((row) => [
          row.emplyoyeeName,

          row.fromDate,
          row.toDate,

          row.presenty,
          row.currentPoRate,
          row.presentyAmt, // Calculate amount
        ]);
        amountColumnIndex = 5;
      }

      const headerLabels =
        includePO && includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "PO NO.",
              "Employee Category",
              "From Date",
              "To date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ]
          : includePO && !includeEmployeeCategoryWithPO
          ? [
              "Particulars",
              "From Date",
              "To date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ]
          : [
              "Particulars",
              "From Date",
              "To date",
              "Billable Hours",
              "Current PO Rate",
              "Amount",
            ];

      // Generate the PDF table
      doc.autoTable({
        head: [headerLabels],

        body: tableData,
        startY: billedToContainerY + billedToContainerHeight + 0,
        theme: "grid",
        styles: {
          fontSize: 9,
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          [amountColumnIndex]: { columnWidth: 25, halign: "right" }, // Set width for the "Amount" column
        },
      });

      // Calculate the height of the first table (invoice data table)
      const firstTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const {
        totalPresentyAmount,
        grandTotalAmount,
        totalTaxAmt,
        totalInWord,
      } = invoiceGstDetailsUI;
      const { cgstAmount, sgstAmount, igstAmount } = invoiceGstDetailsUI;

      const InvoiceData = [
        ["A] Total", totalPresentyAmount],
        ["B] CGST (1)", ` ${cgstAmount}`],
        ["C] SGST (2)", ` ${sgstAmount}`],
        ["D] IGST (3)", ` ${igstAmount}`],
        ["E] Total (A+B+C+D)", grandTotalAmount],
        ["F] Deduction ", 0],
        ["G] Grand Total (F-G)", grandTotalAmount],
        [{ content: " Total In Words: " + totalInWord, colSpan: 2 }],
      ];

      // Set startY for the second table immediately after the first table
      doc.autoTable({
        body: InvoiceData,
        startY: firstTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: {
          0: { columnWidth: 157 },
          1: { columnWidth: 25, halign: "right" },
        },
      });

      // Calculate the height of the second table (invoice data table)
      const secondTableHeight = doc.previousAutoTable
        ? doc.previousAutoTable.finalY
        : 0;

      const remainingSpace =
        doc.internal.pageSize.getHeight() - secondTableHeight - 20;

      // Check if there is enough space for the bank table
      if (remainingSpace < 0) {
        doc.addPage();
      }

      const bankDataTable = [
        [`Bank Name: ${bankUI.bankName}`],
        [`Bank Address: ${bankUI.bankAddress}`],
        [`A/C No.: ${bankUI.accountNumber}`],
        [`IFC Code: ${bankUI.ifsc}`],
        [`Swift Code: ${bankUI.swiftCode}`],
      ];

      const tableWidth2 = doc.internal.pageSize.width * 0.4;

      // Set startY for the bank table immediately after the first table
      doc.autoTable({
        body: bankDataTable,
        startY: secondTableHeight + 0,
        theme: "grid",
        styles: {
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          marginBottom: 0,
        },
        columnStyles: { 0: { cellWidth: tableWidth2, cellHeight: 10 } },
      });

      // Draw the rectangle and text for "For Gsquare Techsystem PVT LTD"
      const rectWidth = 97;
      const rectHeight = 45.5;

      // Set rectX1 to start from the right edge of the bank table
      const rectX1 = doc.internal.pageSize.width * 0.47;

      const rectY1 = secondTableHeight + 0; // Adjust the spacing as needed

      doc.setDrawColor(0); // Set border color to black
      doc.rect(rectX1, rectY1, rectWidth, rectHeight).setLineWidth(0.5);

      const line1Text = "For Gsquare Techsystem PVT LTD";
      const line2Text = "Authorized Signatory & Seal";
      const textX = rectX1 + 25;
      const textY1 = rectY1 + 8;
      const textY2 = rectY1 + 30;
      doc.text(line1Text, textX - 5, textY1);
      doc.text(line2Text, textX, textY2);

      const pdfData = doc.output("blob"); // Assuming doc.output provides Blob object
      const pdfWindow = window.open("", "_blank");
      const pdfURL = URL.createObjectURL(pdfData);
      pdfWindow.location.href = pdfURL;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGenerateExcel = () => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error("Data is not available or empty.");
      return;
    }

    // Flatten the data structure to include additional fields
    const flattenedData = data.flatMap((item) => {
      // Extract employee details and remove invoiceEmployeePoDetailsUIList from the item
      const {
        invoiceEmployeePoDetailsUIList,
        invoiceGstDetailsUI,
        invoicePaymentModeUI,
        bankUI,
        clientUI,
        gstdetailsUI,
        employee,
        ...rest
      } = item;

      // Map over each employee and include their details in the flattened data
      return invoiceEmployeePoDetailsUIList.map((employee) => ({
        ...rest,
        ...employee,
        ...item.invoiceGstDetailsUI,
        ...item.invoicePaymentModeUI,
        ...item.bankUI,
        ...item.clientUI,
        ...item.gstdetailsUI,
      }));
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice Data");

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.target = "_blank"; // Open in new tab/window
    anchor.download = "invoice_data.xlsx"; // File name

    // Trigger the download
    anchor.click();
  };
  const filteredData =
    data && Array.isArray(data)
      ? data.filter(
          (po) =>
            po.clientNameAndCode
              .toLowerCase()
              .includes(searchTermClientName.toLowerCase()) &&
            po.invoiceNo.toString().includes(searchTerm.toLowerCase())
        )
      : [];
  return (
    <>
      {isDeleting && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Invoice is Deleting...</div>
        </div>
      )}
      {isUpdating && (
        <div className="blur-effect">
          <CircularProgress color="primary" />
          <div className="loading-message">Invoice is Updating...</div>
        </div>
      )}
      <TextField
        label="Search by Invoice No"
        variant="standard"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <TextField
        label="Search by Client Name"
        variant="standard"
        value={searchTermClientName}
        onChange={(e) => setSearchTermClientName(e.target.value)}
        style={{ float: "right", margin: "10px" }}
      />
      <Paper
        elevation={3}
        style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
      >
        <Link to="/generateEmployeeInvoice" style={{ marginRight: "10px" }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
          >
            Generate Invoice
          </Button>
        </Link>

        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateExcel}
          style={{ marginTop: 20, marginRight: 10, marginBottom: "20px" }}
        >
          Excel
        </Button>
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: 20,
          marginBottom: 20,
          backgroundColor: "#2196f3",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: "15px", color: "darkblue" }}
          >
            Employee Invoice List
          </Typography>
        </div>
      </Paper>

      {error ? (
        <p>No Invoice Data Found, Generete A New Invoice.</p>
      ) : (
        <TableContainer
          component={Paper}
          style={{ height: "20%", marginTop: "20px" }}
        >
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
              <Typography
                variant="h6"
                color="inherit"
                style={{ marginLeft: "10px" }}
              >
                Getting Data...
              </Typography>
            </Backdrop>
          ) : (
            <>
              <Grid item xs={12}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "darkcyan",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        <TableCell />
                        <TableCell>Invoice Ref</TableCell>
                        <TableCell>Client Name</TableCell>
                        <TableCell>Po Number</TableCell>
                        <TableCell>Service Of Month </TableCell>
                        <TableCell> Service Of Year</TableCell>
                        <TableCell>Invoice No</TableCell>
                        <TableCell></TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            No Employee Invoice found
                          </TableCell>
                        </TableRow>
                      ) : (
                        filteredData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          // .filter((po) =>
                          //   po.invoiceNo
                          //     .toString()
                          //     .includes(searchTerm.toString().toLowerCase())
                          // )
                          // .filter((po) =>
                          //   po.clientNameAndCode
                          //     .toLowerCase()
                          //     .includes(searchTermClientName.toLowerCase())
                          // )
                          .map((invoice, index) => (
                            <React.Fragment key={index}>
                              <TableRow>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleExpandClick(index)}
                                    aria-expanded={expandedRow === index}
                                    aria-label="show more"
                                  >
                                    <ExpandMoreIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>{invoice.invoiceRefNo}</TableCell>
                                <TableCell>{invoice.clientName}</TableCell>
                                <TableCell>{invoice.poNumber}</TableCell>
                                <TableCell>{invoice.paymentMonth}</TableCell>
                                <TableCell>{invoice.paymentYear}</TableCell>
                                <TableCell>{invoice.invoiceNo}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handlegeneratePDF(invoice.invoiceRefNo)
                                    } // Pass a function reference here
                                    color="secondary"
                                  >
                                    VIEW
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handleUpdateInvoiceClick(invoice)
                                    }
                                  >
                                    Update Invoice
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      handleDeleteClick(invoice.invoiceRefNo)
                                    }
                                  >
                                    Delete Invoice
                                  </Button>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Collapse
                                    in={expandedRow === index}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {invoice.invoiceEmployeePoDetailsUIList.map(
                                      (proInvoice, empIndex) => (
                                        <Accordion key={empIndex}>
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${index}-content`}
                                            id={`panel${index}-header`}
                                            IconButtonProps={{ edge: "start" }}
                                          >
                                            <Typography>
                                              {"Employee Name -:"}
                                              {
                                                invoice
                                                  .invoiceEmployeePoDetailsUIList[
                                                  empIndex
                                                ].emplyoyeeName
                                              }
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <Table style={{ width: "100%" }}>
                                              <TableBody>
                                                <TableRow
                                                  style={{
                                                    backgroundColor: "darkcyan",
                                                    fontWeight: "bold",
                                                    color: "darkblue",
                                                  }}
                                                >
                                                  <TableCell>
                                                    Emplyoyee Name
                                                  </TableCell>
                                                  <TableCell
                                                    style={{ width: "150px" }}
                                                  >
                                                    From Date
                                                  </TableCell>
                                                  <TableCell
                                                    style={{ width: "150px" }}
                                                  >
                                                    To Date
                                                  </TableCell>
                                                  <TableCell>
                                                    Curreent Po Rate
                                                  </TableCell>
                                                  {/* <TableCell>Action</TableCell> */}
                                                  <TableCell></TableCell>
                                                </TableRow>
                                                {proInvoice ? (
                                                  <TableRow>
                                                    <TableCell>
                                                      {proInvoice.emplyoyeeName}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.fromDate
                                                        ? proInvoice.fromDate
                                                            .split("-")
                                                            .reverse()
                                                            .join("-")
                                                        : "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.toDate
                                                        ? proInvoice.toDate
                                                            .split("-")
                                                            .reverse()
                                                            .join("-")
                                                        : "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                      {proInvoice.currentPoRate}
                                                    </TableCell>
                                                  </TableRow>
                                                ) : (
                                                  <TableRow>
                                                    <TableCell
                                                      colSpan={4}
                                                      align="center"
                                                    >
                                                      No Record found
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                                <TableRow>
                                                  <TableCell colSpan={6}>
                                                    <Collapse
                                                      in={expandedRow === index}
                                                      timeout="auto"
                                                      unmountOnExit
                                                    >
                                                      <Box
                                                        style={{ padding: 20 }}
                                                      >
                                                        <Typography variant="subtitle1">
                                                          Employee Details
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Presenty
                                                          </TableCell>
                                                          <TableCell>
                                                            Presenty Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Canteen Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Transportation
                                                            Amount
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              width: "150px",
                                                            }}
                                                          >
                                                            From Date
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              width: "150px",
                                                            }}
                                                          >
                                                            To Date
                                                          </TableCell>
                                                          <TableCell>
                                                            Emplyoyee Code
                                                          </TableCell>
                                                          <TableCell>
                                                            Emplyoyee Name
                                                          </TableCell>
                                                          <TableCell>
                                                            Curreent Po Rate
                                                          </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                          <TableCell>
                                                            {
                                                              proInvoice.presenty
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.presentyAmt
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.canteenAmt
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.transportationAmt
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {proInvoice.fromDate
                                                              ? proInvoice.fromDate
                                                                  .split("-")
                                                                  .reverse()
                                                                  .join("-")
                                                              : "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {proInvoice.fromDate
                                                              ? proInvoice.toDate
                                                                  .split("-")
                                                                  .reverse()
                                                                  .join("-")
                                                              : "N/A"}
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.employeeCode
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.emplyoyeeName
                                                            }
                                                          </TableCell>
                                                          <TableCell>
                                                            {
                                                              proInvoice.currentPoRate
                                                            }
                                                          </TableCell>
                                                        </TableRow>
                                                        <Typography variant="subtitle1">
                                                          Employee Details
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Fix monthly Canteen
                                                          </TableCell>
                                                          <TableCell>
                                                            Is CanteenAssign
                                                          </TableCell>
                                                          <TableCell>
                                                            Is
                                                            TransportationAssign
                                                          </TableCell>
                                                          <TableCell>
                                                            Active Status
                                                          </TableCell>
                                                          <TableCell>
                                                            Release Date
                                                          </TableCell>
                                                          <TableCell>
                                                            Current Emplyoyee
                                                          </TableCell>
                                                          <TableCell>
                                                            Current Po Date
                                                          </TableCell>
                                                          {/* <TableCell>
                                                            Annenux Flag
                                                          </TableCell> */}
                                                        </TableRow>
                                                        {proInvoice ? (
                                                          <TableRow>
                                                            <TableCell>
                                                              {
                                                                proInvoice.fixmonthlyCanteen
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.isCanteenAssign
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.isTransportationAssign
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.activeStatus
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.releaseDate
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.currentEmplyoyee
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                proInvoice.currentPoDate
                                                              }
                                                            </TableCell>
                                                            {/* <TableCell>
                                                              {
                                                                proInvoice.annenuxFlag
                                                              }
                                                            </TableCell> */}
                                                          </TableRow>
                                                        ) : (
                                                          <TableRow>
                                                            <TableCell
                                                              colSpan={4}
                                                              align="center"
                                                            >
                                                              No Details found
                                                            </TableCell>
                                                          </TableRow>
                                                        )}
                                                        <Typography variant="subtitle1">
                                                          Invoice Gst Details
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Total Presenty
                                                            Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Total Tax Amt
                                                          </TableCell>
                                                          <TableCell>
                                                            Grand Total
                                                          </TableCell>
                                                          <TableCell>
                                                            Deduction Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Deduction Particular
                                                          </TableCell>
                                                          <TableCell>
                                                            CGST
                                                          </TableCell>
                                                          <TableCell>
                                                            SGST
                                                          </TableCell>
                                                          <TableCell>
                                                            IGST
                                                          </TableCell>
                                                          <TableCell>
                                                            Grand Total Amount
                                                          </TableCell>
                                                          <TableCell>
                                                            Total In Word Amount
                                                          </TableCell>
                                                        </TableRow>
                                                        {invoice.invoiceGstDetailsUI ? (
                                                          <TableRow>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .totalPresentyAmount
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .totalTaxAmt
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .grandTotalAmount
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .deductionAmount
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .deductionParticular
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .cGST
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .sGST
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .iGST
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .grandTotalAmount
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoiceGstDetailsUI
                                                                  .totalInWordAmount
                                                              }
                                                            </TableCell>
                                                          </TableRow>
                                                        ) : (
                                                          <TableRow>
                                                            <TableCell
                                                              colSpan={4}
                                                              align="center"
                                                            >
                                                              No GST Details
                                                              found
                                                            </TableCell>
                                                          </TableRow>
                                                        )}

                                                        <Typography variant="subtitle1">
                                                          Invoice Payment Mode
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Mode Terms Of
                                                            Payment
                                                          </TableCell>
                                                          <TableCell>
                                                            Kind Attention
                                                          </TableCell>
                                                          <TableCell>
                                                            Select Bank AccNo
                                                          </TableCell>
                                                        </TableRow>
                                                        {invoice.invoicePaymentModeUI ? (
                                                          <TableRow>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoicePaymentModeUI
                                                                  .modeTermsOfPayment
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoicePaymentModeUI
                                                                  .kindAttention
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice
                                                                  .invoicePaymentModeUI
                                                                  .selectBankAccNo
                                                              }
                                                            </TableCell>
                                                          </TableRow>
                                                        ) : (
                                                          <TableRow>
                                                            <TableCell
                                                              colSpan={4}
                                                              align="center"
                                                            >
                                                              No Payment Mode
                                                              Details found
                                                            </TableCell>
                                                          </TableRow>
                                                        )}

                                                        <Typography variant="subtitle1">
                                                          Bank Details
                                                        </Typography>
                                                        <TableRow
                                                          style={{
                                                            backgroundColor:
                                                              "darkcyan",
                                                            fontWeight: "bold",
                                                            color: "darkblue",
                                                          }}
                                                        >
                                                          <TableCell>
                                                            Account Number
                                                          </TableCell>
                                                          <TableCell>
                                                            Bank Name
                                                          </TableCell>
                                                          <TableCell>
                                                            Account Type
                                                          </TableCell>
                                                          <TableCell>
                                                            Branch Name
                                                          </TableCell>
                                                          <TableCell>
                                                            Account Number
                                                          </TableCell>
                                                          <TableCell>
                                                            IFSC
                                                          </TableCell>
                                                          <TableCell>
                                                            MICR
                                                          </TableCell>
                                                          <TableCell>
                                                            Bank Address
                                                          </TableCell>
                                                          <TableCell>
                                                            Swift Code
                                                          </TableCell>
                                                        </TableRow>
                                                        {invoice.bankUI ? (
                                                          <TableRow>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .accountNumber
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .bankName
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .accountType
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .branchName
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .ifsc
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .micr
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .bankAddress
                                                              }
                                                            </TableCell>
                                                            <TableCell>
                                                              {
                                                                invoice.bankUI
                                                                  .swiftCode
                                                              }
                                                            </TableCell>
                                                          </TableRow>
                                                        ) : (
                                                          <TableRow>
                                                            <TableCell
                                                              colSpan={4}
                                                              align="center"
                                                            >
                                                              No Bank Details
                                                              found
                                                            </TableCell>
                                                          </TableRow>
                                                        )}
                                                      </Box>
                                                    </Collapse>
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </AccordionDetails>
                                        </Accordion>
                                      )
                                    )}
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </>
          )}
        </TableContainer>
      )}
      <Dialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        maxWidth="lg"
        maxHeight="lg"
        fullWidth
      >
        <DialogTitle>Update Invoice Data</DialogTitle>
        <DialogContent>
          <Paper
            elevation={4}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={3}>
                <TextField
                  label="Select Company"
                  value={editedInvoiceData?.selectCompany || ""}
                  onChange={(e) =>
                    handleDialogInputChange("selectCompany", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Financial Year"
                  value={editedInvoiceData?.financialYear || ""}
                  onChange={(e) =>
                    handleDialogInputChange("financialYear", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice No"
                  value={editedInvoiceData?.invoiceNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceNo", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Ref No"
                  value={editedInvoiceData?.invoiceRefNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceRefNo", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Invoice Date"
                  type="date"
                  value={editedInvoiceData?.invoiceDate || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceDate", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  label="Invoice Type"
                  value={editedInvoiceData?.invoiceType || ""}
                  onChange={(e) =>
                    handleDialogInputChange("invoiceType", e.target.value)
                  }
                  disabled
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  label="Client Type"
                  value={editedInvoiceData?.clientType || ""}
                  onChange={(e) =>
                    handleDialogInputChange("clientType", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Client Name And Code"
                  value={editedInvoiceData?.clientNameAndCode || ""}
                  onChange={(e) =>
                    handleDialogInputChange("clientNameAndCode", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Issue Date"
                  type="date"
                  value={editedInvoiceData?.issueDate || ""}
                  onChange={(e) =>
                    handleDialogInputChange("issueDate", e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="SacHsn"
                  value={editedInvoiceData?.sacHsn || ""}
                  onChange={(e) =>
                    handleDialogInputChange("sacHsn", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="PO Number"
                  value={editedInvoiceData?.poNumber || ""}
                  onChange={(e) =>
                    handleDialogInputChange("poNumber", e.target.value)
                  }
                  required
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  label="Buyers Po Number"
                  value={editedInvoiceData?.buyersPoNumber || ""}
                  onChange={(e) =>
                    handleDialogInputChange("buyersPoNumber", e.target.value)
                  }
                />
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  label="Service of Year"
                  value={editedInvoiceData?.paymentYear || ""}
                  onChange={(e) =>
                    handleDialogInputChange("paymentYear", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Service of Month"
                  value={editedInvoiceData?.paymentMonth || ""}
                  onChange={(e) =>
                    handleDialogInputChange("paymentMonth", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Unique Po No"
                  value={editedInvoiceData?.uniqueNoPoNo || ""}
                  onChange={(e) =>
                    handleDialogInputChange("uniqueNoPoNo", e.target.value)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Working Days"
                  value={editedInvoiceData?.workingDays || ""}
                  onChange={(e) =>
                    handleDialogInputChange("workingDays", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceType"
                  options={["Debit Note", "Credit Note", "Tax Invoice"]}
                  value={editedInvoiceData?.invoiceType || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceType", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Type" required />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceTitle"
                  options={["TAX INVOICE", "PROFORMA INVOICE"]}
                  value={editedInvoiceData?.invoiceTitle || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceTitle", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Title" required />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="invoiceRemarks"
                  options={invoiceRemarksOptions}
                  value={editedInvoiceData?.invoiceRemarks || ""}
                  onChange={(event, newValue) =>
                    handleDialogInputChange("invoiceRemarks", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Invoice Remarks" required />
                  )}
                />
              </Grid>

              {/* <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="invoiceType-label">Invoice Type</InputLabel>
                  <Select
                    labelId="invoiceType-label"
                    id="invoiceType"
                    value={editedInvoiceData?.invoiceType || ""}
                    onChange={(e) =>
                      handleDialogInputChange("invoiceType", e.target.value)
                    }
                    required
                  >
                    <MenuItem value={editedInvoiceData?.invoiceType || ""}>
                      {editedInvoiceData?.invoiceType || "Select Invoice Type"}
                    </MenuItem>
                    <MenuItem value="Debit Note">Debit Note</MenuItem>
                    <MenuItem value="Credit Note">Credit Note</MenuItem>
                    <MenuItem value="Tax Invoice">Tax Invoice</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="invoiceTitle-label">Invoice Title</InputLabel>
                  <Select
                    labelId="invoiceTitle-label"
                    id="invoiceTitle"
                    value={editedInvoiceData?.invoiceTitle || ""}
                    onChange={(e) =>
                      handleDialogInputChange("invoiceTitle", e.target.value)
                    }
                    required
                  >
                    <MenuItem value={editedInvoiceData?.invoiceTitle || ""}>
                      {editedInvoiceData?.invoiceTitle ||
                        "Select Invoice Title"}
                    </MenuItem>
                    <MenuItem value="TAX INVOICE">TAX INVOICE</MenuItem>
                    <MenuItem value="PROFORMA INVOICE ">
                      PROFORMA INVOICE{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={3}>
                <TextField
                  label="Emp Rate Unit"
                  value={editedInvoiceData?.empRateUnit || ""}
                  onChange={(e) =>
                    handleDialogInputChange("empRateUnit", e.target.value)
                  }
                  required
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <TableContainer component={Paper} style={{ padding: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell style={{ padding: "30px" }}>
                      Delete Employee
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>Presenty</TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Presenty Amount
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Canteen Amount
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Transportation Amount
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>From Date</TableCell>
                    <TableCell style={{ padding: "30px" }}>To Date</TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Employee Code
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Employee Name
                    </TableCell>
                    <TableCell style={{ padding: "30px" }}>
                      Current Po Rate
                    </TableCell>
                    {/* <TableCell style={{ padding: "30px" }}>
                          Fix Monthly Canteen
                        </TableCell>
                        <TableCell style={{ padding: "30px" }}>
                          Is Canteen Assign
                        </TableCell>
                        <TableCell style={{ padding: "30px" }}>
                          Is Transportation Assign
                        </TableCell>
                        <TableCell style={{ padding: "30px" }}>
                          Active Status
                        </TableCell>
                        <TableCell style={{ padding: "30px" }}>
                          Release Date
                        </TableCell>
                        <TableCell style={{ padding: "30px" }}>
                          Current Employee
                        </TableCell>
                        <TableCell style={{ padding: "30px" }}>
                          Current Po Date
                        </TableCell>
                        <TableCell style={{ padding: "30px" }}>
                          Annenux Flag
                        </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedInvoiceData?.invoiceEmployeePoDetailsUIList?.map(
                    (project, projectIndex) => (
                      <TableRow key={projectIndex}>
                        <TableCell>
                          <Checkbox
                            checked={project.employeeDeleteFlag === true}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "employeeDeleteFlag",
                                e.target.checked
                              )
                            }
                            style={{ color: "red" }} // Set the color to red
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.presenty || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "presenty",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.presentyAmt || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "presentyAmt",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.canteenAmt || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "canteenAmt",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.transportationAmt || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "transportationAmt",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.fromDate || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "fromDate",
                                e.target.value
                              )
                            }
                            type="date"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.toDate || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "toDate",
                                e.target.value
                              )
                            }
                            type="date"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.employeeCode || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "employeeCode",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.emplyoyeeName || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "emplyoyeeName",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={project.currentPoRate || ""}
                            onChange={(e) =>
                              handleProjectInputChange(
                                projectIndex,
                                "currentPoRate",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        {/* <TableCell>
                              <TextField
                                value={project.fixmonthlyCanteen || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    projectIndex,
                                    "fixmonthlyCanteen",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={project.isCanteenAssign || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    projectIndex,
                                    "isCanteenAssign",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={project.isTransportationAssign || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    projectIndex,
                                    "isTransportationAssign",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={project.activeStatus || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    projectIndex,
                                    "activeStatus",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={project.releaseDate || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    projectIndex,
                                    "releaseDate",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={project.currentEmplyoyee || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    projectIndex,
                                    "currentEmplyoyee",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={project.currentPoDate || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    projectIndex,
                                    "currentPoDate",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={project.annenuxFlag || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    projectIndex,
                                    "annenuxFlag",
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell> */}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Total Presenty Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .totalPresentyAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "totalPresentyAmount",
                        e.target.value
                      )
                    }
                  />

                  <TextField
                    label="Total Tax Amt"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.totalTaxAmt || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("totalTaxAmt", e.target.value)
                    }
                  />

                  <TextField
                    label="Grand Total"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.grandTotal || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("grandTotal", e.target.value)
                    }
                  />
                  <TextField
                    label="Grand Total Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.grandTotalAmount ||
                      ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("grandTotalAmount", e.target.value)
                    }
                  />

                  <TextField
                    label="Deduction Amount"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.deductionAmount ||
                      ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("deductionAmount", e.target.value)
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="Deduction Particular"
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    size="small"
                    multiline
                    rows={4}
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .deductionParticular || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "deductionParticular",
                        e.target.value
                      )
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <TextField
                    label="CGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.cGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("cGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    label="IGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.iGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("iGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    label="SGST"
                    variant="standard"
                    size="small"
                    value={editedInvoiceData?.invoiceGstDetailsUI.sGST || ""}
                    onChange={(e) =>
                      handleGstDetailsChange("sGST", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="SGST Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.sgstAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("sgstAmount", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="CGST Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.cgstAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("cgstAmount", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="IGST Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI.igstAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange("igstAmount", e.target.value)
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Total In Word Amount"
                    variant="standard"
                    size="small"
                    value={
                      editedInvoiceData?.invoiceGstDetailsUI
                        .totalInWordAmount || ""
                    }
                    onChange={(e) =>
                      handleGstDetailsChange(
                        "totalInWordAmount",
                        e.target.value
                      )
                    }
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  elevation={3}
                  style={{ padding: "20px", marginBottom: "20px" }}
                >
                  <Button variant="contained">Calculate</Button>
                </Paper>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <TextField
                  label="Mode Terms Of Payment"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI
                      .modeTermsOfPayment || ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange(
                      "modeTermsOfPayment",
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Kind Attention"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI.kindAttention || ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange("kindAttention", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Select Bank Acc No"
                  value={
                    editedInvoiceData?.invoicePaymentModeUI.selectBankAccNo ||
                    ""
                  }
                  onChange={(e) =>
                    handlePaymentModeChange("selectBankAccNo", e.target.value)
                  }
                  disabled
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper
            elevation={3}
            style={{ padding: 20, marginBottom: 20, marginTop: 20 }}
          >
            <Grid container spacing={6} style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <TextField
                  label="Account Number"
                  value={editedInvoiceData?.bankUI.accountNumber || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("accountNumber", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Bank Name"
                  value={editedInvoiceData?.bankUI.bankName || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("bankName", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Account Type"
                  value={editedInvoiceData?.bankUI.accountType || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("accountType", e.target.value)
                  }
                  disabled
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Branch Name"
                  value={editedInvoiceData?.bankUI.branchName || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("branchName", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="IFSC"
                  value={editedInvoiceData?.bankUI.ifsc || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("ifsc", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="MICR"
                  value={editedInvoiceData?.bankUI.micr || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("micr", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Bank Address"
                  value={editedInvoiceData?.bankUI.bankAddress || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("bankAddress", e.target.value)
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Swift Code"
                  value={editedInvoiceData?.bankUI.swiftCode || ""}
                  onChange={(e) =>
                    handleBankDetailsChange("swiftCode", e.target.value)
                  }
                  disabled
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUpdateDialogOpen(false)}
            color="primary"
            variant="contained"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateDialogSave}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            disabled={!hasChanges} // Disable the button if there are no changes
          >
            Update
          </Button>
          <Button
            variant="contained"
            onClick={handlegeneratePDF}
            color="secondary"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            PDF
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setPassword(""); // Reset password field
        }}
        maxHeight="md"
        fullWidth
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
          {/* {passwordVerified === false && (
            <Typography variant="body2" color="error">
              Incorrect password. Please try again.
            </Typography>
          )} */}
          <Paper elevation={2} style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Enter Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDialogDelete}
            color="primary"
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EmployeeInvoiceList;
