import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Paper,
  Grid,
  Card,
  CardHeader,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { myAxiosCommonHost } from "../../Service/CorsHelperBaseUrl";
const GenerateQuotation = () => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [quotationDetails, setQuotationDetails] = useState({
    selectCompany: "",
    quotationRefNo: "",
    quotationNo: 0,
    quotationDate: "",
    clientNameAndCode: "",
    kindAttention: "",
    quotationSubject: "",
    quotationContent: "",
    totalAmount: "",
    totalInWord: "",
    financialYear: "",
    financialMonth: "",
  });
  const [accomodationUIList, setAccomodationUIList] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [paymentMonths, setPaymentMonths] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [paymentYears, setPaymentYears] = useState([]);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [newAccomodation, setNewAccomodation] = useState({
    description: "",
    costPerResources: "",
    noOfResources: "",
    noOfMonth: "",
    totalCost: "",
  });
  useEffect(() => {
    myAxiosCommonHost
      .get("gst/client/getClientNameList")
      .then((response) => {
        setClientOptions(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, []);
  useEffect(() => {
    const fetchPaymentMonths = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getShortMonth"
        );
        setPaymentMonths(response.data);
      } catch (error) {
        console.error("Error fetching paymentMonths:", error);
      }
    };

    fetchPaymentMonths();
  }, []);
  useEffect(() => {
    const fetchPaymentYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getPaymentYear"
        );
        setPaymentYears(response.data);
      } catch (error) {
        console.error("Error fetching paymentYears:", error);
      }
    };

    fetchPaymentYears();
  }, []);
  useEffect(() => {
    const fetchFinancialYears = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/invoice/getFiscalYear"
        );
        setFinancialYears(response.data);
      } catch (error) {
        console.error("Error fetching financialYears:", error);
      }
    };

    fetchFinancialYears();
  }, []);
  // useEffect(() => {
  //   const fetchQuotationNo = async () => {
  //     try {
  //       const response = await myAxiosCommonHost.get(
  //         "gst/quotation/getQuotationNo"
  //       );
  //       setQuotationDetails((prevDetails) => ({
  //         ...prevDetails,
  //         quotationNo: response.data.toString(), // Convert to string format
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching quotationNo:", error);
  //     }
  //   };

  //   fetchQuotationNo();
  // }, []);
  useEffect(() => {
    const fetchQuotationNo = async () => {
      try {
        const response = await myAxiosCommonHost.get(
          "gst/quotation/getQuotationNo"
        );
        setQuotationDetails((prevDetails) => ({
          ...prevDetails,
          quotationNo: Number(response.data), // Parse as a number
        }));
      } catch (error) {
        console.error("Error fetching quotationNo:", error);
      }
    };

    fetchQuotationNo();
  }, []);
  const handleAddEntry = () => {
    const isDuplicate = accomodationUIList.some(
      (accomodation) =>
        accomodation.description.trim() === newAccomodation.description.trim()
    );
    if (isDuplicate) {
      alert("Duplicate Entry! Cannot Add The Same Contact Person Name.");
      return;
    }
    if (
      newAccomodation.description.trim() === "" ||
      newAccomodation.costPerResources.trim() === "" ||
      newAccomodation.totalCost.trim() === "" ||
      newAccomodation.noOfMonth.trim() === "" ||
      newAccomodation.noOfResources.trim() === ""
    ) {
      alert("Cannot add null or empty data to the table");
      return;
    }
    setAccomodationUIList((prevAccomodationUIList) => [
      ...prevAccomodationUIList,
      newAccomodation,
    ]);
    setNewAccomodation({
      description: "",
      costPerResources: "",
      noOfResources: "",
      noOfMonth: "",
      totalCost: "",
    });
  };
  const handleAccomodationUIChange = (e) => {
    const { name, value } = e.target;
    setNewAccomodation((prevNewAccomodation) => ({
      ...prevNewAccomodation,
      [name]: value,
    }));
  };
  const generateQuotationRefNo = () => {
    const companyValue =
      quotationDetails.selectCompany &&
      typeof quotationDetails.selectCompany === "object"
        ? quotationDetails.selectCompany.value
        : quotationDetails.selectCompany;
    const { selectCompany, financialYear, quotationNo } = quotationDetails;
    return `${companyValue}${financialYear}${quotationNo}`;
  };
  const quotationRefNo = generateQuotationRefNo();
  useEffect(() => {
    if (
      quotationDetails.selectCompany &&
      quotationDetails.financialYear &&
      quotationDetails.quotationNo
    ) {
      const newQuotationRefNo = generateQuotationRefNo(
        quotationDetails.selectCompany,
        quotationDetails.quotationNo,
        quotationDetails.financialYear
      );
      setQuotationDetails((prevDetails) => ({
        ...prevDetails,
        quotationRefNo: newQuotationRefNo,
      }));
    }
  }, [
    quotationDetails.selectCompany,
    quotationDetails.financialYear,
    quotationDetails.quotationNo,
  ]);
  const handleQuotationDetailsChange = (e) => {
    const { name, value } = e.target;
    setQuotationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    // const newQuotationRefNo = generateQuotationRefNo();
    // setQuotationDetails({
    //   quotationRefNo: newQuotationRefNo,
    // });
  };
  const handleCancel = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel? Any unsaved changes will be lost."
    );
    if (userConfirmed) {
      navigate("/updateQuotationPage");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingOverlay(true);
    setFormSubmitted(true);
    try {
      const postData = {
        ...quotationDetails,
        accomodationUIList,
      };
      console.log(
        "Posted data in JSON format:",
        JSON.stringify(postData, null, 2)
      );
      await myAxiosCommonHost.post("gst/quotation/createQuotation", postData);
      console.log(
        "Posted data in JSON format:",
        JSON.stringify(postData, null, 2)
      );

      // Show alert on successful post
      alert("Quotation Submited Successfully!");

      // Reset all fields after user clicks "OK" on the alert
      setQuotationDetails({
        selectCompany: "",
        quotationRefNo: "",
        quotationNo: "",
        quotationDate: "",
        clientNameAndCode: "",
        kindAttention: "",
        quotationSubject: "",
        quotationContent: "",
        totalAmount: "",
        totalInWord: "",
        financialYear: "",
        financialMonth: "",
      });

      setAccomodationUIList([]);

      setNewAccomodation({
        description: "",
        costPerResources: "",
        noOfResources: "",
        noOfMonth: "",
        totalCost: "",
      });

      // Navigate to updateClient component
      navigate("/updateQuotationPage");

      console.log(
        "Data posted successfully, fields reset, and navigated to ClientList!"
      );
    } catch (error) {
      console.error("Error posting data:", error);
    } finally {
      setLoadingOverlay(false);
    }
  };
  const handleDeleteRow = (index) => {
    const updatedQuotation = [...accomodationUIList];
    updatedQuotation.splice(index, 1);
    setAccomodationUIList(updatedQuotation);
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Card elevation={3} style={{ marginBottom: "20px", marginTop: "20px" }}>
          <CardHeader
            title="Generate Quotation"
            style={{
              backgroundColor: "#2196f3",
              color: "white",
              padding: "10px",
              textAlign: "center",
            }}
          />
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Quotation Details
                </Typography>
              </Paper>
              {loadingOverlay && (
                <div className="loading-overlay">
                  <CircularProgress size={60} />
                  <div className="loading-message">Quotation is Saving...</div>
                </div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={2} md={4}>
                  <Autocomplete
                    options={[{ label: "GSquare", value: "GST" }]}
                    value={quotationDetails.selectCompany}
                    onChange={(event, newValue) => {
                      setQuotationDetails({
                        ...quotationDetails,
                        selectCompany: newValue ? newValue.value : "", // Set selectCompany to its value
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <Autocomplete
                    options={financialYears}
                    getOptionLabel={(option) => option}
                    value={quotationDetails.financialYear}
                    onChange={(event, newValue) => {
                      setQuotationDetails({
                        ...quotationDetails,
                        financialYear: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Year"
                        margin="normal"
                        variant="standard"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <Autocomplete
                    options={paymentMonths}
                    getOptionLabel={(option) => option}
                    value={quotationDetails.financialMonth}
                    onChange={(event, newValue) => {
                      setQuotationDetails({
                        ...quotationDetails,
                        financialMonth: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Month"
                        margin="normal"
                        variant="standard"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation Ref Number"
                    name="quotationRefNo"
                    value={quotationDetails.quotationRefNo}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation No"
                    name="quotationNo"
                    value={quotationDetails.quotationNo}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation Date"
                    name="quotationDate"
                    value={quotationDetails.quotationDate}
                    onChange={handleQuotationDetailsChange}
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <Autocomplete
                    options={clientOptions}
                    getOptionLabel={(option) => option}
                    value={quotationDetails.clientNameAndCode}
                    onChange={(event, newValue) => {
                      setQuotationDetails({
                        ...quotationDetails,
                        clientNameAndCode: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name And Code"
                        margin="normal"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Kind Attention"
                    name="kindAttention"
                    value={quotationDetails.kindAttention}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    required
                  />
                </Grid>
                {/* <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation Subject"
                    name="quotationSubject"
                    value={quotationDetails.quotationSubject}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Quotation Content"
                    name="quotationContent"
                    value={quotationDetails.quotationContent}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Quotation Subject"
                    name="quotationSubject"
                    value={quotationDetails.quotationSubject}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard" // Change the variant to outlined for multiline TextField
                    multiline // Add the multiline prop for multiline functionality
                    rows={6} // Adjust the rows prop as needed
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label="Quotation Content"
                    name="quotationContent"
                    value={quotationDetails.quotationContent}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard" // Change the variant to outlined for multiline TextField
                    multiline // Add the multiline prop for multiline functionality
                    rows={6} // Adjust the rows prop as needed
                  />
                </Grid>

                <Grid item xs={2} md={4}>
                  <TextField
                    label="Total Amount"
                    name="totalAmount"
                    value={quotationDetails.totalAmount}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Total In Word"
                    name="totalInWord"
                    value={quotationDetails.totalInWord}
                    onChange={handleQuotationDetailsChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Paper>
          <Paper
            elevation={3}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Paper
              elevation={3}
              style={{ padding: "20px", marginBottom: "20px" }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  marginBottom: "30px",
                  backgroundColor: "darkgray",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginBottom: "15px",
                    color: "darkblue",
                    textAlign: "center",
                  }}
                >
                  Accomodation
                </Typography>
              </Paper>

              <Grid container spacing={2}>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Description"
                    name="description"
                    value={newAccomodation.description}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Cost Per Resources"
                    name="costPerResources"
                    value={newAccomodation.costPerResources}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Number Of Resources"
                    name="noOfResources"
                    value={newAccomodation.noOfResources}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Number Of Month"
                    name="noOfMonth"
                    value={newAccomodation.noOfMonth}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2} md={4}>
                  <TextField
                    label="Total Cost"
                    name="totalCost"
                    value={newAccomodation.totalCost}
                    onChange={handleAccomodationUIChange}
                    fullWidth
                    margin="dense"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Paper>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "darkcyan",
                      fontWeight: "bold",
                      color: "darkblue",
                    }}
                  >
                    <TableCell>SR.No</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Cost Per Resources</TableCell>
                    <TableCell>Number Of Resources</TableCell>
                    <TableCell>Number Of Month</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accomodationUIList.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{entry.description}</TableCell>
                      <TableCell>{entry.costPerResources}</TableCell>
                      <TableCell>{entry.noOfResources}</TableCell>
                      <TableCell>{entry.noOfMonth}</TableCell>
                      <TableCell>{entry.totalCost}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteRow(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEntry}
              style={{ marginTop: "20px" }}
            >
              Add Accomodation
            </Button>
          </Paper>
          <Paper
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginBottom: "20px", marginLeft: "30px" }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              style={{
                marginBottom: "20px",
                marginLeft: "10px",
                backgroundColor: "red",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </Paper>
        </Card>
      </form>
    </Container>
  );
};

export default GenerateQuotation;
